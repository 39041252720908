import { makeStyles, createTheme } from "@material-ui/core/styles"

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: 16,

    [responsive.breakpoints.down('sm')]: {
      margin: '0 16px',
    }
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 1014,
    width: '100%',

    [responsive.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    }
  },

  skeleton: {
    display: 'flex',
    width: '100%',
    height: 70,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 18px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.24)',
    borderRadius: 8,
    marginBottom: 24,

    '& :nth-child(1)': {
      fontSize: 14,
      fontWeight: '700',
      maxWidth: 210,
      width: '100%',

    },

    '& :nth-child(2)': {
      fontSize: 14,
      maxWidth: 210,
      width: '100%',
    },

    '& :nth-child(3)': {
      fontSize: 14,
      fontWeight: '700',
      maxWidth: 210,
      width: '100%',
    },

    '& :nth-child(4)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 208,
      width: '100%',
      height: 38,
      borderRadius: 8,
      padding: '12px 24px',
      outLine: 'none',
      border: 'none',
      fontSize: 12,
      fontWeight: 'bold',
      letterSpacing: 2,
    },

    [responsive.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 409,
      width: '100%',
      height: 169,
    }
  },

  message: {
    textAlign: 'center',
    fontSize: '50px',
    margin: '40px 0',
    color: '#271E1E',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',

    [responsive.breakpoints.down('sm')]: {
      fontSize: '6vw',
    }
  }

}))

export default useStyles
