import { Box, Button, Dialog, Grid, Slide } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import loading from '../../Assets/img/loading.gif';

import useStyles from './style';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SubscribeModal = props => {
  const {
    openModal,
    setOpenModal,
    nameTeacher,
    nameCourse,
    CoursePraticalOrNot,
    dayCourseFormated,
    courseFreeOrNot,
    donatioCourse,
    telephoneTeacher,
    handleSubmitCourse,
    setSubscribeCourse,
    setActiveStep,
    activeStep,
    confirmationButton,
    setConfirmationButton,
  } = props;

  // const [activeStep, setActiveStep] = useState(1);
  const [loadingData, setLoadingData] = useState(false);

  const courseWarningMessage =
    courseFreeOrNot === 'Grátis' ? (
      <p>
        Você não precisa pagar nada. Apenas pedimos para levar no dia uma
        contribuição simbólica de <b>{donatioCourse}</b>, para ajudar
        instituições carentes.
      </p>
    ) : (
      <p>
        Basta comparecer ao curso e efetuar o pagamento em dinheiro direto para
        o(a) {nameTeacher}(a).
      </p>
    );
  const messageWhatsapp = `Olá **${nameTeacher}** Quero me inscrever no curso ${nameCourse} do dia ${dayCourseFormated} na loja santo antonio, como eu faço para efetuar o pagamento?`;

  const maskTelephone = telephone => {
    telephone = telephone.replace(/\D/g, ''); //Remove tudo o que não é dígito
    telephone = telephone.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    telephone = telephone.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
    return telephone;
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };
  const buttonSubscribe = document.querySelector('#gif_label_subscribe');
  const handleNextStep = () => {
    if (
      buttonSubscribe.textContent !== 'Aguardando confirmação' ||
      buttonSubscribe.textContent !== 'Esgotado' ||
      buttonSubscribe?.dataset.lastvacancies !== 'Cancelado'
    ) {
      handleSubmitCourse();
      setLoadingData(true);
      setConfirmationButton(true);
    }
  };

  const handleNextStepAlreadySubscribe = activeStep => {
    if (CoursePraticalOrNot === 'Prático') {
      if (activeStep <= 2) {
        setActiveStep(prevActiveStep => ++prevActiveStep);
      } else {
        setOpenModal(false);
        setActiveStep(1);
      }
    }

    if (CoursePraticalOrNot !== 'Prático') {
      if (activeStep === 2) {
        setOpenModal(false);
        setActiveStep(1);
      }
    }
  };

  const getLinkWhastapp = (number, message) => {
    const link = `https://api.whatsapp.com/send?phone=+55${number}&text=${encodeURIComponent(
      message
    )}`;
    setOpenModal(false);
    return window.open(link);
  };

  const handleScroll = () => {
    const modal = document?.querySelector('#modal-terms');
    console.log(
      modal.scrollTop,
      modal.clientWidth,
      modal.clientHeight,
      modal.clientTop
    );

    if (Math.ceil(modal.scrollTop) >= 115) {
      setConfirmationButton(false);
    } else {
      setConfirmationButton(true);
    }
  };

  const style = useStyles();
  return (
    <Dialog open={openModal} TransitionComponent={Transition} keepMounted>
      <Grid
        className={style.containerModalSubscribeCourse}
        data-confirmation_subscribe
      >
        {activeStep === 1 && (
          <Box className={style.containerModalSlider}>
            <Box className={style.headerSubscribeModal}>
              <p>Confirmação de inscrição</p>
              <Button onClick={handleCloseModal}>X</Button>
            </Box>

            <Box
              className={`${style.contentModalList} area_carousel`}
              id="modal-terms"
              onScroll={handleScroll}
            >
              <ul>
                <li>
                  <b>LEIA COM ATENCÃO:</b>
                </li>
                <li>
                  <b>
                    Estou ciente que NÃO É PERMITIDO estacionar nas vagas
                    rotativas na porta da loja durante a permanência no curso
                    (estacionamento rotativo durante o período de compras até
                    1h).
                  </b>
                </li>
                <li>
                  <b>
                    ESTOU CIENTE QUE DEVO EFETUAR O CANCELAMENTO AQUI NO SITE
                    CASO NÃO POSSA COMPARECER NA AULA
                  </b>
                  (caso ocorra a falta sem cancelamento, o seu cadastro poderá
                  ficar bloqueado)
                </li>
                <li>
                  Temos convênio com desconto em 2 estacionamentos próximos a
                  loja.
                </li>
                <li>
                  <b>CURSOS PARA MAIORES DE 12 ANOS.</b>
                </li>
                <li>
                  <b>Não é permitido acompanhantes na sala.</b>
                </li>
                <li>
                  Me comprometo a comparecer com ao menos 15 minutos de
                  antecedência ao início da aula.
                </li>
                <li>Permitimos no máximo 15 minutos de atraso.</li>
                <li>
                  <b>
                    ESTOU CIENTE QUE DEVO LEVAR PAPEL E CANETA PARA ANOTAÇÕES.
                  </b>
                </li>
                <li>
                  Estou ciente que nos cursos grátis devo levar alimento
                  <b> NÃO PERECÍVEL </b> para doação.
                </li>
                <li>
                  O pagamento do curso é feito diretamente para o professor na
                  entrada da sala de curso.
                </li>
              </ul>
            </Box>

            <Button
              onClick={handleNextStep}
              disabled={loadingData ? true : confirmationButton}
            >
              {loadingData && <img src={loading} alt="loading" />}
              Aceitar e me inscrever
            </Button>
          </Box>
        )}

        {activeStep === 2 && (
          <Box className={style.containerModalSlider}>
            <Box className={style.headerSubscribeModal}>
              <p>Parabéns</p>
            </Box>
            <Box className={style.contentModalCongratulations}>
              <p>
                Agora você está inscrito no curso <b>{nameCourse}</b>
              </p>
              <p>
                O seu curso é
                <b style={{ marginLeft: 5 }}>
                  {CoursePraticalOrNot} - {courseFreeOrNot}
                </b>
              </p>
              {courseWarningMessage}
            </Box>
            <Button onClick={() => handleNextStepAlreadySubscribe(activeStep)}>
              Estou ciente
            </Button>
          </Box>
        )}

        {activeStep === 3 && (
          <Box className={style.containerModalSlider}>
            <Box className={style.headerSubscribeModal}>
              <p>Quase lá!</p>
            </Box>
            <Box className={style.contentModalContactTeacher}>
              <p>
                Recebemos usa inscrição no curso <b>{nameCourse}</b>
              </p>
              <p>
                O seu curso é
                <b>
                  {CoursePraticalOrNot} - {courseFreeOrNot}
                </b>
              </p>
              <p>
                Para confirmar a sua inscrição você precisa entrar em contato
                com o(a) {nameTeacher}(a) através do telefone abaixo informando
                sua inscrição conosco e combinando o pagamento. Assim que o
                pagamento for confirmado, iremos validar sua inscrição.
              </p>
              <Box className={style.ContactTeacher}>
                <WhatsAppIcon
                  onClick={() =>
                    getLinkWhastapp(
                      maskTelephone(telephoneTeacher),
                      messageWhatsapp
                    )
                  }
                />
                <span
                  onClick={() =>
                    getLinkWhastapp(
                      maskTelephone(telephoneTeacher),
                      messageWhatsapp
                    )
                  }
                >
                  {nameTeacher}
                </span>
                -
                <span
                  onClick={() =>
                    getLinkWhastapp(
                      maskTelephone(telephoneTeacher),
                      messageWhatsapp
                    )
                  }
                >
                  {maskTelephone(telephoneTeacher)}
                </span>
              </Box>
            </Box>
            <Button
              onClick={() => handleNextStepAlreadySubscribe(activeStep)}
              href={`https://api.whatsapp.com/send?phone=+55${maskTelephone(
                telephoneTeacher
              )}&text=${encodeURIComponent(messageWhatsapp)}`}
              method="get"
            >
              Estou ciente, iniciar conversa!
            </Button>
          </Box>
        )}
      </Grid>
    </Dialog>
  );
};

export default SubscribeModal;
