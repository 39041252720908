import React, { useState } from 'react'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { Grid, Button, FormControl, InputLabel, OutlinedInput, FormHelperText, IconButton, InputAdornment } from '@material-ui/core'
import MaskedInput from 'react-text-mask';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import globalStyles from '~/Assets/css/globalStyles';
import { useDispatch, useSelector } from 'react-redux'
import { signInRequest, signReset } from '~/store/modules/auth/actions';
import api from '~/Services/api';
import { useEffect } from 'react';


function cpfMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

cpfMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function removerCharEspecial(value) {
  const charEsp = /[^a-z0-9]/gi;
  return value.replace(charEsp, '');
};

const FormLogin = ({ handleDrawer }) => {
  const dispatch = useDispatch()
  const profile = useSelector(state => state.user.profile)
  const alertSign = useSelector(state => state.auth.alertSignIn)

  const pattern = globalStyles()

  const [events, setEvents] = useState({
    showPassword: false
  })

  const handleClickShowPassword = field => {
    setEvents({ ...events, [field]: !events[field] });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const [login, setLogin] = useState({
    cpf: "",
    password: ""
  })

  const [btnSubmit, setBtnSubmit] = useState(false)

  const [msg, setMsg] = useState({
    cpf: "",
    password: "",
  })

  const [status, setStatus] = useState({
    cpf: "",
    password: "",
  })

  const schema = yup.object().shape({
    cpf: yup.string().required("Campo Obrigatório!").min(14, "CPF incompleto !"),
    password: yup.string().required("Campo Obrigatório!").min(6, "Mínimo 6 dígitos!")
  })


  const handleValue = (field, e) => {
    setLogin({ ...login, [field]: e.target.value })
    valid(field, { ...login, [field]: e.target.value })
  }

  const valid = (field, obj) => {
    schema.validateAt(field, obj).then(() => {
      setMsg({ ...msg, [field]: "" })
      setStatus({ ...status, [field]: "isValid" })
    }).catch(err => {
      setMsg({ ...msg, [field]: err.errors })
      setStatus({ ...status, [field]: "invalid" })
    })

    schema.validate(obj).then(() => {
      setBtnSubmit(true)
    }).catch(err => {
      setBtnSubmit(false)
    })
  }

  useEffect(function (){
    if(profile){
      handleDrawer("login")
    }
  }, [profile, handleDrawer])

  const signIn = e => {
    e.preventDefault()

    const obj = {
      cpf: removerCharEspecial(login.cpf),
      password: login.password,
    }
    dispatch(signInRequest(obj.cpf, obj.password))

    setTimeout(() => {
      dispatch(signReset())
    }, 3000)
  }

  return (
    <form onSubmit={e => signIn(e)} className={pattern.root}>
      <Grid container spacing={1} >
        <Grid item xs={12}>
          {alertSign ? (
            <div className={`${pattern.alert} ${alertSign.status ? alertSign.status : ""}`}>
              {alertSign.msg ? alertSign.msg : ""}
            </div>
          ) : ""}
        </Grid>

        <Grid item xs={12}>
          <FormControl
            fullWidth
            size="small"
            className={status.cpf}
          >
            <InputLabel htmlFor="cpf" variant={'outlined'}>CPF</InputLabel>
            <OutlinedInput
              value={login.cpf}
              id="cpf"
              inputComponent={cpfMask}
              onChange={e => handleValue('cpf', e)}
              onBlur={e => handleValue('cpf', e)}
              placeholder="123.456.789-00"
              labelWidth={35}
            />
            <FormHelperText>{msg.cpf}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            fullWidth
            size="small"
            variant="outlined"
            className={status.password}
          >
            <InputLabel htmlFor="password">Senha</InputLabel>
            <OutlinedInput
              id="password"
              type={events.showPassword ? 'text' : 'password'}
              value={login.password}
              onChange={e => handleValue('password', e)}
              onBlur={e => handleValue('password', e)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handleClickShowPassword('showPassword')
                    }
                    onMouseDown={() => handleMouseDownPassword}
                    edge="end"
                  >
                    {events.showPasswordSignIn ? (
                      <Visibility />
                    ) : (
                        <VisibilityOff />
                      )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={45}
            />
            <FormHelperText>{msg.password}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" fullWidth disabled={!btnSubmit}>ENTRAR</Button>
        </Grid>
      </Grid>
    </form>
  )
}

const ForgotPass = () => {

  const pattern = globalStyles()

  const [cpfForgot, setCpfForgot] = useState({
    cpf: "",
  })

  const [alert, setAlert] = useState({
    status: false,
    msg: null,
  })

  const [btnSubmit, setBtnSubmit] = useState(false)

  const [msg, setMsg] = useState({
    cpf: "",
  })

  const [status, setStatus] = useState({
    cpf: "",
  })

  const schema = yup.object().shape({
    cpf: yup.string().required("Campo Obrigatório!").min(14, "CPF incompleto !"),
  })


  const handleValue = (field, e) => {
    setCpfForgot({ ...cpfForgot, [field]: e.target.value })
    valid(field, { ...cpfForgot, [field]: e.target.value })
  }

  const valid = (field, obj) => {
    schema.validateAt(field, obj).then(() => {
      setMsg({ ...msg, [field]: "" })
      setStatus({ ...status, [field]: "isValid" })
    }).catch(err => {
      setMsg({ ...msg, [field]: err.errors })
      setStatus({ ...status, [field]: "invalid" })
    })

    schema.validate(obj).then(() => {
      setBtnSubmit(true)
    }).catch(err => {
      setBtnSubmit(false)
    })
  }

  const envEmail = async e => {
    e.preventDefault();
    try {
      const obj = {
        cpf: removerCharEspecial(cpfForgot.cpf),
      }
      const res = await api.post('/forgot_password', obj)
      let email = res.data;
      const endEmail = email.substring(email.indexOf("@"))
      const initialEmail = email.substring(0, email.indexOf("@"))
      let ast = ""
      for (let i = 5; i < initialEmail.length; i++) {
        ast += "*"
      }
      email = initialEmail.substring(0, 5) + ast + endEmail;
      setAlert({ ...alert, status: 'success', msg: `Email de recuperação de senha enviado para ${email}.` })
      setTimeout(() => {
        setAlert({ ...alert, status: false, msg: null })
      },5000)
    } catch (err) {
      setAlert({ ...alert, status: 'error', msg: "Não existe cadastro com esse CPF." })
    }
  }

  return (
    <>
      <form className={pattern.root} onSubmit={envEmail}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {alert.status ? (
              <div className={`${pattern.alert} ${alert.status ? alert.status : ""}`}>
                {alert.msg ? alert.msg : ""}
              </div>
            ) : ""}
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              size="small"
              className={status.cpf}
            >
              <InputLabel htmlFor="cpf" variant={'outlined'}>CPF</InputLabel>
              <OutlinedInput
                value={cpfForgot.cpf}
                id="cpf"
                inputComponent={cpfMask}
                onChange={e => handleValue('cpf', e)}
                onBlur={e => handleValue('cpf', e)}
                placeholder="123.456.789-00"
                labelWidth={35}
              />
              <FormHelperText>{msg.cpf}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" fullWidth disabled={!btnSubmit}>ENVIAR</Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

FormLogin.propTypes = {
  handleDrawer: PropTypes.func.isRequired
}

export { FormLogin, ForgotPass }
