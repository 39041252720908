import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  btnAddStudent: {
    backgroundColor: 'orange !important',
    height: 40,
    marginLeft: 10,
    color: '#fff',
  },

  linkListPresence: {
    fill: '#D01717',
    width: '1.5em',
    height: '1.5em',
  },

  tableHeader: {
    '& .MuiTableRow-head': {
      background: '#d01717',

      '& .MuiTableCell-head': {
        color: '#fff',
        fontSize: 18,
        fontWeight: '400',
      },
    },
  },

  titleCourse: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 24,
    fontWeight: '400',
    color: '#000000de',
  },

  radioButton: {
    '& .MuiFormControlLabel-root': {
      display: 'block',
    },

    '& .MuiRadio-colorSecondary.Mui-checked':{
      color: '#b01010'
    }
  },

  checkbox:{
    '&.MuiCheckbox-colorPrimary.Mui-checked':{
      color: '#b01010'
    }
  },

  total: {
    '& .MuiTableCell-footer':{
      width: 130,
      textAlign: 'center',
      color: '#271010',
      fontSize: 18,

      '&:nth-child(1)':{
        width: 180,
      },

      '&:nth-child(2)':{
        width: 210
      },

      '&:nth-child(3)':{
        width: 250
      }
    },

  },
}));

export default useStyles;
