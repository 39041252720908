import React, { useEffect } from 'react'
import NewPassword from '~/Components/Forms/NewPassword';
import globalStyles from '~/Assets/css/globalStyles';
import { Container } from '@material-ui/core';

const ResetPass = ({ match }) => {
  const pattern = globalStyles()

  return (
    <>
      {/* <Header /> */}
      <div className={pattern.content}>
        <Container maxWidth={'xs'} style={{ paddingTop: '100px' }}>
          <NewPassword token={match.params.token} />
        </Container>
      </div>
    </>
  )

}

export default ResetPass;
