import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import '~/config/reactotronConfig';
import Routes from '~/Routes';

// import '~/Assets/css/geral.css';
import 'typeface-roboto';

import history from '~/Services/history';
import { store, persistor } from '~/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StylesGlobals from './Assets/css/StylesGlobals';
import { AuthProvider } from '~/Context/AuthContext';

function App() {
  useEffect(() => {

    // Código GTM de Teste
    const gtmScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PK8LNNQ6');
    `;

    // Adiciona o script do GTM ao head
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.appendChild(document.createTextNode(gtmScript));
    document.head.appendChild(scriptTag);

    // Adiciona o script do GTM ao body
    const noscriptTag = document.createElement('noscript');
    let scriptEl = document.createElement('iframe');
    scriptEl.setAttribute('style', 'display: none; visibility: hidden;');
    scriptEl.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=GTM-PK8LNNQ6');
    scriptEl.setAttribute('height', '0');
    scriptEl.setAttribute('width', '0');
    noscriptTag.appendChild(scriptEl);
    document.body.appendChild(noscriptTag);
  }, []);

  return (
    <AuthProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <StylesGlobals />
            <Routes />
            <ToastContainer autoClose={3000} />
          </Router>
        </PersistGate>
      </Provider>
    </AuthProvider>
  );
}

export default App;
