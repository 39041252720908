import React from 'react';
import DashAdmin from '~/Components/DashAdmin';
import TableCourses from '~/Components/Forms/Courses/TableCourses';

// import { Container } from './styles';

export default function ListCourses() {
  return (
    <DashAdmin content={<TableCourses />} title={'Listar Cursos'} width={'100%'} />
  );
}
