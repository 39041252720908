import React from 'react'
import MaskedInput from 'react-text-mask';

function MaskCpf(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function MaskPhone(props) {
  const { inputRef, value, ...other } = props;
  let mask = []
  if (value !== null) {
    if (value[5] === '9') {
      mask = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    } else {
      mask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/,]
    }
  }

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      value={value}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function MaskTime(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/\d/,/\d/,':',/\d/,/\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export { MaskCpf, MaskPhone, MaskTime }
