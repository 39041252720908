import React from 'react';
import DashAdmin from '~/Components/DashAdmin';
import TableStudent from '~/Components/Tables/TableStudent'

export default function ListStudent() {
  return (
    <>
      <DashAdmin content={<TableStudent />} title={'Lista de Alunos'} width={'100%'}/>
    </>
  );
}
