import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiSelect-select:focus': {
      backgroundColor: '#fff',
    },
  },
  inputFull: {
    minWidth: '100%',
  },
  inputFile: {
    '& .labelNameImage': {
      display: 'inline-flex',
      paddingLeft: 10,
      width: '65%',
      overflow: 'hidden',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  bgLabel: {
    background: '#fafafa',
  },
  inputBg: {
    background: '#ffffff',
  },
  containerRecipe: {
    display: 'flex',
    textAlign: 'center',
    overflow: 'hidden',
  },

  recipes: {
    display: 'flex',
    justifyContent: 'right',
    marginRight: 5,
  },

  recipeImg: {
    width: '150px',
    height: '100px',
    objectFit: 'contain',
    borderRadius: '10px',
    objectFit: 'cover',
  },

  recipeActions: {
    background: '#2825256b',
    padding: 5,
    border: 'none',
    width: 35,
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center',
    borderRadius: '0 10px 10px 0',
  },

  recipeAction: {
    borderRadius: '50%',
    border: 'none',
    cursor: 'pointer',
    width: 25,
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiSvgIcon-root': {
      width: '0.875rem',
      height: '0.875rem',
      fill: '#fff',
    },
  },

  recipeActionEdit: {
    background: '#ff9800',
    transition: 'linear 0. 2s',

    '&:hover': {
      opacity: '0.95',
    },
  },
  recipeActionexclude: {
    background: '#b01010',
    transition: 'linear 0. 2s',

    '&:hover': {
      opacity: '0.95',
    },
  },
}));

export default useStyles;
