import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './routes';

/** * import Pages ** */

import AreaStudent from '~/Pages/AreaStudent';
import ResetPass from '~/Pages/ResetPassword';
import AddCourses from '~/Pages/Admin/Courses/AddCourses';
import AboutUs from '~/Pages/AboutUs';
import ListCourses from '~/Pages/Admin/Courses/ListCourses';
import EditCourses from '~/Pages/Admin/Courses/EditCourse';
import CourseForCategory from '~/Pages/Cursos/CourseForCategory';
import Curso from '~/Pages/Cursos/Curso';
import Questions from '~/Pages/Questions';
import MyCourses from '~/Components/MyCourses';
import ListPresence from '~/Pages/Admin/ListPresence';
import TablePresence from '~/Pages/Admin/ListPresence/TablePresence';
import Dashboard from '~/Pages/Admin/Dashboard';
import Depoiment from '~/Pages/AreaStudent/Depoiment';
import Certificate from '~/Pages/AreaStudent/Certificate';
import Category from '~/Pages/Admin/Category';
import ListCategory from '~/Pages/Admin/Category/list';
import EditStudent from '~/Pages/Admin/Student/EditStudent';
import ListStudent from '~/Pages/Admin/Student/ListStudent';
import AddEditTeacher from '~/Pages/Admin/Teacher/AddEditTeacher';
import ListTeacher from '~/Pages/Admin/Teacher/ListTeacher';
import AreaTeacher from '~/Pages/AreaTeacher';
import PasswordTeacher from '~/Pages/PasswordTeacher';
import MyProfile from '~/Pages/AreaStudent/MyProfile';
import Home from '~/Pages/public/Home';
import OngoingCourses from '~/Pages/AreaStudent/OngoingCourses';
import OngoingCoursesTeacher from '~/Pages/AreaTeacher/OngoingCourses';
import CompletedCoursesTeacher from '~/Pages/AreaTeacher/CompletedCourses';
import CoursesITeach from '~/Pages/AreaTeacher/CoursesITeach';
import GenerateCertificate from '~/Components/GenerateCertificate';
import CompletedCourses from '~/Pages/AreaStudent/CompletedCourses';
import CoursesITaught from '~/Pages/AreaTeacher/CoursesITaught';
import AllCourses from '~/Pages/public/AllCourses';
import CourseV2 from '~/Pages/Cursos/CourseV2';
import ProfileEdit from '~/Pages/AreaStudent/ProfileEdit';
import ProfileEditTeacher from '~/Pages/AreaTeacher/ProfileEdit';
import AreaTeacherAsStudent from '~/Pages/AreaTeacher/AsStudent';
import ListDepoiment from '~/Pages/Admin/Depoiment/ListDepoiment';

import RouteChangeTracker from '~/Components/RouterChangeTracker';
import CourseV3 from '~/Pages/Cursos/CourseV3';
import ListBanners from '~/Pages/Admin/Configurations/Banners/ListBanners';
import AddBanner from '~/Pages/Admin/Configurations/Banners/AddBanner';
import EditBanner from '~/Pages/Admin/Configurations/Banners/EditBanner';

/*** Novos componentes ***/

const Routes = () => {
  return (
    <Switch>
      {/* Rotas Publicas */}
      <Route exact path="/" component={Home} />
      <Route exact path="/cursos" component={AllCourses} />
      <Route exact path="/cursos/:id" component={CourseV2} />
      <Route exact path="/curso/:id" component={CourseV3} />
      <Route exact path="/quem-somos" component={AboutUs} />
      <Route exact path="/curso/:name" component={Curso} />
      <Route
        exact
        path="/cursos/categoria/:name"
        component={CourseForCategory}
      />
      <Route exact path="/duvidas" component={Questions} />

      {/* Rotas do Alunos */}
      <Route
        exact
        path="/area-do-aluno"
        privateFor={'student'}
        component={AreaStudent}
      />
      <Route
        exact
        path="/area-do-aluno/meu-perfil"
        privateFor={'student'}
        component={MyProfile}
      />
      <Route
        exact
        path="/area-do-aluno/meu-perfil/:id"
        privateFor={'student'}
        component={ProfileEdit}
      />
      <Route
        exact
        path="/area-do-aluno/meus-cursos"
        component={MyCourses}
        privateFor={'student'}
      />
      <Route
        exact
        path="/area-do-aluno/meus-cursos/cursos-andamento"
        component={OngoingCourses}
        privateFor={'student'}
      />
      <Route
        exact
        path="/area-do-aluno/meus-cursos/cursos-completos"
        component={CompletedCourses}
        privateFor={'student'}
      />
      <Route
        exact
        path="/area-do-aluno/depoimento/:id"
        component={Depoiment}
        privateFor={'student'}
      />
      <Route
        exact
        path="/area-do-aluno/certificado"
        component={GenerateCertificate}
        privateFor={'student'}
      />
      <Route
        exact
        path="/area-do-aluno/certificado/:id"
        component={Certificate}
        privateFor={'student'}
      />

      {/* Rotas do Professor */}
      <Route
        exact
        path="/area-do-professor"
        privateFor={'teacher'}
        component={AreaTeacher}
      />
      <Route
        exact
        path="/area-do-professor/meu-perfil/:id"
        privateFor={'teacher'}
        component={ProfileEditTeacher}
      />
      <Route
        exact
        path="/area-do-professor/meus-cursos/cursos-andamento"
        privateFor={'teacher'}
        component={OngoingCoursesTeacher}
      />
      <Route
        exact
        path="/area-do-professor/meus-cursos/cursos-completos"
        privateFor={'teacher'}
        component={CompletedCoursesTeacher}
      />
      <Route
        exact
        path="/area-do-professor/meus-cursos/cursos-para-ensinar"
        privateFor={'teacher'}
        component={CoursesITeach}
      />
      <Route
        exact
        path="/area-do-professor/meus-cursos/cursos-que-ensinei"
        privateFor={'teacher'}
        component={CoursesITaught}
      />
      <Route
        exact
        path="/cadastrar-senha-professor/:token"
        privateFor={'teacher'}
        component={PasswordTeacher}
      />
      <Route
        exact
        path="/area-do-professor/certificado/:id"
        component={Certificate}
        privateFor={'teacher'}
      />
      <Route
        exact
        path="/area-do-professor/area-do-aluno"
        component={AreaTeacherAsStudent}
        privateFor={'teacher'}
      />

      {/* Rotas do Administrador */}
      <Route exact path="/admin" component={Dashboard} privateFor={'admin'} />
      <Route
        exact
        path="/admin/listar-categorias"
        component={ListCategory}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/nova-categoria"
        component={Category}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/editar-categoria/:id"
        component={Category}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/lista-de-presenca/"
        component={TablePresence}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/lista-de-presenca/:id"
        component={ListPresence}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/listar-alunos"
        component={ListStudent}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/editar-aluno/:id"
        component={EditStudent}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/listar-alunos"
        component={ListStudent}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/novo-professor"
        component={AddEditTeacher}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/editar-professor/:id"
        component={AddEditTeacher}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/listar-professores"
        component={ListTeacher}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/depoimentos"
        component={ListDepoiment}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/configuracoes/novo-banner"
        component={AddBanner}
        privateFor={'admin'}
      />
      <Route
        exact
        path="/admin/configuracoes/lista"
        component={ListBanners}
        privateFor={'admin'}
      />

      <Route
        exact
        path="/admin/configuracoes/editar-banner/:id"
        privateFor={'admin'}
        component={EditBanner}
      />

      <Route exact path="/recuperacao-de-senha/:token" component={ResetPass} />

      <Route
        exact
        path="/admin/novo-curso"
        privateFor={'admin'}
        component={AddCourses}
      />
      <Route
        exact
        path="/admin/listar-cursos"
        privateFor={'admin'}
        component={ListCourses}
      />

      <Route
        exact
        path="/admin/listar-cursos/:id"
        privateFor={'admin'}
        component={EditCourses}
      />

      <RouteChangeTracker />
    </Switch>
  );
};

export default Routes;
