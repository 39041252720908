import React from 'react'
import { Form } from '@unform/web'
import { Button } from '@material-ui/core'

const FormV2 = (props) => {
  const { children, handleSubmit, formRef } = props

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      {children}

      <Button type="submit">Salvar Alterações</Button>
    </Form>
  )
}

export default FormV2
