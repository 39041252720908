import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import RateCourse from '~/Components/Modals/RateCourse';
import api from '~/Services/api';
import * as yup from 'yup';
import { validateForms, validateInputForm } from '../Formularios/configYup';
import useStyles from './style';
import history from '~/Services/history';
import { DateTime } from 'luxon';

import { CustomButton } from '../Custom';
import { store } from '~/store';

DateTime.local().setZone('America/Sao_Paulo');

const GenerateCertificate = ({ course }) => {

  const { profile } = store.getState().user;

  const [openModal, setOpenModal] = useState(false);
  const [rate, setRate] = useState({
    rating_teacher: '',
    rating_course: '',
    about_teacher: '',
    subscribe_id: course.subscribe_id,
    is_approved: 0,
    course_id: course.id,
  });

  const [btnSubmit, setBtnSubmit] = useState(false);
  const [validateForm, setValidateForm] = useState({
    rating_teacher: {
      msg: '',
      status: '',
    },
    rating_course: {
      msg: '',
      status: '',
    },
    about_teacher: {
      msg: '',
      status: '',
    },
    about_course: {
      msg: '',
      status: '',
    },
  });

  const handleOpenModal = () => {
    if (parseInt(course.have_depoiment) === 1) {
      if (profile.type_user === 'student') {
        history.push(`/area-do-aluno/certificado/${course.subscribeID}`);
      } else {
        history.push(`/area-do-professor/certificado/${course.subscribeID}`);
      }
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    let obj = { ...rate, [name]: value };

    validateInputForm(schema, name, obj, validateForm, setValidateForm);
    setRate({
      ...rate,
      [name]: value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const obj = {
      course_id: rate.course_id,
      about_teacher: rate.about_teacher,
      about_course: rate.about_course,
      rating_teacher: parseInt(rate.rating_teacher),
      rating_course: parseInt(rate.rating_course),
      subscribe_id: course.subscribeID,
      is_approved: 0,
    };

    try {
      await api.post('depoiment', obj);
      setBtnSubmit(false);
      await api.put(`subscribe/${course.subscribe_id}`, { have_depoiment: 1 });
      history.push(`/area-do-aluno/certificado/${course.subscribeID}`);
    } catch (error) {
      console.log(error);
    }
  };

  const schema = yup.object().shape({
    rating_course: yup
      .string()
      .required('Avalie sua experiencia com o curso')
      .min(1, 'Selecione 1 nota'),
    rating_teacher: yup
      .string()
      .required('Avalie sua experiencia com o professor')
      .min(1, 'Selecione 1 nota'),
    about_teacher: yup
      .string()
      .required('Nos descreva o motivo da sua nota')
      .min(10, 'Minimo de 10 caracteres'),
      about_course: yup
      .string()
      .required('Nos descreva o motivo da sua nota')
      .min(10, 'Minimo de 10 caracteres'),
  });

  useEffect(() => {
    validateForms(schema, rate, setBtnSubmit);
  }, [rate]);

  const style = useStyles();
  return (
    <Box
      className={`${style.container} ${
        course.reason_canceled ? 'courseCanceled' : ''
      }`}
    >
      <p>{course.name}</p>
      <p>{course.fullname}</p>
      <p>{DateTime.fromSQL(course.date_of_course)
                      .setLocale('pt')
                      .toFormat('dd/MM/yyyy')}</p>
      {course.reason_canceled ? (
        <p className="canceled">Curso cancelado</p>
      ) : course.is_present === 1 ? (
        <CustomButton
          className={style.button}
          bg={parseInt(course.have_depoiment) === 1 ? '#419a58' : ''}
          onClick={handleOpenModal}
        >
          Baixar certificado
        </CustomButton>
      ) : (
        <p className="ausent">Ausente</p>
      )}

      <RateCourse
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        btnSubmit={btnSubmit}
        validateForm={validateForm}
        courseName={course.name}
      />
    </Box>
  );
};

export default GenerateCertificate;
