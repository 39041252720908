const { makeStyles, createTheme } = require("@material-ui/core/styles");

const shadow = '0px 2px 5px 0px rgb(39 30 30 / .2)'
const shadowBtnInUp = '0px -2px 5px 0px rgb(39 30 30 / .2)'

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})


const useStyles = makeStyles((theme) => ({
  header: {
    height: 80,
    width: '100%',
    boxShadow: shadow,
    overflow: 'hidden',
    background: '#fff',

    '& > div, .area_header': {
      height: '100%',
      padding: 0,
    },

    '& .area_header': {
      '& .logo': {
        width: 150,
        height: '100%',
        flex: 'none',
        display: 'flex',
        alignItems: 'center',

        '& img': {
          maxWidth: '100%',
          maxHeight: '100%'
        }
      },

      '& .navigation': {
        display: 'flex',
        alignItems: 'center'
      }
    },

    '& .btn_in_up_out': {
      [responsive.breakpoints.down('sm')]: {
        position: 'fixed',
        background: '#fff',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99,
        boxShadow: shadowBtnInUp,
      }
    }
  },

  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none !important',
    padding: '10px 14px',
    height: 'max-content',
    cursor: 'pointer',

    '& .label': {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#271e1e',

      [responsive.breakpoints.down('md')]: {
        fontSize: 14,
      }
    },

    '& .underline': {
      display: 'block',
      width: 0,
      height: 5,
      background: '#d81717',
      transition: 'width .2s'
    },

    '&:hover': {
      '& .underline': {
        width: '100%'
      }
    },

    [responsive.breakpoints.down('md')]: {
      padding: '10px 5px',
    },
  },

  btnMenuMobile: {
    '& svg': {
      fill: '#271e1e',
      fontSize: '3rem'
    },
  },

  btnSignUpIn: {
    background: '#f4f4f4',
    position: 'relative',
    borderRadius: 55,
    height: 40,
    cursor: 'pointer',
    fontWeight: 'bold',

    '& .switch': {
      display: 'block',
      background: '#d81717',
      position: 'absolute',
      width: 150,
      right: 79,
      height: '100%',
      borderRadius: 55,
      transition: 'linear .2s',

      '&.active': {
        width: 80,
        right: 0
      },
    },

    '& .label': {
      fontSize: 18,
      zIndex: 10,
      color: '#d81717',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 55,

      '&:nth-child(2)': {
        width: 150,
      },

      '&:nth-child(3)': {
        width: 80,
      },

      '&.active': {
        color: '#fff'
      }
    }
  },

  areaStudent: {
    height: 40,
    padding: '0 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    background: '#271e1e',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: 20,
    textDecoration: 'none !important',
    cursor: 'pointer',
    transition: 'linear .2s',


    [responsive.breakpoints.down('xs')]: {
      fontSize: '3.5vw'
    },

    '&:hover': {
      background: '#000',
    }
  },

  drawer: {
    '& .drawer_content': {
      width: 500,
      minHeight: '100vh',

      '& .btn_close_drawer': {
        '& svg': {
          fontSize: '3rem',
          fill: '#271e1e',
        }
      },

      '& .navigation_mobile':{
        '& .label *': {
          fontSize: 20,
          fontWeight: 'bold',
          color: '#271e1e'
        },

        '& .box_btns': {
          '& .label *': {
            color: '#d81717',
          }
        },
      },

      '& .content_drawer': {
        padding: '0 20px',
      },

      [responsive.breakpoints.down('sm')]: {
        width: '100vw'
      }
    }
  }
}))

export default useStyles
