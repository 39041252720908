import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Slide, Dialog, Grid, Box } from '@material-ui/core'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ListAltIcon from '@material-ui/icons/ListAlt'
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { addDays, format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import Profile from '~/Components/Profile'
import api from '~/Services/api'
import { store } from '~/store'
import useStyles from './style'
import { DateTime } from "luxon";
import UnsubscribeModal from '~/Components/Modals/UnsubscribeModal';
DateTime.local().setZone('America/Sao_Paulo')

const CoursesITeach = () => {
  const { profile } = store.getState().user

  const [courses, setCourses] = useState([])
  const [notFound, setNorfound] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [subscribes, setSubscribes] = useState([])
  const [statusFill, setStatusFill] = useState(false)
  const [courseId, setCourseId] = useState('')

  const getCourses = async () => {
    const res = await api.get(`/teacher/my_subscribes/${profile.id}`)
    if (res.data.length > 0) {
      setCourses(res.data)
    } else {
      setNorfound(true)
    }
  }

  const getSubscribePerCourse = async (courseID) => {
    const subscribes = await api.get(`subscribes_per_course/${courseID}`)
    setSubscribes(subscribes.data)
  }

  const confirmationSubscribe = async (subscribeID, statusPratical) => {
    const status = statusPratical === 1 ? 0 : 1
    const confimation = await api.put(`subscribe/${subscribeID}`, { status_pratical: status })
    const newSubscribe = subscribes.map((s) => {
      if (s.id === confimation.data.id) {
        s.status_pratical = s.status_pratical === 1 ? 0 : 1
      }
      return s
    })
    const newCourse = courses.map((course) => {
      if (course.id === courseId) {
        course.quantityPresent = statusPratical === 1 ? parseInt(course.quantityPresent) - 1 : parseInt(course.quantityPresent) + status
      }
      return course
    })
    setSubscribes(newSubscribe)
    setCourses(newCourse)
  }

  const handleModal = (courseId) => {
    setOpenModal(true)
    getSubscribePerCourse(courseId)
    setCourseId(courseId)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    getCourses()
  }, [])

  const style = useStyles()
  return (
    <Profile title="Próximas aulas">
      {!notFound ?
        <TableContainer className={style.root}>
          <Table>
            <TableHead>
              <TableRow className={style.headerTable}>
                <TableCell align="center">Lista de alunos</TableCell>
                <TableCell align="center">Nome do curso</TableCell>
                <TableCell align="center">data do curso</TableCell>
                <TableCell align="center">Horario</TableCell>
                <TableCell align="center">Total de vagas</TableCell>
                <TableCell align="center">Total de inscritos</TableCell>
                <TableCell align="center">Total de alunos confirmados</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={style.bodyTable}>
              {courses.map((course) => (
                <TableRow
                  key={course.id}
                >
                  <TableCell align="center">
                    <ListAltIcon onClick={() => handleModal(course.id)} />
                  </TableCell>
                  <TableCell align="center" component="th" scope="row" className={style.textCapitalize}>
                    {course.name}
                  </TableCell>
                  <TableCell align="center">{format(addDays(new Date(course.date_of_course), 1), 'dd/MM/yyyy')}</TableCell>
                  <TableCell align="center">{`${DateTime.fromSQL(course.hour_start).setLocale('pt').toFormat('HH:mm')} há ${DateTime.fromSQL(course.hour_end).setLocale('pt').toFormat('HH:mm')}`}</TableCell>
                  <TableCell align="center">{course.totalQuantity}</TableCell>
                  <TableCell align="center">{course.quantity}</TableCell>
                  <TableCell align="center">{course.quantityPresent}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        :
        <p className={style.message}>Você não possui nenhum curso</p>
      }
      <ModalStudentsSubscribe
        openModal={openModal}
        subscribes={subscribes}
        setSubscribes={setSubscribes}
        statusFill={statusFill}
        setStatusFill={setStatusFill}
        confirmationSubscribe={confirmationSubscribe}
        handleCloseModal={handleCloseModal}
        courseId={courseId}
      />
    </Profile>
  )
}

export default CoursesITeach

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalStudentsSubscribe = ({ openModal, subscribes, setSubscribes, confirmationSubscribe, handleCloseModal, courseId }) => {

  const [openModalUnsubscribe, setOpenModalUnsubscribe] = useState(false)
  const [data, setData] = useState({})
  const [btnSubmit, setBtnSubmit] = useState(true)
  const [unsubscribe, setUnsubscribe] = useState('')

  const handlerModalUnsubscribe = (e, subscribeId, student_id) => {
    setUnsubscribe('')
    setOpenModalUnsubscribe(true)
    setData({
      ...data,
      subscribe_id: subscribeId,
      course_id: courseId,
      student_id: student_id
    })

  }

  const handlerCloseModalUnsubscribe = () => {
    setOpenModalUnsubscribe(false)
  }

  useEffect(() => {
    if (unsubscribe === 'not subscribe') {
      const newSubscribes = subscribes.filter(subscribe => subscribe.id !== data.subscribe_id)
      setSubscribes(newSubscribes)
    }
  }, [unsubscribe])

  const style = useStyles()
  return (
    <Dialog
      open={openModal}
      TransitionComponent={Transition}
      keepMounted
      className={style.containerModal}
    >
      <Grid  >
        <Box className={style.modalClose}>
          <CloseIcon onClick={handleCloseModal} />
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className={style.headerTable}>
                {
                  subscribes[0]?.status_pratical === 1 && (
                    <>
                      <TableCell align="center">Desinscrever aluno</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </>
                  )
                }
                <TableCell align="center">Nome do Aluno</TableCell>
                <TableCell align="center">Telefone</TableCell>
                <TableCell align="center">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={style.bodyTable}>
              {subscribes.map((subscribe) => (
                <TableRow
                  key={subscribe.id}
                >
                  {
                    subscribe.status_pratical === 1 && (
                      <>
                        <TableCell align="center">
                          <DeleteOutlinedIcon
                            onClick={(e) => handlerModalUnsubscribe(e, subscribe.id, subscribe.student_id)}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <CheckCircleOutlineIcon
                            className={subscribe.status_pratical === 1 ? 'active' : 'not-active'}
                            onClick={() => confirmationSubscribe(subscribe.id, subscribe.status_pratical)}
                          />
                        </TableCell>
                      </>
                    )
                  }

                  <TableCell align="center" component="th" scope="row" className={style.textCapitalize}>
                    {subscribe.fullname}
                  </TableCell>
                  <TableCell align="center">{subscribe.telephoneOne}</TableCell>
                  <TableCell align="center">{subscribe.email}</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      < UnsubscribeModal
        open={openModalUnsubscribe}
        handleDialog={handlerCloseModalUnsubscribe}
        student_id={data.student_id}
        course_id={data.course_id}
        subscribe_id={data.subscribe_id}
        btnSubmit={btnSubmit}
        setBtnSubmit={setBtnSubmit}
        setUnsubscribe={setUnsubscribe}
      />
    </Dialog>
  )
}
