import { makeStyles, createTheme } from "@material-ui/core/styles";

const responsive = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 960,
        lg: 1315,
        xl: 1920,
      },
    },
  })

const useStyles = makeStyles(theme => ({
    boxFilter: {
        maxWidth: 200,
        width: '100%',
        marginBottom: 24,

        '& p': {
            fontSize: 14,
            fontWeight: '700',
            margin: '24px 0 16px 0',
        },

        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
        },

        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#8C0E0E'
        },

        [responsive.breakpoints.down('sm')]: {
            marginBottom: 0,
            maxWidth: '100%',
            borderBottom: '1px solid #D4D2D2',
            paddingBottom: 16,

        }
    },
}))

export default useStyles
