import produce from "immer";

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  alertSignIn: {
    status: false,
    msg: null,
  },
  alertSignUp: {
    status: false,
    msg: null,
  },
  tokenExpired: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        draft.tokenExpired = false;
        break;
      }
      case '@auth/SIGN_UP_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        draft.tokenExpired = false;
        draft.alertSignIn = {
          status: "success",
          msg: "Login Efetuado com sucesso !",
        };
        break;
      }
      case '@auth/SIGN_UP_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        draft.alertSignIn = {
          status: "error",
          msg: "Usuário ou Senha inválidos",
        };
        break;
      }
      case '@auth/SIGN_UP_FAILURE': {
        draft.loading = false;
        draft.alertSignUp = {
          status: "error",
          msg: "Não foi possivel realizar seu cadastro!",
        };
        break;
      }
      case '@auth/SIGN_RESET': {
        draft.alertSignIn = {
          status: false,
          msg: null,
        };
        draft.alertSignUp = {
          status: false,
          msg: null,
        };
        draft.tokenExpired = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.signed = false;
        break;
      }
      case '@auth/SIGN_IN_EXPIRED': {
        draft.token = null;
        draft.signed = false;
        draft.tokenExpired = true;
        break;
      }
      default:
    }
  });
}
