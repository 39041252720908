import React, { useEffect, useState } from 'react';

import useStyles from './style';
import api from '~/Services/api';
import globalStyles from '~/Assets/css/globalStyles';
import { Box } from '@material-ui/core';

import 'moment/locale/pt-br'
import { CustomButton } from '~/Components/Custom';
import NewHeader from '~/Components/NewHeader';
import Footer from '~/Components/Footer';

import CertificateImg from '~/Assets/img/certificado.jpg'

const moment = require('moment')
moment.locale('pt-BR')


export default function Certificate({ match }) {

  const classes = useStyles();
  const pattern = globalStyles()

  const id = match.params.id
  const [sub, setSub] = useState([])

  useEffect(() => {
    requestCertificate()
  }, [])

  async function requestCertificate() {
    const res = await api.get(`certificate_generate/${id}`)
    const obj = res.data[0]
    obj.hour_end = obj.hour_end.substr(0, 5)
    obj.hour_start = obj.hour_start.substr(0, 5)

    setSub(obj)
  }

  return (
    <>
      <NewHeader />
      <div className={`${pattern.content} ${pattern.grayPrimary}`}>
        <div className={`${classes.certificateFix} ${pattern.root}`}>
          <img src={CertificateImg} alt="certificado" />
          <div className={classes.infosCertificate}>
            <span className={classes.studentCertificate}>{sub.fullname}</span>
            <div className={classes.courseCertificate}>
              <span className={classes.nameCourse}> {sub.name}</span>
              <span className={classes.nameTeacher}> {sub.teacher}</span>
            </div>
            <div className={classes.dateCertificate}>
              <span>{moment(sub.date_of_course).format('DD/MM/YYYY')}</span>
              <span>das {sub.hour_start} às {sub.hour_end}</span>
            </div>
          </div>
        </div>

        <Box display='flex' justifyContent='center'>
          <CustomButton onClick={() => window.print()}>IMPRIMIR CERTIFICADO</CustomButton>
        </Box>
      </div>
      <Footer />
    </>
  );
}
