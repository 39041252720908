import React, { useState, useEffect } from 'react';
import useStyles from './style';
import { Grid, Box } from '@material-ui/core';
import api from '~/Services/api';
import Profile from '~/Components/Profile';
import MyProfile from '../../AreaStudent/MyProfile';
import { toast } from 'react-toastify';
import MyCourses from '~/Components/MyCourses';
import { store } from '~/store';

import { useRef } from 'react';

export default function AreaTeacherAsStudent(props) {
  const profile = store.getState().user.profile;
  const [teacher, setTeacher] = useState({});
  const [courses, setCourses] = useState({
    coursesProgress: '',
    completedCourses: '',
  });
  const [courseTeach, setCourseTeach] = useState({
    coursesProgress: 0,
    completedCourses: 0,
  });
  const linkAreaStudent = `/area-do-aluno/meu-perfil/${profile.id}`;
  const linkAreaTeacher = `/area-do-professor/meu-perfil/${profile.id}`;

  const linksCompletedCourseStudent =
    '/area-do-aluno/meus-cursos/cursos-completos';
  const linksCompletedCourseTeacher =
    '/area-do-professor/meus-cursos/cursos-completos';

  const linksOngonigCourseStudent =
    '/area-do-aluno/meus-cursos/cursos-andamento';
  const linksOngonigCourseTeacher =
    '/area-do-professor/meus-cursos/cursos-andamento';

  const linksArea = {
    editProfile:
      profile.type_user === 'student' ? linkAreaStudent : linkAreaTeacher,
    acessCompletedCourses:
      profile.type_user === 'student'
        ? linksCompletedCourseStudent
        : linksCompletedCourseTeacher,
    acessOngoingCourses:
      profile.type_user === 'student'
        ? linksOngonigCourseStudent
        : linksOngonigCourseTeacher,
    accessCoursesITaught: `/area-do-professor/meus-cursos/cursos-que-ensinei`,
    accessCoursesITeach: `/area-do-professor/meus-cursos/cursos-para-ensinar`,
  };

  const maskCpf = cpf =>
    cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

  const maskTelephone = telephone => {
    telephone = telephone.replace(/\D/g, '');
    telephone = telephone.replace(/^(\d{2})(\d)/g, '($1) $2');
    telephone = telephone.replace(/(\d)(\d{4})$/, '$1-$2');
    return telephone;
  };

  const getTeacher = async () => {
    try {
      const res = await api.get(`users/${profile.id}`);
      setTeacher({
        ...teacher,
        id: res.data.id,
        fullname: res.data.fullname,
        cpf: maskCpf(res.data.cpf),
        email: res.data.email,
        telephoneOne: res.data.telephoneOne
          ? maskTelephone(res.data.telephoneOne)
          : '',
        telephoneTwo: res.data.telephoneTwo
          ? maskTelephone(res.data.telephoneTwo)
          : '',
        typeUser: res.data.type_user,
        resum_teacher: res.data.resum_teacher,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const getCourse = async () => {
    const res = await api.get(`/student/my_subscribes/${profile.id}`);
    const { data: courseRealized } = await api.get(
      `/student/my_subscribes/${profile.id}?realized=true`
    );

    const cursosAndamento = res.data.course.reduce(
      (accumulator, { status_pratical }) => {
        accumulator += status_pratical === 0;
        return accumulator;
      },
      0
    );

    setCourses({
      coursesProgress: cursosAndamento,
      completedCourses: courseRealized.length,
    });
  };

  const getCourseTeacher = async () => {
    const CompletedCourses = await api.get(
      `/teacher_courses_realized/${profile.id}`
    );
    const courseOngoing = await api.get(`teacher/my_subscribes/${profile.id}`);
    setCourseTeach({
      ...courseTeach,
      coursesProgress: courseOngoing.data.length,
      completedCourses: CompletedCourses.data.length,
    });
  };

  const infoStudentRef = useRef();
  const [toggleHelp, setToggleHelp] = useState(false);

  useEffect(() => {
    infoStudentRef.current.style.display = 'none';
  }, []);

  useEffect(() => {
    toggleHelp
      ? (infoStudentRef.current.style.display = 'block')
      : (infoStudentRef.current.style.display = 'none');
  }, [toggleHelp]);

  useEffect(() => {
    getTeacher();
    getCourse();
    getCourseTeacher();
  }, []);

  const style = useStyles();
  return (
    <Profile title="Área do Aluno">
      <Grid className={style.containerDados}>
        <MyProfile
          data={teacher}
          linksAreaEditProfile={linksArea.editProfile}
        />
      </Grid>
      <Grid container>
        <Box className={style.containerCourses}>
          <div className={style.info}>
            <strong
              className={style.showInfo}
              onMouseOver={() => setToggleHelp(!toggleHelp)}
              onMouseOut={() => setToggleHelp(!toggleHelp)}
            >
              🛈
            </strong>
            <p className={style.infoText} ref={infoStudentRef}>
              Estes são os cursos que você participou como aluno, sendo os
              Cursos em andamento aqueles em que você está inscrito, porém não
              realizou ainda e os Cursos realizados aqueles que já concluiu.
            </p>
          </div>
          <MyCourses
            title="Cursos para aprender"
            courses={courses}
            linksAcesseCompletedCourse={linksArea.acessCompletedCourses}
            linksAcesseOngoingCourse={linksArea.acessOngoingCourses}
          />
        </Box>
      </Grid>
    </Profile>
  );
}
