import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from '@material-ui/core';

import React from 'react';

import useStyles from './style';

import Slide from '@material-ui/core/Slide';
import rate from '../../Assets/img/rate.png';
import { CustomButton } from '../Custom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RateCourse = props => {
  const {
    openModal,
    handleCloseModal,
    handleChange,
    handleSubmit,
    btnSubmit,
    validateForm,
    courseName
  } = props;

  const style = useStyles();
  return (
    <Dialog open={openModal} TransitionComponent={Transition} keepMounted>
      <form className={style.content} onSubmit={handleSubmit}>
        <Box className={style.headerModal}>
          <Box className={style.title}>
            <img src={rate} alt="avaliar" />
            <h1>Avalie o curso e baixe o certificado</h1>
          </Box>
          {/* <img src={close} alt="avaliar" onClick={handleCloseModal} /> */}
          <Button onClick={handleCloseModal}>X</Button>
        </Box>
        <Box className={`${style.contentModal} area_carousel`}>
          <Box className={style.rating}>
            <p>
              Para baixar o certificado precisamos saber o que você achou do
              curso
              <br /> <span>{courseName}</span>.
            </p>

            <span>Qual nota você dá para sua experiência no curso?</span>
            <FormGroup
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                margin: '0 auto',
                width: '100%',
              }}
            >
              <RadioGroup
                className={`${style.containerRadio} ${validateForm.rating_course.status}`}
                row
                aria-label="position"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  name="rating_course"
                  value="1"
                  control={<Radio color="primary" />}
                  label="1"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_course"
                  value="2"
                  control={<Radio color="primary" />}
                  label="2"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_course"
                  value="3"
                  control={<Radio color="primary" />}
                  label="3"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_course"
                  value="4"
                  control={<Radio color="primary" />}
                  label="4"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_course"
                  value="5"
                  control={<Radio color="primary" />}
                  label="5"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_course"
                  value="6"
                  control={<Radio color="primary" />}
                  label="6"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_course"
                  value="7"
                  control={<Radio color="primary" />}
                  label="7"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_course"
                  value="8"
                  control={<Radio color="primary" />}
                  label="8"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_course"
                  value="9"
                  control={<Radio color="primary" />}
                  label="9"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_course"
                  value="10"
                  control={<Radio color="primary" />}
                  label="10"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
              </RadioGroup>
              {/* <FormHelperText>{validateForm.rating_course.msg}</FormHelperText> */}
            </FormGroup>
            <p className='motivo'>
              Nos diga o motivo da sua nota:
              <span>maximo de 255 caracteres</span>
            </p>
            <FormGroup
              style={{
                width: '100%',
              }}
            >
              <TextareaAutosize
                name="about_course"
                className={style.textArea}
                placeholder="Escreava aqui"
                onChange={handleChange}
                maxLength={255}
              />
              <FormHelperText>{validateForm.about_teacher.msg}</FormHelperText>
            </FormGroup>
          </Box>

          <Box className={style.rating}>
            <span>Qual nota você dá para sua experiência com o professor?</span>
            <FormGroup
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <RadioGroup
                className={`${style.containerRadio} ${validateForm.rating_course.status}`}
                row
                aria-label="position"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  name="rating_teacher"
                  value="1"
                  control={<Radio color="primary" />}
                  label="1"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_teacher"
                  value="2"
                  control={<Radio color="primary" />}
                  label="2"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_teacher"
                  value="3"
                  control={<Radio color="primary" />}
                  label="3"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_teacher"
                  value="4"
                  control={<Radio color="primary" />}
                  label="4"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_teacher"
                  value="5"
                  control={<Radio color="primary" />}
                  label="5"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_teacher"
                  value="6"
                  control={<Radio color="primary" />}
                  label="6"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_teacher"
                  value="7"
                  control={<Radio color="primary" />}
                  label="7"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_teacher"
                  value="8"
                  control={<Radio color="primary" />}
                  label="8"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_teacher"
                  value="9"
                  control={<Radio color="primary" />}
                  label="9"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="rating_teacher"
                  value="10"
                  control={<Radio color="primary" />}
                  label="10"
                  labelPlacement="bottom"
                  onChange={handleChange}
                />
              </RadioGroup>
              {/* <FormHelperText>{validateForm.rating_teacher.msg}</FormHelperText> */}
            </FormGroup>
            <p  className='motivo'>
              Nos diga o motivo da sua nota:
              <span>maximo de 255 caracteres</span>
            </p>
            <FormGroup
              style={{
                width: '100%',
                marginBottom: 32,
                borderBottom: '1px solid #D4D2D2',
              }}
            >
              <TextareaAutosize
                name="about_teacher"
                className={style.textArea}
                placeholder="Escreava aqui"
                onChange={handleChange}
                maxLength={255}
              />
              <FormHelperText>{validateForm.about_teacher.msg}</FormHelperText>
            </FormGroup>
          </Box>
        </Box>

        <CustomButton type="submit" disabled={!btnSubmit} style={{marginTop: 20}}>
          Avaliar e baixar certificado
        </CustomButton>
      </form>
    </Dialog>
  );
};

export default RateCourse;
