import React from 'react';
import DashAdmin from '~/Components/DashAdmin';
import TableConfiguration from '~/Components/Tables/admin/TableConfiguration';

// import { Container } from './styles';

export default function ListBanners() {
  return (
    <>
      <DashAdmin
        content={<TableConfiguration />}
        title={'Lista de Banners'}
        width={'100%'}
      />
    </>
  );
}
