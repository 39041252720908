import React, { useState, useEffect } from 'react';
import api from '~/Services/api';

import Skeleton from '@material-ui/lab/Skeleton';
import { Typography, Button, Dialog, DialogTitle, DialogActions } from '@material-ui/core';

import globalStyles from '~/Assets/css/globalStyles';

import { useSelector } from 'react-redux';

import Header from '~/Components/Header';
import UnsubscribeModal from '~/Components/Modals/UnsubscribeModal';
import history from '~/Services/history';

import 'moment/locale/pt-br';
const moment = require('moment');
moment.locale('pt-br')

const Curso = (props) => {
  const pattern = globalStyles()

  const id = props.match.params.name.split('-').pop()

  const profile = useSelector(state => state.user.profile)
  const token = useSelector(state => state.auth.token)

  const [layout, setLayout] = useState({
    openDialog: false,
    msg: '',
    iAgree: false,
  })

  const [course, setCourse] = useState([])
  const [courseStudent, setCourseStudent] = useState([])

  const [drawer, setDrawer] = useState({
    name: '',
    show: false
  })

  const getCourse = async () => {
    const res = await api.get(`course/${id}`)
    if (res.data.error) {
      return history.push('/')
    }
    setCourse(res.data[0])
  }

  const getCourseStudent = async () => {
    try {
      if (profile) {
        const res = await api.get(`student/my_subscribes/${profile.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        if (res.data[res.data.findIndex(x => x.course_id === parseInt(id))]) {
          await setCourseStudent([res.data[res.data.findIndex(x => x.course_id === parseInt(id))]])
        }
      }
    } catch (error) {
      return
    }
  }

  useEffect(() => {
    getCourse()
    getCourseStudent()
  }, [])

  useEffect(() => {
    getCourseStudent()
    if (layout.btnSubscribe) {
      subscribe()
    }
  }, [profile])

  function handleDialog() {
    setLayout({ ...layout, openDialog: !layout.openDialog })
  }

  const subscribe = async () => {
    try {
      setLayout({ ...layout, btnSubscribe: true, iAgree: false })
      if (!profile) {
        return setDrawer({ name: 'login', show: !drawer.show })
      }
      if (courseStudent.length > 0) {
        if (courseStudent[0].renunciation) {
          const obj = {
            student_id: courseStudent[0].student_id,
            course_id: courseStudent[0].course_id,
            renunciation: false,
          }
          await api.put(`subscribe/${courseStudent[0].id}`, obj)
          getCourseStudent()
          getCourse()
          return
        }
      }

      const obj = {
        student_id: profile.id,
        course_id: parseInt(id),
        renunciation: false,
      }
      const res = await api.post('subscribe', obj)
      getCourseStudent()
      getCourse()
    } catch (error) {
      return
    }
  }

  const unsubscribe = async () => {
    handleDialog()
  }

  function handleIAgree() {
    setLayout({ ...layout, iAgree: !layout.iAgree })
  }

  return (
    <>
      <Header open={drawer} />
      <div className={pattern.content} >
        {!course.id ?
          <Skeleton variant="rect" width={200} height={100} />
          :
          <>
            <h1 className={pattern.title}><Typography>{course.name}</Typography></h1>

            <div>
              {course.status === 'canceled' ? <span>{course.reason_canceled}</span>
                : ""}
            </div>

            <div>
              {
                course.status === 'canceled' ?
                  <span>Cancelado</span>
                  :
                  moment().format('DD/MM/YYYY') > moment(course.date_of_course).subtract(10, 'days').calendar() ?
                    courseStudent.length > 0 ?
                      parseInt(courseStudent[0].renunciation) === 1 ?
                        <div>
                          <span>DESISTIU</span>
                          {parseInt(course.quantity) === parseInt(course.totalQuantity) ?
                            <span>Vagas Esgotadas</span>
                            :
                            <Button onClick={handleIAgree}>Inscrever-se novamente</Button>
                          }
                        </div>
                        :
                        <div>
                          <span>INSCRITO</span>
                          {parseInt(course.quantity) === parseInt(course.totalQuantity) ?
                            <span>Vagas Esgotadas</span>
                            :
                            <Button onClick={unsubscribe}>Não poderei participar</Button>
                          }
                        </div>
                      :
                      <div>
                        {parseInt(course.quantity) === parseInt(course.totalQuantity) ?
                          <span>Vagas Esgotadas</span>
                          :
                          <Button onClick={handleIAgree}>INSCREVA-SE</Button>
                        }
                      </div>
                    :
                    <span>Em Breve</span>
              }
            </div>
          </>
        }

        <Dialog
          open={layout.iAgree}
          onClose={handleIAgree}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Li as regras do curso.</DialogTitle>
          <DialogActions>
            <Button onClick={subscribe} color="primary" autoFocus>
              Estou de Acordo
            </Button>
            <Button onClick={handleIAgree} color="primary" autoFocus>
              Não estou de Acordo!
            </Button>
          </DialogActions>
        </Dialog>

        <UnsubscribeModal open={layout.openDialog} handleDialog={handleDialog} getCourseStudent={getCourseStudent} subscribe={courseStudent} />
      </div>
    </>
  )
}

export default Curso
