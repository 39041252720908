import { createTheme, makeStyles } from '@material-ui/core/styles';
import setaNext from '~/Assets/img/seta-next.jpg';
import setaPrev from '~/Assets/img/seta-prev.jpg';
import vacancyDesk from '~/Assets/img/banner-moeda-desk.jpg';
import vacancyMobile from '~/Assets/img/banner-moeda-mobile.jpg';

const shadow = '0px 2px 5px 0px rgb(39 30 30 / .2)';
const shadowHover = '0px 2px 15px 0px rgb(39 30 30 / .4)';

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles(theme => ({
  home: {},

  titleHome: {
    color: '#d81717',
    fontSize: 32,
    width: '100%',
  },

  containerBigSquare: {
    justifyContent: 'space-between',
    [responsive.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },

    '&.infra_local': {
      marginTop: 69,

      '& .infra_local--box': {
        width: 628,

        '& $titleHome': {
          paddingBottom: 10,
        },

        [responsive.breakpoints.down('md')]: {
          width: '49%',
        },

        [responsive.breakpoints.down('sm')]: {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',

          '&:nth-child(2)': {
            marginTop: 60,
          },
        },

        '& $boxLocal, $boxInfra': {
          width: '100%',
          maxWidth: 628,
          height: 490,
          boxShadow: shadow,
          borderRadius: 5,
          overflow: 'hidden',
          background: '#fff',
          position: 'relative',
          transition: 'linear .1s',

          [theme.breakpoints.down('sm')]: {
            height: 282,
          },

          '&:hover': {
            boxShadow: shadowHover,
          },
        },
      },
    },
  },

  boxBigSquare: {
    width: 628,
    height: 490,
    boxShadow: shadow,
    borderRadius: 5,
    overflow: 'hidden',
    background: '#fff',
    position: 'relative',
    transition: 'linear .1s',

    '&:hover': {
      boxShadow: shadowHover,
    },

    [responsive.breakpoints.between('md', 'lg')]: {
      width: '49%',
    },

    [responsive.breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
      marginTop: 20,

      '&:nth-child(2)': {
        marginTop: 20,
      },
    },
  },

  styleSlides: {
    '&.slide_banner_home': {
      '& .slick-dots': {
        bottom: 14,
      },
    },

    '& .box_mini_slide': {
      width: '196px !important',
      height: 280,
      display: 'block',
      borderRadius: 5,
      overflow: 'hidden',
      boxShadow: shadow,
      margin: '15px 0',

      [responsive.breakpoints.up('md')]: {
        '&:hover': {
          boxShadow: shadowHover,
          borderBottom: '5px solid #d81717',
        },
      },

      '& .image_mini_slide': {
        width: '100%',
        height: 224,
        display: 'flex',
        justifyContent: 'center',
        background: '#fff',

        '& img': {
          maxWidth: 'auto',
          height: '100%',
        },
      },

      '& .name_mini_slide': {
        width: '100%',
        height: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fff',

        '& > p': {
          color: '#271e1e',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: 160,
          textAlign: 'center',
          fontSize: 22,
        },
      },

      '&  p': {
        color: '#271e1e',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        minWidth: 150,
      },
    },

    '&.slick-slider': {
      textAlign: 'center',
      height: 490,
    },

    '& .slick-dots': {
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center',
      height: 30,

      '& li': {
        padding: 0,
        margin: 5,
        width: 10,
        height: 10,
        overflow: 'hidden',
        borderRadius: '50%',
        transition: 'linear .1s',

        '&.slick-active': {
          width: 20,
          height: 20,

          '& button': {
            background: '#d81717',
          },
        },

        '& button': {
          background: '#fff',
          padding: 0,
          '&:before': {
            display: 'none',
          },
        },
      },
    },

    '& .slick-arrow': {
      width: 50,
      height: 72,
      background: '#fff',
      zIndex: 1,
      opacity: 0.7,
      transition: 'linear .2s',

      '&:before': {
        color: 'transparent',
        display: 'flex',
        width: 50,
        height: 72,
        backgroundPosition: 'center',
      },

      '&.slick-next': {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        right: 0,

        '&:before': {
          backgroundImage: `url('${setaNext}')`,
          backgroundRepeat: 'no-repeat',
        },
      },

      '&.slick-prev': {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        left: 0,

        '&:before': {
          backgroundImage: `url('${setaPrev}')`,
          backgroundRepeat: 'no-repeat',
        },
      },

      '&:hover': {
        opacity: 1,
      },
    },
  },

  boxBanner: {
    position: 'relative',

    [responsive.breakpoints.down('sm')]: {
      top: '1.5px',

      '& img': {
        width: '100%',
      },
    },

    '& .title_banner': {
      position: 'absolute',
      fontSize: 54,
      fontWeight: 'bold',
      color: '#fff',
      bottom: 41,
      width: '100%',

      [responsive.breakpoints.between('md', 'md')]: {
        fontSize: '4.2vw',
      },
    },
  },

  containerCourses: {
    maxWidth: 1300,
    margin: '41px auto',

    [responsive.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },

  boxNextCourse: {
    color: '#271e1e',

    '&:hover': {
      '& .link_next_course': {
        background: '#ee2526',
      },
    },

    '& .side_left, .side_right': {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    '& .side_left': {
      '& .photo_teacher': {
        maxWidth: 260,
        height: 260,
        overflow: 'hidden',
        borderRadius: '50%',
        marginTop: 20,

        '& img': {
          maxWidth: 'auto',
          height: '100%',
          border: '5px solid #B01010',
          borderRadius: '50%',
        },
      },
      '& .schedule': {
        marginTop: 65,
        height: 60,
        width: '100%',

        '& .MuiBox-root': {
          width: '50%',
        },

        '& .date_of_course, .hour_start': {
          fontWeight: 'bold',
          fontSize: 22,

          [responsive.breakpoints.only('md')]: {
            fontSize: 18,
          },
        },
        '& .hour_end': {
          fontWeight: 'bold',
          fontSize: 18,
        },

        '& .label_hour_end, .label_hour_start, .day_week_course, .hour_end': {
          [responsive.breakpoints.only('md')]: {
            fontSize: 15,
          },
        },
      },
      '& .price': {
        fontSize: 32,
        color: '#d81717',
        fontWeight: 'bold',
        paddingTop: 11,
        textAlign: 'center',
      },
    },

    '& .side_right': {
      padding: 12,
      '& .name_teacher': {
        fontSize: 22,
        marginTop: 33,
        textAlign: 'center',
        height: 33,

        [responsive.breakpoints.only('md')]: {
          fontSize: 18,
        },
      },

      '& .name_course': {
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        fontSize: 22,
        lineHeight: '23px',
        marginTop: 4,
        height: 73,

        [responsive.breakpoints.down('md')]: {
          fontSize: 18,
        },
      },

      '& .tag_course': {
        background: '#f4f4f4',
        textAlign: 'center',
        display: 'flex',
        width: 200,
        margin: '0 auto',
        marginTop: 10,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 700,
        color: '#271e1e',
        padding: '2px 0',
      },

      '& .box_categories': {
        marginTop: 14,
        marginBottom: 13,
        justifyContent: 'center',
        height: 33,

        '& .category': {
          color: '#d81717',
          background: '#f5f5f5',
          padding: '0 10px',
          borderRadius: 25,
          margin: 5,
          whiteSpace: 'nowrap',
        },
      },

      '& .description_course': {
        fontSize: 14,
        height: 149,
        overflow: 'hidden',
        lineHeight: 1.5,
      },

      '& .link_next_course': {
        display: 'block',
        width: '100%',
        maxWidth: 280,
        borderRadius: 5,
        background: props => props.bgButton,
        color: props => props.labelColor,
        border: props => props.borderButton,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 35,
        padding: '0 10px',

        '& .MuiTypography-root': {
          '&:nth-child(1)': {
            fontSize: 22,
            paddingTop: 6,
          },

          '&:nth-child(2)': {
            position: 'relative',
            top: -4,
            paddingBottom: 8,
          },
        },
      },

      '& span': {
        fontSize: 14,

        '& button': {
          marginLeft: 5,
          color: '#419a58',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          background: 'transparent',
        },
      },
    },
  },

  boxReadMoreCourses: {
    borderTop: '5px solid #a9a5a5',
    borderRadius: 5,
    width: 304,
    height: 420,
    background: '#d4d2d2',
    marginTop: 65,
    marginLeft: 10,
    marginRight: 10,
    boxShadow: shadow,
    transition: 'linear .1s',

    '&:hover': {
      boxShadow: shadowHover,
    },

    '& .circle_arrow': {
      background: '#f4f4f4',
      width: 152,
      height: 152,
      borderRadius: 76,
    },

    '& .label_read_more_courses': {
      color: '#5d5656',
      fontSize: 32,
      textAlign: 'center',
      paddingTop: 7,
    },
  },

  boxMiniSlides: {
    '&.categories': {
      marginTop: 40,
    },

    '&.teachers': {
      marginTop: 62,
    },
  },

  boxPricePeriod: {
    marginTop: 72,

    [responsive.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },

    '& .box': {
      maxWidth: 608,

      '&.price': {
        [responsive.breakpoints.down('sm')]: {
          marginTop: 30,
        },
      },

      [responsive.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
  },

  boxSquare: {
    width: 132,
    height: 137,
    margin: 10,
    borderRadius: 5,
    transition: 'linear .05s',

    '& > .MuiBox-root': {
      width: 132,
      height: 132,
      borderRadius: 5,
      background: '#fff',
      textAlign: 'center',
      boxShadow: shadow,

      '& .label_primary': {
        color: '#271e1e',
        fontSize: 22,
      },

      '& .label_secondary': {
        color: '#5d5656',
        fontSize: 14,
      },
    },

    '&:hover': {
      borderBottom: '5px solid #d81717',
      boxShadow: shadowHover,
    },

    [responsive.breakpoints.down('xs')]: {
      '& , > .MuiBox-root': {
        width: 150,
        height: 92,
      },
    },
  },

  boxDepoiments: {
    marginTop: 102,

    [responsive.breakpoints.down('sm')]: {
      '& .image_depoiment': {
        order: 2,
      },

      '& .box_content': {
        order: 1,
        marginBottom: 35,
      },
    },

    '& .image_depoiment': {
      marginRight: 31,

      [responsive.breakpoints.down('sm')]: {
        marginRight: 0,

        '& img': {
          maxWidth: '100%',
          height: 'auto',
        },
      },
    },

    '& .box_content': {
      width: 488,
      height: 253,
      borderRadius: 20,
      position: 'relative',
      background: '#fff',

      [responsive.breakpoints.down('sm')]: {
        width: 'calc(100% - 16px)',
      },

      '& .aspas': {
        fontSize: 82,
        fontFamily: "'nunito',sans-serif !important",
        color: '#d4d2d2',
        fontStyle: 'normal',

        '&.aspas_start': {
          display: 'flex',
          alignItems: 'flex-start',
          position: 'relative',
          top: -23,
        },

        '&.aspas_end': {
          display: 'flex',
          alignItems: 'flex-end',
          position: 'relative',
          bottom: -15,
        },
      },

      '& .arrow_depoiment': {
        borderTop: '45px solid #fff',
        borderLeft: '55px solid transparent',
        borderBottom: '35px solid transparent',
        display: 'block',
        width: 0,
        height: 0,
        position: 'absolute',
        left: -54,
        top: 68,

        [responsive.breakpoints.down('sm')]: {
          right: '54px',
          bottom: '-47px',
          left: 'auto',
          top: 'auto',
          borderTop: '55px solid #fff',
          borderLeft: '45px solid transparent',
          borderBottom: '0px solid transparent',
        },
      },

      '& .name_student': {
        paddingTop: 41,
        paddingLeft: 59,
        fontSize: 22,
      },

      '& .area_text': {
        paddingLeft: 20,
        paddingTop: 21,

        [responsive.breakpoints.down('sm')]: {
          padding: '20px 10px 0 10px',
        },
      },

      '& .text_depoiment': {
        fontStyle: 'italic',
        fontSize: 14,
        color: '#271e1e',
        paddingLeft: 10,
        paddingRight: 7,
        width: 377,
        height: 100,
        overflow: 'hidden',

        [responsive.breakpoints.down('sm')]: {
          maxWidth: 377,
          width: 'auto',
        },
      },
    },
  },

  boxInfra: {},

  boxLocal: {
    '& .address': {
      height: 174,
      paddingTop: 27,

      '& $titleHome': {
        [responsive.breakpoints.only('md')]: {
          fontSize: 27,
        },

        [responsive.breakpoints.only('xs')]: {
          fontSize: 25,
        },
      },

      '& .text': {
        color: '#270e1e',
        fontSize: 22,

        [responsive.breakpoints.only('md')]: {
          fontSize: 18,
        },

        [responsive.breakpoints.only('xs')]: {
          fontSize: 15,
        },
      },
    },
  },

  containerSpecification: {
    marginTop: 80,
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    [responsive.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  },

  boxSpecification: {
    width: 370,

    '&:hover': {
      '& .image img': {
        transform: 'scale(1.1)',
      },
    },

    [responsive.breakpoints.down('sm')]: {
      marginBottom: 80,
      width: 'auto',
      maxWidth: 370,

      '&.course_free': {
        marginBottom: 0,
      },
    },

    '& .text': {
      color: '#271e1e',
      fontSize: 22,
    },

    '& .image': {
      width: 200,
      height: 200,
      borderRadius: 100,
      marginTop: 41,

      '& img': {
        transition: 'linear .1s',
      },
    },
  },

  boxVacancy: {
    boxShadow: shadow,
    width: 960,
    height: 280,
    backgroundImage: `url(${vacancyDesk})`,
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    top: 71,

    [responsive.breakpoints.down('xs')]: {
      backgroundImage: `url(${vacancyMobile})`,
      width: 320,
      height: 356,
      flexWrap: 'wrap',
    },

    '&:hover': {
      boxShadow: shadowHover,

      '& .box-right .link_all_courses': {
        background: '#ee2526',
      },
    },

    '& .box': {
      width: '50%',

      [responsive.breakpoints.down('xs')]: {
        width: '100%',
        height: 'max-content',
      },
    },

    '& .box-left': {
      paddingLeft: 95,

      [responsive.breakpoints.down('sm')]: {
        paddingLeft: '5vw',
      },

      [responsive.breakpoints.down('xs')]: {
        paddingLeft: 20,
      },

      '& $titleHome': {
        paddingTop: 40,

        [responsive.breakpoints.down('sm')]: {
          fontSize: 28,
        },

        [responsive.breakpoints.down('xs')]: {
          fontSize: 26,
        },
      },

      '& .text': {
        color: '#271e1e',
        fontSize: 22,
        width: 400,
        lineHeight: 'normal',

        [responsive.breakpoints.down('sm')]: {
          width: '100%',
          fontSize: 18,
        },

        '&.bold': {
          fontSize: 32,
          fontWeight: 'bold',
          paddingTop: 27,

          [responsive.breakpoints.down('sm')]: {
            fontSize: 28,
          },

          [responsive.breakpoints.down('xs')]: {
            fontSize: 24,
          },
        },
      },
    },

    '& .box-right': {
      '& .link_all_courses': {
        width: 280,
        height: 72,
        background: '#d81717',
        borderRadius: 5,
        color: '#fff',
        fontSize: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
  },

  carouselHomeMobile: {
    overflow: 'hidden',

    '& .area_carousel': {
      '&.courses': {
        marginTop: 20,
        marginRight: 10,
      },

      flexWrap: 'nowrap',
      overflowX: 'scroll',
      padding: '10px 0',

      '& > .MuiBox-root': {
        flex: 'none',
      },

      '& .box_mini_slide': {
        marginRight: 20,
      },

      '& $boxReadMoreCourses': {
        height: 440,
      },
    },
  },

  boxBannerMobile: {
    boxShadow: shadow,
    width: 300,
    height: 234,
    display: 'flex',
    justifyContent: 'center',
    marginRight: 20,
    marginBottom: 10,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 5,

    '& img': {
      height: '100%',
    },

    '& .title_banner': {
      position: 'absolute',
      color: '#fff',
      fontSize: 25,
      fontWeight: 'bold',
      bottom: 20,
    },
  },

  linkAllCourses: {
    background: '#d4d2d2',
    color: '#5d5656',
    fontSize: 16,
    textDecoration: 'none',
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    boxShadow: shadow,

    [responsive.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  infraRoom: {
    '& .boxRoom': {
      width: '100%',

      '& .boxRoomImg': {
        height: 490,

        [theme.breakpoints.down('sm')]: {
          height: 'auto',
        },

        '& img': {
          display: 'block',
          width: '100%',
          height: 'inherit',
        },
      },
    },
  },
}));

export default useStyles;
