const validateInputForm = (schema, name, obj, validateForm, setValidateForm) => {

  schema.validateAt(name, obj).then(() => {
    switch (name) {
      case 'donation':
        setValidateForm({
          ...validateForm,
          [name]: {
            msg: '',
            status: 'is_valid'
          },
          price: {
            msg: '',
            status: 'is_valid'
          }
        })
        break;

      case 'price':
        setValidateForm({
          ...validateForm,
          [name]: {
            msg: '',
            status: 'is_valid'
          },
          donation: {
            msg: '',
            status: 'is_valid'
          }
        })
        break;

      default:
        setValidateForm({
          ...validateForm,
          [name]: {
            msg: '',
            status: 'is_valid'
          },
        })
        break;
    }
  }).catch(err => {
    setValidateForm({
      ...validateForm,
      [name]: {
        msg: err.message,
        status: 'invalid'
      },
    })
  })
}

const validateForms = (schema, obj, setBtnSubmit) => {
  schema.validate(obj).then(() => {
    setBtnSubmit(true)
  }).catch(err => {
    setBtnSubmit(false)
  })
}

export { validateInputForm, validateForms }
