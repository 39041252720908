const { makeStyles, createTheme } = require("@material-ui/core/styles");

const shadow = '0px 2px 5px 0px rgb(39 30 30 / .2)'
const shadowHover = '0px 2px 15px 0px rgb(39 30 30 / .4)'


const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    width: 304,
    height: 440,
    margin: '10px 0',
    marginTop: 65,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 24,
  },

  boxCourse: {
    borderTop: props => `5px solid ${props.borderColor}`,
    background: '#fff',
    transition: 'linear .05s',
    borderRadius: 5,
    height: 420,
    boxShadow: shadow,

    [responsive.breakpoints.up('md')]: {
      '&:hover': {
        height: 440,
        boxShadow: shadowHover,

        '& $btnCardCourse': {
          height: 75,
          overflow: 'none',

          '& .label_second': {
            color: props => props.labelColor
          }
        }
      }
    },

    [responsive.breakpoints.down('sm')]: {
      height: 440,
      boxShadow: shadowHover,

      '& $btnCardCourse': {
        height: 75,
        overflow: 'none',

        '& .label_second': {
          color: props => props.labelColor
        }
      }

    },

  },

  photoTeacher: {
    width: 152,
    height: 152,
    borderRadius: '100%',
    border: '5px solid #f4f4f4',
    overflow: 'hidden',
    position: 'absolute',
    top: -76,
    background: '#f4f4f4',

    '& img': {
      maxWidth: '100%',
      height: 'auto',
    }
  },

  nameTeacher: {
    fontSize: 22,
    color: '#271e1e',
    padding: '0 10px',
    textAlign: 'center',
    marginTop: 91,
  },

  nameCourse: {
    fontSize: 16,
    color: '#271e1e',
    padding: '0 10px',
    textAlign: 'center',
    marginTop: 10,
    height: 40,
    lineHeight: '20px',
    overflow: 'hidden',
    textTransform: 'lowercase',
  },

  boxCategories: {
    marginTop: 14,
  },

  nameCategory: {
    color: '#d81717',
    background: '#f4f4f4',
    padding: '0 18px',
    borderRadius: 10,
    marginRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  boxInfosCourse: {
    marginTop: 12,

    '& *': {
      color: '#271e1e'
    },

    '& .box': {
      width: '50%',
    },

    '& .date_course, .hour_start_course, .hour_end_course': {
      fontWeight: 'bold',
    },

    '& .date_course, .hour_start_course': {
      fontSize: 22,
    }
  },

  priceCourse: {
    fontSize: 32,
    color: '#d81717',
    fontWeight: 'bold',
    marginTop: 11,
    textAlign: 'center',
  },

  btnCardCourse: {
    background: props => props.bgButton,
    color: '#fff',
    height: 55,
    overflow: 'hidden',
    transition: 'linear .05s',
    marginTop: 20,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,

    '& .label_primary': {
      fontSize: 22,
      height: 55,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: props => props.labelColor,

      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        fontSize: 22,
        height: 55,
      },

      '& span:nth-child(2)': {
        display: 'none',
      },



    },

    '& .label_second': {
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      top: -15,
      transition: 'linear .2s',
      color: 'transparent',
    }
  }
}))

export default useStyles
