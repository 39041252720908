import React, { useState, useEffect, useContext } from 'react';
import * as yup from 'yup'

import useStyles from '../style'

import { Box, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { InputForm, CustomButton, TitleForm } from '~/Components/Custom';
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { validateForms, validateInputForm } from '../configYup';
import { MaskCpf, MaskPhone } from '../Maks';

import { Context } from '~/Context/AuthContext'

function FormUser() {
  const { createUser, setCreateUser, handleSignUp, alertMessage } = useContext(Context)

  const style = useStyles()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [btnSubmit, setBtnSubmit] = useState(false)
  const [validateForm, setValidateForm] = useState({
    cpf: {
      msg: '',
      status: ''
    },
    fullname: {
      msg: '',
      status: ''
    },
    email: {
      msg: '',
      status: ''
    },
    telephoneOne: {
      msg: '',
      status: ''
    },
    telephoneTwo: {
      msg: '',
      status: ''
    },
    password: {
      msg: '',
      status: ''
    },
    confirmPassword: {
      msg: '',
      status: ''
    }
  })

  const schema = yup.object().shape({
    cpf: yup.string().required("Campo Obrigatório!").min(14, "CPF incompleto"),
    fullname: yup.string().test('fullname', 'Insira nome e sobrenome', function (value) {
      if (value.split(" ").length >= 2 && value.split(" ")[1].length >= 2) {
        return true;
      } else {
        return false;
      }
    }),
    email: yup.string().email("Formato de email inválido!").required("Campo Obrigatório!"),
    telephoneOne: yup.string().when('telephoneTwo', (telephoneTwo, field) =>
      createUser.telephoneTwo !== "" ? field : field.required("Necessário 1 contato!").min(14, "Telefone Incompleto")
    ),
    telephoneTwo: yup.string().when((telephoneOne, field) =>
      createUser.telephoneOne !== "" ? field : field.required("Necessário 1 contato!").min(14, "Telefone incompleto")
    ),
    password: yup.string().required("Campo Obrigatório!").min(6, "Mínimo 6 dígitos!"),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Senhas não conferem!').required("Campo Obrigatório !"),
  })

  function handleValues(e) {
    const { value, name } = e.target
    let obj;
    if (name === 'confirmPassword') {
      obj = { [name]: value, password: createUser.password }
    } else {
      obj = { [name]: value }
    }
    validateInputForm(schema, name, obj, validateForm, setValidateForm)
    setCreateUser({ ...createUser, [name]: value })
  }

  useEffect(() => {
    validateForms(schema, createUser, setBtnSubmit)
  }, [createUser])

  return (
    <>
      <Box className={style.forms}>
        <form onSubmit={handleSignUp} >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box display='flex' justifyContent='center'>
                <span className={`${style.alert_form} ${alertMessage.status}`}>{alertMessage.msg}</span>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TitleForm>Cadastre-se e participe dos nossos cursos!</TitleForm>
            </Grid>
            <Grid item xs={12}>
              <InputForm variant='outlined' fullWidth className={validateForm.cpf.status}>
                <InputLabel id="input_cpf">CPF</InputLabel>
                <OutlinedInput
                  id="input_cpf"
                  name="cpf"
                  value={createUser.cpf}
                  onChange={handleValues}
                  labelWidth={30}
                  inputComponent={MaskCpf}
                />
                <FormHelperText>{validateForm.cpf.msg}</FormHelperText>
              </InputForm>
            </Grid>

            <Grid item xs={12}>
              <InputForm variant='outlined' fullWidth className={validateForm.fullname.status}>
                <InputLabel id="input_fullname">Nome Completo</InputLabel>
                <OutlinedInput
                  id="input_fullname"
                  name="fullname"
                  value={createUser.fullname}
                  onChange={handleValues}
                  labelWidth={125}
                />
                <FormHelperText>{validateForm.fullname.msg}</FormHelperText>
              </InputForm>
            </Grid>

            <Grid item xs={12}>
              <InputForm variant='outlined' fullWidth className={validateForm.email.status}>
                <InputLabel id="input_email">E-mail</InputLabel>
                <OutlinedInput
                  id="input_email"
                  name="email"
                  value={createUser.email}
                  onChange={handleValues}
                  type="email"
                  labelWidth={50}
                />
                <FormHelperText>{validateForm.email.msg}</FormHelperText>
              </InputForm>
            </Grid>

            <Grid item xs={12}>
              <InputForm variant='outlined' fullWidth className={validateForm.telephoneOne.status}>
                <InputLabel id="input_phone_1">Telefone 1</InputLabel>
                <OutlinedInput
                  id="input_phone_1"
                  name="telephoneOne"
                  value={createUser.telephoneOne}
                  onChange={handleValues}
                  labelWidth={80}
                  inputComponent={MaskPhone}
                />
                <FormHelperText>{validateForm.telephoneOne.msg}</FormHelperText>
              </InputForm>
            </Grid>

            <Grid item xs={12}>
              <InputForm variant='outlined' fullWidth className={validateForm.telephoneTwo.status}>
                <InputLabel id="input_phone_2">Telefone 2</InputLabel>
                <OutlinedInput
                  id="input_phone_2"
                  name="telephoneTwo"
                  value={createUser.telephoneTwo}
                  onChange={handleValues}
                  labelWidth={80}
                  inputComponent={MaskPhone}
                />
                <FormHelperText>{validateForm.telephoneTwo.msg}</FormHelperText>
              </InputForm>
            </Grid>

            <Grid item xs={12}>
              <InputForm variant="outlined" className={`${validateForm.password.status}`} fullWidth>
                <InputLabel htmlFor="input_password">Senha</InputLabel>
                <OutlinedInput
                  id="input_password"
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  value={createUser.password}
                  onChange={handleValues}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={e => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={50}
                />
                <FormHelperText>{validateForm.password.msg}</FormHelperText>
              </InputForm>
            </Grid>

            <Grid item xs={12}>
              <InputForm variant="outlined" className={validateForm.confirmPassword.status} fullWidth>
                <InputLabel htmlFor="input_password">Confirmar senha</InputLabel>
                <OutlinedInput
                  id="input_password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  name='confirmPassword'
                  value={createUser.confirmPassword}
                  onChange={handleValues}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={e => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={125}
                />
                <FormHelperText>{validateForm.confirmPassword.msg}</FormHelperText>
              </InputForm>
            </Grid>

            <Grid item xs={12}>
              <CustomButton type='submit' disabled={!btnSubmit} fullWidth>Cadastrar</CustomButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  )
}

export default FormUser;
