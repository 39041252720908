import { all, takeLatest, call, put } from 'redux-saga/effects'
import api from '~/Services/api'
import { updateProfileSuccess, updateProfileFailure } from './actions'

export function* updateProfileReq({ payload }) {
  try {
    const { id, fullname, email, telephoneOne, telephoneTwo, ...rest } = payload.data
    const profile = Object.assign({ id, fullname, email, telephoneOne, telephoneTwo }, rest.oldPassword ? rest : {})
    const res = yield call(api.put, `users/${id}`, profile)
    yield put(updateProfileSuccess(res.data))

  } catch (error) {
    yield put(updateProfileFailure())
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfileReq)
])
