const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  forms: {
    marginBottom: 80,
    '& form': {
      paddingBottom: 20,
    },


  },

  goback: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '75vh',
    paddingTop: 25,



    '& .item': {
      position: 'absolute',
      width: '100%',
      transition: 'linear .2s',

      '&:nth-child(1)': {
        right: '100%',
        '&.active': {
          right: 0
        }
      },

      '&:nth-child(2)': {
        left: '100%',
        '&.active': {
          left: 0
        }
      }
    },

    '& .label_goback': {
      color: '#b01010',
      cursor: 'pointer',

      '&:hover p': {
        textDecoration: 'underline',
      }
    }

  },

  alert_form: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    height: 50,
    width: 0,
    transition: 'width .3s',
    overflow: 'hidden',
    borderRadius: 3,
    fontSize: 18,

    '&.error': {
      background: '#fe6363',
      whiteSpace: 'nowrap',
      width: '100%'
    },

    '&.success': {
      background: '#61d061',
      width: '100%'
    },
  },

  boxUploadImg: {
    paddingBottom: 15,

    '& $btnUploadImg, $boxPreviewImg': {
      height: 125,
      width: 125,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 15,
    }
  },

  btnUploadImg: {
    background: '#b01010',
    borderRadius: 5,
    cursor: 'pointer',
    '& *': {
      color: '#fff',
      fontSize: 40
    }
  },

  boxPreviewImgUser: {
    width: 150,
    height: 150,

    '& > div': {
      overflow: 'hidden'
    },

    '& img': {
      maxWidth: '100%',
      height: 'auto'
    }
  },

  boxPreviewImg: {
    border: '3px dashed #ccc',
    borderRadius: 5,

    '& > div': {
      overflow: 'hidden'
    },

    '& img': {
      maxWidth: '100%',
      height: 'auto'
    }
  },

  inputFile: {
    display: 'none',
  },

  rating: {
    '& svg': {
      fontSize: 30,
    }
  }
}))

export default useStyles
