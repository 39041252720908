import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({

  root: {
    borderRadius: 8,
    margin: '0 16px',
  },

  textCapitalize: {
    textTransform: 'capitalize'
  },

  headerTable: {

    '&.MuiTableRow-head': {
      background: '#d01717',

      '& .MuiTableCell-head': {
        color: '#fff',
        fontSize: 18,
        fontWeight: '400',
      }
    },
  },

  bodyTable: {

    '& .MuiTableRow-root': {

      '& .MuiTableCell-body': {
        color: '#271E1E',
        fontSize: 14
      },

      '&:nth-child(even)': {
        background: '#f5f5f5',
      }
    }
  },

  message: {
    width: '100%',
    textAlign:'center',
    fontSize: '3vw',

    [theme.breakpoints.down('sm')]:{
      fontSize: '5vw',
    }
  }
}))

export default useStyles
