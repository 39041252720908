import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

import useStylesCard from './style'
import { Card, Avatar, CardContent } from '@material-ui/core';


import 'moment/locale/pt-br';
const moment = require('moment');
moment.locale('pt-br')

export default function CardCourse({ course }) {
  const classes = useStylesCard()

  const [status, setStatus] = useState({
    label: 'INSCREVA-SE',
    type: 'public',
  })

  useEffect(() => {
    verifyStatus()
  }, [])

  const verifyStatus = () => {

    let today = new Date()
    let parts = moment(course.date_of_course).subtract(10, 'days').format('DD/MM/YYYY').split('/')
    let date = new Date(parts[2], parts[1] - 1, parts[0])

    if (course.status === 'canceled') {
      return setStatus({ label: 'Cancelado', type: "canceled" })
    }
    if(today < date){
      return setStatus({ label: 'Inscrição em Breve', type: "coming_soon" })
    }
    if (parseInt(course.quantity) === parseInt(course.totalQuantity)) {
      return setStatus({ label: 'Esgotado', type: "full" })
    }
    if (parseInt(course.quantity) > (parseInt(course.totalQuantity) - 10)) {
      return setStatus({ label: 'Últimas vagas', type: "lastVacancies" })
    }
    return setStatus({ label: 'Inscreva-se', type: "public" })
  }

  const handleSlug = (name) => {
    name = name.replace(/ /g, '-').toLowerCase()
    return `${name}-${course.id}`
  }

  return (
    <>
      <a href={`/curso/${handleSlug(course.name)}`}>
        <Card className={`${classes.card} ${status.type}`}>
          <Avatar className={classes.avatarCard} src={course.path_image} />
          <CardContent className={classes.contentCard}>
            <p className={classes.nameProfCard} >{course.fullname}</p>
            <p className={classes.nameCourseCard}>{course.name}</p>
            <div className={classes.areaCategories} >
              {
                course.categories.map(category => (
                  <p className={classes.nameCategoryCard} key={category.name}><span>{category.name}</span></p>
                ))
              }
            </div>
            <div className={classes.infosDateTimeCard}>
              <div className={classes.dateCard}>
                <p>{moment(course.date_of_course).format('DD/MM/YYYY')}</p>
                <p>{moment(course.date_of_course).format('dddd')}</p>
              </div>
              <div className={classes.timeCard}>
                <p>início: <span>{course.hour_start}</span></p>
                <p>término: <span>{course.hour_end}</span></p>
              </div>
            </div>
            <p className={classes.priceCard}>{course.price !== '0' ? course.price : 'grátis'}</p>
            <p className={classes.btnCourseCard}>
              {status.label}
            </p>
            <p className={`${classes.btnCourseCard} ${classes.btnReadMore}`}>saiba mais</p>
          </CardContent>
        </Card>
      </a>
    </>
  )
}

CardCourse.propTypes = {
  course: PropTypes.object.isRequired
}
