import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Slider from 'react-slick';

import categoria from '~/Assets/img/exemplo-categoria.jpg'


function SlideCategory({ className, categories, ...props }) {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          infinite: false,
          swipe: true,
          swipeToSlide: true,
          slidesToShow: 5,

        }
      },
      {
        breakpoint: 1024,
        settings: {
          infinite: false,
          swipe: true,
          swipeToSlide: true,
          slidesToShow: 3,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  }

  const toCamelCase = (string) => {
    return string.split(' ')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }).join(' ')
  }

  return (
    <>
      <Slider {...settings} className={`${className} slide_category`}>
        {categories.map(c => (
          <Box key={c.id} className='box_mini_slide' component='a' href={`/cursos?category=${c.name}`} >
            <Box className='image_mini_slide'>
              <img src={c.image_url || categoria} alt="" />
            </Box>
            <Box className='name_mini_slide'>
              <Typography>{toCamelCase(c?.name)}</Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </>
  )
}

export default SlideCategory;
