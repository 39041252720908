import React, { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import api from '~/Services/api'

/*** Estilizações ***/
import useStyles from '../style'

/*** Componentes ***/
import { Box, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@material-ui/core"
import { InputForm, CustomButton, TitleForm } from "~/Components/Custom"
import { ArrowBack, ArrowForward, Visibility, VisibilityOff } from '@material-ui/icons'

/*** Utils para os formulários ***/
import { MaskCpf } from '../Maks'
import { validateForms, validateInputForm } from '../configYup'

import { Context } from '~/Context/AuthContext'


const FormLogin = ({ handleDrawer, ...props }) => {

  const style = useStyles()

  const [goback, setGoback] = useState({
    next: false,
    prev: true
  })

  function handleGoback(action) {
    let obj;

    if (action === 'prev') {
      obj = {
        prev: true,
        next: false
      }
    } else {
      obj = {
        prev: false,
        next: true
      }
    }

    setGoback(obj)
  }

  return (
    <Box className={`${style.forms} ${style.goback}`}>
      <Box className={`item ${goback.prev ? 'active' : ''}`}>
        <BoxLogin handleGoback={handleGoback} />
      </Box>

      <Box className={`item ${goback.next ? 'active' : ''}`}>
        <BoxForgotPass handleGoback={handleGoback} />
      </Box>
    </Box>
  )
}

function BoxLogin({ handleGoback }) {
  const style = useStyles()
  const { user, setUser, handleSignIn, alertMessage } = useContext(Context)

  const [showPassword, setShowPassword] = useState(false)
  const [btnSubmit, setBtnSubmit] = useState(false)
  const [validateForm, setValidateForm] = useState({
    cpf: {
      msg: '',
      status: '',
    },

    password: {
      msg: '',
      status: '',
    }
  })

  const schema = yup.object().shape({
    cpf: yup.string().required('Campo Obrigatório').min(14, 'CPF incompleto!'),
    password: yup.string().required('Campo Obrigatório').min(6, 'Senha incompleta, mínimo 6 caracteres!')
  })

  const handleValues = (e) => {
    const { value, name } = e.target
    validateInputForm(schema, name, { [name]: value }, validateForm, setValidateForm)
    setUser({ ...user, [name]: value })
  }

  useEffect(() => {
    validateForms(schema, user, setBtnSubmit)
  }, [user])

  return (
    <form onSubmit={handleSignIn}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <span className={`${style.alert_form} ${alertMessage.status}`}>{alertMessage.msg}</span>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TitleForm>Faça seu login!</TitleForm>
        </Grid>

        <Grid item xs={12}>
          <InputForm variant='outlined' className={`${validateForm.cpf.status}`} fullWidth>
            <InputLabel>CPF</InputLabel>
            <OutlinedInput
              label='CPF'
              name='cpf'
              value={user.cpf}
              onChange={handleValues}
              inputComponent={MaskCpf}
            />
            <FormHelperText>{validateForm.cpf.msg}</FormHelperText>
          </InputForm>
        </Grid>

        <Grid item xs={12}>
          <InputForm variant="outlined" className={`${validateForm.password.status}`} fullWidth>
            <InputLabel htmlFor="input_password">Senha</InputLabel>
            <OutlinedInput
              id="input_password"
              type={showPassword ? 'text' : 'password'}
              name='password'
              onChange={handleValues}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={e => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label='Senha'
            />
            <FormHelperText>{validateForm.password.msg}</FormHelperText>
          </InputForm>
        </Grid>

        <Grid item xs={12}>
          <CustomButton size='large' type='submit' fullWidth disabled={!btnSubmit}>Entrar</CustomButton>
        </Grid>

        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end' alignItems='center'>
            <Box display='flex' className='label_goback' onClick={e => handleGoback('next')}>
              <Typography>Esqueci minha senha</Typography>
              <ArrowForward />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

function BoxForgotPass({ handleGoback }) {
  const style = useStyles()
  const [btnSubmit, setBtnSubmit] = useState(false)
  const [alert, setAlert] = useState({
    msg: '',
    status: ''
  })
  const [validateForm, setValidadeForm] = useState({
    cpf: {
      msg: '',
      status: '',
    },
  })
  const [data, setData] = useState({
    cpf: '',
  })

  const schema = yup.object().shape({
    cpf: yup.string().required('Campo Obrigatório').min(14, 'CPF incompleto!'),
  })

  const handleValues = (e) => {
    const { value, name } = e.target
    validateInputForm(schema, name, { [name]: value }, validateForm, setValidadeForm)
    setData({ ...data, [name]: value })
  }

  async function requestEmail(e) {
    e.preventDefault()
    try {
      const obj = {
        cpf: data.cpf.replace(/[^0-9]/g, '')
      }
      const res = await api.post('/forgot_password', obj)
      let email = res.data
      let part = email.substr(3, (email.indexOf('@') - 3))
      email = email.replace(part, '***********')

      setAlert({ msg: `Encaminhamos um email de redefinição de senha para ${email}`, status: 'success' })

    } catch (error) {
      setAlert({ msg: `Não localizamos usuário com esse CPF`, status: 'error' })
    }

    setTimeout(() => {
      setAlert({
        msg: '',
        status: ''
      })
    }, 10000)
  }

  useEffect(() => {
    validateForms(schema, data, setBtnSubmit)
  }, [data])

  return (
    <form onSubmit={requestEmail}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <span className={`${style.alert_form} ${alert.status}`}>{alert.msg}</span>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TitleForm>Confirme seu CPF para solicitar uma nova senha.</TitleForm>
        </Grid>

        <Grid item xs={12}>
          <InputForm variant='outlined' className={validateForm.cpf.status} fullWidth>
            <InputLabel id='input_cpf'>CPF</InputLabel>
            <OutlinedInput
              htmlFor='input_cpf'
              label='CPF'
              name='cpf'
              value={data.cpf}
              onChange={handleValues}
              inputComponent={MaskCpf}
            />
            <FormHelperText>{validateForm.cpf.msg}</FormHelperText>
          </InputForm>
        </Grid>

        <Grid item xs={12}>
          <CustomButton size='large' disabled={!btnSubmit} type="submit" fullWidth >Solicitar</CustomButton>
        </Grid>

        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-start' alignItems='center'>
            <Box display='flex' className='label_goback' onClick={e => handleGoback('prev')}>
              <ArrowBack />
              <Typography>Voltar</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}



export default FormLogin
