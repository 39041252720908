import React, { useEffect, useState } from 'react'

import { Box, Drawer, Grid, Hidden, IconButton } from '@material-ui/core'
import Footer from '~/Components/Footer'
import CourseCard from '~/Components/CourseCard';
import SubscribeModal from '~/Components/Modals/SubscribeModal';
import NewHeader from '~/Components/NewHeader'
import SkeletonDetailsCouse from '~/Utils/Skeleton/SkeletonDetailsCourse';

import { DateTime } from 'luxon';
import { format, addDays } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR';

import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgUser from '~/Assets/img/vazio.png'
import GifButton from '~/Assets/img/gif-button.gif'
import api from '~/Services/api'
import { store } from '~/store';

import useStyles from './style'
import FormLogin from '~/Components/Formularios/FormLogin';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Close } from '@material-ui/icons';
import { CustomButton } from '~/Components/Custom';
import UnsubscribeModal from '~/Components/Modals/UnsubscribeModal';

DateTime.local().setZone('America/Sao_Paulo');

const CourseV2 = (props) => {
  const parameterUrl = props.match.params.id
  const idCourse = parameterUrl.slice(parameterUrl.lastIndexOf('-')).slice(1)
  const profile = store.getState().user.profile

  const [course, setCourse] = useState({})
  const [nextCourses, setNextCourses] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [openModalUnsubscribe, setOpenModalUsubscribe] = useState(false)
  const [configSubscribe, setConfigSubscribe] = useState({
    label: "Inscrições Abertas",
    labelColor: '#fff',
    bgButton: '#d81717',
    buttonDisabled: false,
    subscribe: 'subscribe',
    isSubcribe: true
  })
  const [subscribeCourse, setSubscribeCourse] = useState('')
  const [subscribeID, setSubscribeID] = useState('')
  const [handleLogin, setHandleLogin] = useState(false)
  const [isPratical, setIsPratical] = useState(false)
  const [statusPratical, setStatusPratical] = useState('')
  const [btnSubmit, setBtnSubmit] = useState(true)

  const nameCourseFormated = course.name && course.name.slice(0, 1).concat(course.name.substr(1).toLowerCase())
  const dayWeekCoruse = course.date_of_course && format(addDays(new Date(course.date_of_course), 1), 'EEEE', {
    locale: ptBR
  })
  const dayWeekCoruseFormated = dayWeekCoruse && dayWeekCoruse.charAt(0).toUpperCase().concat(dayWeekCoruse.slice(1))
  const dayCourseFormated = course.date_of_course && format(addDays(new Date(course.date_of_course), 1), 'dd/MM/yyyy', {
    locale: ptBR
  })
  const startHourCourseFormated = course?.hour_start?.substr(0, 5)
  const EndtHourCourseFormated = course?.hour_end?.substr(0, 5)
  const categoryCourse = course?.categories_names?.split(',')
  const isCoursePraticalOrNot = course.is_pratical === 0 ? 'Teórico' : 'Prático'
  const isCourseFreeOrNot = course?.donation ? 'Grátis' : `${course.price}`
  const donatioCourse = course.donation?.slice(0, 1).toUpperCase().concat(course.donation.substr(1).toLowerCase())
  const totalNumberSeatsAvaliable = parseInt(course?.totalQuantity)
  const quantityCourse = course.quantity && parseInt(course.quantity)
  const resumTeacher = course?.resum_teacher?.split('.')
  const messageWhatsapp = `Olá **${course.fullname}** Quero me inscrever no curso ${course.name} do dia ${dayCourseFormated} na loja santo antonio, como eu faço para efetuar o pagamento?`

  const courseWarningMessage = isCourseFreeOrNot === 'Grátis'
    ? <span>Você não precisa pagar nada. Apenas pedimos para levar no dia uma contribuição simbólica de {donatioCourse}, para ajudar instituições carentes.</span>
    : <span>Basta comparecer ao curso e efetuar o pagamento em dinheiro direto para o(a) {course.teacherName}(a).</span>

  const dayCourse = DateTime.fromISO(course.date_of_course)
  const today = DateTime.local()
  const diff = dayCourse.diff(today, ['months', 'days'])

  const maskTelephone = (telephone) => {

    telephone = telephone.replace(/\D/g, ""); //Remove tudo o que não é dígito
    telephone = telephone.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    telephone = telephone.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    return telephone;
  }

  const sliderExtraLarge = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          infinite: true,
          swipe: true,
          swipeToSlide: true,
          slidesToShow: 3,

        }
      },
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          swipe: true,
          swipeToSlide: true,
          slidesToShow: 2,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  }

  const getCourse = async () => {
    const res = await api.get(`course/${idCourse}`)
    setCourse(res.data[0])
    if (!res.data.error) {
      setCourse(res.data[0])
    }
  }

  const getNextCourses = async () => {
    const res = await api.get(`view/courses`)
    setNextCourses(res.data)
  }

  const handleOpenModal = () => {
    if (profile !== null) {
      setOpenModal(!openModal)
    } else {
      setHandleLogin(!handleLogin)
    }
  }

  const handleCloseLogin = () => {
    setHandleLogin(!handleLogin)
  }

  const getSubscribeCourse = async () => {
    if (profile) {
      const sub = await api.get(`course_subscribe/${idCourse}/student/${profile.id}`)
      setSubscribeCourse(sub.data.message)
      setSubscribeID(sub.data.subscribeID)
      setStatusPratical(sub.data.status_pratical)

      if (sub.data.message === "registered") {
        setConfigSubscribe({
          label: 'Você está inscrito',
          bgButton: '#419a58',
          labelColor: '#fff',
          buttonDisabled: true
        })
      }

    }
  }

  const handleSubmitCourse = async () => {

    const res = await api.post('subscribe', {
      student_id: profile.id,
      course_id: idCourse,
      status_pratical: isPratical
    })

    if (isCoursePraticalOrNot === 'Prático') {
      setConfigSubscribe({
        label: 'Aguardando confirmação',
        bgButton: '#fff',
        border_button: '1px solid #D01717',
        labelColor: '#D01717',
        buttonDisabled: true
      })
      return null
    }

    setConfigSubscribe({
      label: 'Você está inscrito',
      bgButton: '#419a58',
      labelColor: '#fff',
      buttonDisabled: true
    })
  }

  const handleOpenUnsubcribeModal = () => {
    setOpenModalUsubscribe(!openModalUnsubscribe)
    setBtnSubmit(true)
  }

  useEffect(() => {
    if (profile !== null) {
      getSubscribeCourse()
    }
  }, [profile])

  useEffect(() => {
    if (subscribeCourse === 'registered' && statusPratical !== 1 && course.is_pratical === 1) {
      setConfigSubscribe({
        label: 'Aguardando confirmação',
        bgButton: '#fff',
        border_button: '1px solid #D01717',
        labelColor: '#D01717',
        buttonDisabled: true
      })
    }

    if (course.status === 'canceled') {
      setConfigSubscribe({
        label: 'Cancelado',
        bgButton: '#d4d2d2',
        labelColor: '#fff',
        buttonDisabled: true,
      })
    }

    if (totalNumberSeatsAvaliable < quantityCourse) {
      setConfigSubscribe({
        label: 'Esgotado',
        bgButton: '#ffabab',
        labelColor: '#fff',
        buttonDisabled: true,
      })
    }

    if ((totalNumberSeatsAvaliable - quantityCourse) <= 10 && course.status !== 'canceled' && subscribeCourse !== 'registered') {
      setConfigSubscribe({
        ...configSubscribe,
        label: 'Últimas vagas',
        bgButton: '#d81717',
        labelColor: '#ffe13a',
        dataset: 'ultimas vagas',
        gifButton: GifButton
      })
    }

    if (diff.days > course.days_for_publication) {
      setConfigSubscribe({
        label: 'Inscrições em breve',
        bgButton: '#ffd19f',
        labelColor: '#fff',
        buttonDisabled: true,
      })
    }

  }, [course])

  useEffect(() => {
    if (subscribeCourse === 'not subscribe') {
      setConfigSubscribe({
        label: "Inscrições Abertas",
        labelColor: '#fff',
        bgButton: '#d81717',
      })
    }
  }, [subscribeCourse])

  useEffect(() => {
    getCourse()
  }, [])

  useEffect(() => {
    getNextCourses()
  }, [course])

  const style = useStyles(configSubscribe)
  return (
    <Grid className={style.container}>
      <NewHeader />
      <Grid className={style.content}>
        {Object.keys(course).length > 0 ?
          <>
            <Box className={style.BackgroundHeader}>

              <Grid className={style.headerCourse}>
                <Box display="flex" >

                  <Box className={style.photo}>
                    <img
                      src={course?.path_image || ImgUser}
                      alt="imagem do professor"
                    />
                  </Box>

                  <Box className={style.course}>
                    <h1>{nameCourseFormated}</h1>
                    <p>{course?.fullname}</p>
                    <p>categoria: {categoryCourse.map((categoryname) => (
                      <b key={categoryname}>{categoryname}</b>
                    ))}</p>
                    <Box className={style.hour}>
                      <span>{dayCourseFormated}</span>
                      <span>{dayWeekCoruseFormated}</span>
                      <span>{startHourCourseFormated}h - {EndtHourCourseFormated}h</span>
                    </Box>

                  </Box>
                </Box>

                <Box className={style.contentSubscribe}>
                  <p>
                    <span>Curso: </span><span>{isCoursePraticalOrNot}</span> - <span>{isCourseFreeOrNot}</span>
                  </p>
                  <Box className={style.subscribe}>

                    {configSubscribe?.gifButton ?
                      <Box className={style.gifButton} id="gif_label_subscribe" data-lastvacancies={configSubscribe?.dataset}>
                        <img onClick={handleOpenModal} src={configSubscribe?.gifButton} alt="gif" />
                      </Box> :
                      <CustomButton
                        className={`${style.button}`}
                        bg={configSubscribe?.bgButton}
                        bg_disabled_btn={configSubscribe?.bgButton}
                        border_button={configSubscribe?.border_button}
                        color_bt={configSubscribe?.labelColor}
                        id="gif_label_subscribe"
                        data-subscribe={configSubscribe?.label === 'Inscrições Abertas' ? configSubscribe?.subscribe : ''}
                        disabled={configSubscribe?.buttonDisabled}
                        color_disabled_btn={configSubscribe?.labelColor}
                        onClick={handleOpenModal}
                      >
                        {configSubscribe?.label}
                        {configSubscribe?.label === 'Aguardando confirmação' &&
                          <span>Telefone do professor:
                            <a href={`https://api.whatsapp.com/send?phone=+55${maskTelephone(course?.telephoneOne)}&text=${encodeURIComponent(messageWhatsapp)}`}>
                              <b>{course?.telephoneOne}</b></a>
                          </span>
                        }
                      </CustomButton>
                    }
                    {subscribeCourse === 'registered' &&
                      <Box className={`${style.unsubscribeButton}`}>
                        <DeleteOutlineIcon onClick={handleOpenUnsubcribeModal} />
                      </Box>
                    }
                  </Box>
                </Box>
              </Grid>
            </Box>

            <Box className={style.contentDetails}>
              <Box className={style.aboutTeacher}>
                <h2>Sobre o Professor</h2>
                <Box className={style.resumTeacher}>
                  {resumTeacher !== undefined &&
                    resumTeacher.map((resum) => (
                      <p key={resum}>{resum}</p>
                    ))}
                </Box>
              </Box>
              <Box className={style.lineDivisor}></Box>
              <Box className={style.modality}>
                <h2>Sobre o curso</h2>
                <p>{course?.description}</p>
                {/* <h3>Modalidade</h3>
                <h4>{isCoursePraticalOrNot} - {isCourseFreeOrNot}</h4>
                {courseWarningMessage} */}
              </Box>
              {/* <CustomButton
                 bg={configSubscribe.bgButton}
                 bg_disabled_btn={configSubscribe.bgButton}
                 border_button={configSubscribe.border_button}
                 color_disabled_btn={configSubscribe.labelColor}
                 id="gif_label_subscribe"
                 data-subscribe={configSubscribe.label === 'Inscrições Abertas' ? configSubscribe.subscribe : ''}
                 data-lastvacancies={configSubscribe.dataset}
                 disabled={configSubscribe.buttonDisabled}
                 onClick={handleOpenModal}
              >
                {configSubscribe.label}
              </CustomButton> */}
            </Box>
            {nextCourses.length !== 0 ?
              <>
                {/* <h2 className={style.similarCourses}>Cursos similiares</h2> */}

                {/*Rederiza em  desktops com com resolução acima de 1600px */}
                {/* <Hidden only={['xs', 'sm']}>
                  <Box className={style.boxBigSquare}>
                    <Slider {...sliderExtraLarge} >
                      {nextCourses && nextCourses.map(course => (
                        <CourseCard key={course.id} course={course} />
                      ))}
                    </Slider>
                  </Box>
                </Hidden> */}

                {/*Rederiza nos celulares*/}
                {/* <Hidden only={['md', 'lg', 'xl']}>
                  <Box className={`${style.boxCarouselMobile} area_carousel`}>
                    {nextCourses && nextCourses.map(course => (
                      <Box key={course.id} className={style.carouselMobile}>
                        <CourseCard course={course} />
                      </Box>
                    ))}
                  </Box>
                </Hidden> */}
              </> : ""}
          </>
          :
          <SkeletonDetailsCouse />
        }

      </Grid>
      <Footer />

      {handleLogin !== false ?
        <Drawer className={style.login} anchor={window.innerWidth < 960 ? 'bottom' : 'right'} open={handleLogin}>
          <Box role='presentation' className='drawer_content'>
            <Box display='flex' justifyContent='flex-end' alignItems='center' style={{ height: 80 }}>
              <IconButton onClick={handleCloseLogin} className='btn_close_drawer'>
                <Close />
              </IconButton>
            </Box>

            <Box className="content_drawer">
              <FormLogin handleDrawer={handleLogin} />
            </Box>

          </Box>
        </Drawer> : ""}

      <SubscribeModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        nameTeacher={course.fullname}
        nameCourse={nameCourseFormated}
        dayCourseFormated={dayCourseFormated}
        CoursePraticalOrNot={isCoursePraticalOrNot}
        courseFreeOrNot={isCourseFreeOrNot}
        donatioCourse={donatioCourse}
        telephoneTeacher={course.telephoneOne}
        handleSubmitCourse={handleSubmitCourse}
        setSubscribeCourse={setSubscribeCourse}
        setIsPratical={setIsPratical}
      />

      <UnsubscribeModal
        open={openModalUnsubscribe}
        handleDialog={handleOpenUnsubcribeModal}
        subscribe_id={subscribeID}
        student_id={profile?.id}
        course_id={course?.id}
        setUnsubscribe={setSubscribeCourse}
        btnSubmit={btnSubmit}
        setBtnSubmit={setBtnSubmit}
      />
    </Grid >
  )
}

export default CourseV2
