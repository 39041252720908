import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { signReset } from '~/store/modules/auth/actions'

const TokenExpired = ({ handleDrawer }) => {
  const dispatch = useDispatch()

  const [ alertToken, setAlertToken] = useState(true)

  const handleAlertToken = () => {
    setAlertToken(!alertToken)
    handleDrawer("login")
    dispatch(signReset())
  }

  return (
    <>
      <Dialog
        open={alertToken}
        onClose={handleAlertToken}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Sessão Expirada"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sua sessão expirou. Entre novamente!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertToken} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

TokenExpired.propTypes = {
  handleDrawer: PropTypes.func.isRequired
}

export default TokenExpired
