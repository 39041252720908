import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import GenerateCertificate from '~/Components/GenerateCertificate'

import Profile from '~/Components/Profile'
import api from '~/Services/api'
import { store } from '~/store'

import useStyles from './style'

const CompletedCourses = () => {

  const { profile } = store.getState().user
  const [courses, setCourses] = useState([])
  const [notFound, setNotFound] = useState(false)

  const getCompletedCourses = async () => {
    const res = await api.get(`/teacher_courses_realized/${profile.id}`)
    if (res.data.length > 0) {
      setCourses(res.data)
    } else {
      setTimeout(() => {
        setNotFound(true)
      }, 1000)
    }
  }

  useEffect(() => {
    getCompletedCourses()
  }, [])

  const style = useStyles()
  return (
    <Profile title="Cursos realizados">
      <Box className={style.container}>
        {
          courses.length > 0 ?
            <Box className={style.content}>
              {courses.map(course => (
                <GenerateCertificate key={course.id} course={course} />
              ))}
            </Box>
            :
            notFound ?
              <p className={style.message}>Nenhum Curso realizado</p>
              :
              <>
                {[1, 2, 3, 4, 5, 6].map(skeleton => (
                  <Box key={skeleton} className={style.skeleton}>
                    <p> <Skeleton variant="rect" /></p>
                    <p> <Skeleton variant="rect" /></p>
                    <p> <Skeleton variant="rect" /></p>
                    <button> <Skeleton variant="rect" /></button>
                  </Box>
                ))}
              </>
        }
      </Box>
    </Profile>
  )
}

export default CompletedCourses
