import React, { useState, useEffect } from 'react';
import useStyles from './style';

import api from '~/Services/api';

import DashAdmin from '~/Components/DashAdmin';
import FormUser from '~/Components/Forms/FormUser';

export default function EditStudent({ match }) {
  const [student, setStudent] = useState([]);

  async function requestOneStudent() {
    const res = await api.get(`users/${match.params.id}`);
    setStudent(res.data);
  }

  useEffect(() => {
    requestOneStudent();
  }, []);

  return (
    <>
      <DashAdmin
        content={
          <FormUser
            handleDrawer={() => {}}
            values={student}
            form={'student'}
            user={'admin'}
          />
        }
        title={'Editar Aluno'}
        width={'1200px'}
      />
    </>
  );
}
