import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({

  root: {
    borderRadius: 8,
    margin: '0 16px',
  },

  textCapitalize: {
    textTransform: 'capitalize'
  },

  headerTable: {

    '&.MuiTableRow-head': {
      background: '#d01717',

      '& .MuiTableCell-head': {
        color: '#fff',
        fontSize: 18,
        fontWeight: '400',
      }
    },
  },

  bodyTable: {

    '& .MuiTableRow-root': {

      '& .MuiTableCell-body': {
        color: '#271E1E',
        fontSize: 14,

        [theme.breakpoints.down('sm')]: {
          whiteSpace: 'nowrap'
        }
      },

      '& .MuiSvgIcon-root': {
        cursor: 'pointer',
        fontSize: '2rem',

        '&:hover': {
          fill: '#D01717',
        },

        '&.not-active': {
          fill: '#000',
        },

        '&.active': {
          fill: '#419a58',
        }
      },

      '&:nth-child(even)': {
        background: '#f5f5f5',
      }
    }
  },

  message: {
    width: '100%',
    textAlign: 'center',
    fontSize: '3vw',

    [theme.breakpoints.down('sm')]: {
      fontSize: '5vw',
    }
  },

  modalClose: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 70,

    '& .MuiSvgIcon-root': {
      width: '1.5em',
      height: '1.5em',
      fontSize: '2.5em',
      borderRadius: '50%',
      padding: 10,
      margin: 16,

      '&:hover': {
        background: '#c4c4c49e',
      }
    }
  },

  containerModal: {
    width: '100%',

    '& .MuiDialog-paperWidthSm': {
      maxWidth: 950,
    }
  }

}))

export default useStyles
