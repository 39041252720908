import React from 'react'
import Header from '~/Components/Header';
import NewPassword from '~/Components/Forms/NewPassword';
import globalStyles from '~/Assets/css/globalStyles';
import { Container } from '@material-ui/core';

const PasswordTeacher = ({ match }) => {
  const pattern = globalStyles()

  return (
    <>
      {/* <Header /> */}
      <div className={pattern.content}>
        <Container maxWidth={'xs'} style={{ paddingTop: '100px'}}>
          <NewPassword token={match.params.token} />
        </Container>
      </div>
    </>
  )
}

export default PasswordTeacher;
