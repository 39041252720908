import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import EditProfile from '~/Components/EditProfile'
import Profile from '~/Components/Profile'
import api from '~/Services/api'

const ProfileEdit = (props) => {

  const id = props.match.params.id

  const [teacher, setTeacher] = useState({
    id: '',
    fullname: '',
    cpf: '',
    email: '',
    telephoneOne: '',
    telephoneTwo: '',
    oldPassword: '',
    confirmPassword: '',
    password: '',
  })

  const maskCpf = (cpf) =>
    cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  const maskTelephone = (telephone) => {
    telephone = telephone.replace(/\D/g, "");
    telephone = telephone.replace(/^(\d{2})(\d)/g, "($1) $2");
    telephone = telephone.replace(/(\d)(\d{4})$/, "$1-$2");
    return telephone;
  }

  const getTeacher = async () => {
    try {
      const res = await api.get(`users/${id}`)
      setTeacher({
        ...teacher,
        id: res.data.id,
        fullname: res.data.fullname,
        cpf: maskCpf(res.data.cpf),
        email: res.data.email,
        telephoneOne: res.data.telephoneOne ? maskTelephone(res.data.telephoneOne) : '',
        telephoneTwo: res.data.telephoneTwo ? maskTelephone(res.data.telephoneTwo) : '',
        typeUser: res.data.type_user,
        resum_teacher: res.data.resum_teacher
      })
    } catch (error) {
      toast.error(error)
    }
  }

  useEffect(() => {
    getTeacher()
  }, [])

  return (
    <Profile title="Editar dados">
      <EditProfile data={teacher} />
    </Profile>
  )
}

export default ProfileEdit
