import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Slider from 'react-slick';

function SlideTeachers({ className, teachers, ...props }) {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    speed: 500,
    swipeToSlide: true,
    useTransform: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          infinite: false,
          swipe: true,
          swipeToSlide: true,
          slidesToShow: 5,

        }
      },
      {
        breakpoint: 1024,
        settings: {
          infinite: false,
          slide: 'div',
          slidesToShow: 3,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  }

  const toCamelCase = (string) => {
    return string.split(' ')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }).join(' ')
  }

  return (
    <>
      <Slider {...settings} className={`${className} slide_teacher`}>
        {teachers.map(t => (
          <Box key={t.id} className='box_mini_slide' component='a' href={`/cursos?teacher=${t.name}`} >
            <Box className='image_mini_slide'>
              <img src={t.path_image} alt="" />
            </Box>
            <Box className='name_mini_slide'>
              <Typography>{toCamelCase(t?.name)}</Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </>
  )
}

export default SlideTeachers;
