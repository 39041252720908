import { makeStyles, createTheme } from "@material-ui/core/styles"

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1014,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  carroselMobile: {
    overflow: 'hidden',

    '& .area_carousel': {

      '&.courses': {
        marginTop: 20,
        marginRight: 10,
      },

      flexWrap: 'nowrap',
      overflowX: 'scroll',
      padding: '10px 0',

      '& > .MuiBox-root': {
        flex: 'none'
      },

      '& .box_mini_slide': {
        marginRight: 20,
      },

    }
  }

}))


export default useStyles
