import React from 'react';
import Slider from 'react-slick';

function ImageSlider({ images = [], objectFit = 'cover' }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div>
      {images.length > 0 ? (
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image.url || image}
                alt={image?.alt ? image?.alt : 'imagem da receita'}
                title={image?.title ? image?.title : 'imagem da receita'}
                style={{
                  width: '100%',
                  height: '250px',
                  objectFit: `${objectFit}`,
                  borderRadius: '10px',
                }}
              />
            </div>
          ))}
        </Slider>
      ) : null}
    </div>
  );
}

export default ImageSlider;
