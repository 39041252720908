import { all, takeLatest, call, put } from 'redux-saga/effects';
import api from '~/Services/api';
import {
  signInSuccess,
  signFailure,
  signUpFailure,
  signUpSuccess,
  signInRequest,
} from './actions';
import history from '~/Services/history';

export function* signIn({ payload }) {
  try {
    const { cpf, password } = payload;
    const res = yield call(api.post, 'sessions', {
      cpf,
      password,
    });
    console.log(res.data);
    const { token, user } = res.data;
    if (!user) {
      return;
    }
    yield put(signInSuccess(token, user));

    // if (history.location.state !== undefined) {
    //   return history.push(history.location.state.from.pathname)
    // }

    // if (user.type_user === 'student' || user.type_user === 'teacher') {
    //   window.location.href = window.location.href
    // }

    // if (user.type_user === 'admin') {
    //   history.push('/admin')
    // }
  } catch (err) {
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const {
      fullname,
      cpf,
      email,
      telephoneOne,
      telephoneTwo,
      password,
    } = payload;
    const res = yield call(api.post, 'users', {
      fullname,
      cpf,
      email,
      telephoneOne,
      telephoneTwo,
      password,
    });
    const { id } = res.data;

    if (id) {
      yield put(signUpSuccess());
    }

    yield put(signInRequest(cpf, password));
  } catch (error) {
    yield put(signUpFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

// export function signInExpired(){
//   history.push('/')
// }

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
  // takeLatest('@auth/SIGN_IN_EXPIRED', signInExpired),
]);
