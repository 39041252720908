import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import useStyles from './style'

import MyProfile from './MyProfile';
import MyCourses from '~/Components/MyCourses';
import Profile from '~/Components/Profile';

import { store } from '~/store';
import api from '~/Services/api';
import { toast } from 'react-toastify';

const AreaStudent = (props) => {
  const { profile } = store.getState().user

  const [student, setStudent] = useState({
    id: '',
    fullname: '',
    cpf: '',
    email: '',
    telephoneOne: '',
    telephoneTwo: '',
    oldPassword: '',
    confirmPassword: '',
    password: '',
  })
  const [courses, setCourses] = useState({
    coursesProgress: 0,
    completedCourses: 0
  })

  const linkAreaStudent = `/area-do-aluno/meu-perfil/${profile.id}`
  const linkAreaTeacher = `/area-do-professor/meu-perfil/${profile.id}`

  const linksCompletedCourseStudent = '/area-do-aluno/meus-cursos/cursos-completos'
  const linksCompletedCourseTeacher = '/area-do-professor/meus-cursos/cursos-completos'

  const linksOngonigCourseStudent = '/area-do-aluno/meus-cursos/cursos-andamento'
  const linksOngonigCourseTeacher = '/area-do-professor/meus-cursos/cursos-andamento'

  const linksArea = {
    editProfile: profile.type_user === 'student' ? linkAreaStudent : linkAreaTeacher,
    acessCompletedCourses: profile.type_user === 'student' ? linksCompletedCourseStudent : linksCompletedCourseTeacher,
    acessOngoingCourses: profile.type_user === 'student' ? linksOngonigCourseStudent : linksOngonigCourseTeacher
  }

  const maskCpf = (cpf) =>
    cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  const maskTelephone = (telephone) => {
    telephone = telephone.replace(/\D/g, "");
    telephone = telephone.replace(/^(\d{2})(\d)/g, "($1) $2");
    telephone = telephone.replace(/(\d)(\d{4})$/, "$1-$2");
    return telephone;
  }

  const getStudent = async () => {
    try {
      const res = await api.get(`users/${profile.id}`)
      setStudent({
        ...student,
        id: res.data.id,
        fullname: res.data.fullname,
        cpf: maskCpf(res.data.cpf),
        email: res.data.email,
        telephoneOne: res.data.telephoneOne ? maskTelephone(res.data.telephoneOne) : '',
        telephoneTwo: res.data.telephoneTwo ? maskTelephone(res.data.telephoneTwo) : '',
      })
    } catch (error) {
      toast.error(error)
    }
  }

  const getCourses = async () => {
    const ongoingCourse = await api.get(`student/my_subscribes/${profile.id}`)
    const completesCourse = await api.get(`student/my_subscribes/${profile.id}?realized=true`)

    setCourses({
      ...courses,
      coursesProgress: ongoingCourse.data.course.length,
      completedCourses: completesCourse.data.length,
    })
  }

  useEffect(() => {
    getCourses()
    getStudent()
  }, [])

  const style = useStyles()
  return (
    <Profile title="Meus Dados">
      <Box className={style.containerDados}>
        <MyProfile
          data={student}
          linksAreaEditProfile={linksArea.editProfile}
        />
      </Box>

      <Box className={style.containerCourses}>
        <MyCourses
          title="Cursos"
          courses={courses}
          linksAcesseCompletedCourse={linksArea.acessCompletedCourses}
          linksAcesseOngoingCourse={linksArea.acessOngoingCourses}
        />
      </Box>
    </Profile>
  )
}

export default AreaStudent;
