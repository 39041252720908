import { Box, FormHelperText, Grid } from '@material-ui/core';
import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react'
import ReactInputMask from 'react-input-mask';
import useStyles from './style';

const InputMask = ({ name, label, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  const style = useStyles()
  return (
    <Grid item xs={12} className={style.formMask} >
      <label>{label}</label>
      <Box width="100%">
        <ReactInputMask
          ref={inputRef}
          defaultValue={defaultValue}
          {...rest}
        />
        <FormHelperText className={style.error}>{error && error}</FormHelperText>
      </Box>
    </Grid>
  );
}

export default InputMask
