import { makeStyles, createTheme } from '@material-ui/core/styles';

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles(theme => ({
  containerDados: {
    maxWidth: 483,
    width: '100%',
    minHeight: 280,
    height: 'auto',
    border: '1px solid #daa3a3',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 8,
    marginRight: 48,
    position: 'relative',

    '& h1': {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 'bold',
      marginTop: 32,
      marginBottom: 25,
      color: '#d01717',
    },

    [responsive.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginBottom: 32,
      marginRight: 20,
    },

    [responsive.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '0 16px 32px 16px',
    },
  },

  containerCourses: {
    maxWidth: 483,
    width: '100%',
    height: 248,
    border: '1px solid #daa3a3',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 10px 10px 10px',

    '& h1': {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 'bold',
      marginTop: 32,
      marginBottom: 25,
      color: '#54080B',
    },

    [responsive.breakpoints.down('md')]: {
      maxWidth: '100%',
      margin: '0 16px 32px 16px',
    },
  },

  teacherCourses: {
    '& > .titleMyCourse': {
      color: '#fff',
      background: '#d81717',
      padding: '15px 0',
      fontSize: 20,
      textAlign: 'center',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      overflow: 'hidden',
    },
    '& > .contentMyCourse': {
      background: '#fff',
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      '& .course': {
        textAlign: 'center',
        '& .msgNotCourse': {
          color: '#d81717',
          display: 'block',
          padding: '35px 0',
          fontSize: 23,
          fontWeight: 'bold',
        },
      },
      '& table tr th': {
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
  },
  info: {
    position: 'relative',
    top: '2rem',
    left: '6rem',
    height: 0,
  },
  infoTeacher: {
    position: 'relative',
    top: '2rem',
    left: '5.5rem',
    height: 0,
  },
  showInfo: {
    color: '#54080B',
    fontSize: '18px',
    marginTop: '32px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '25px',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  infoText: {
    display: 'block',
    position: 'absolute',
    top: '-7.5rem',
    left: '-15rem',
    width: '400px',
    background: 'white',
    border: '1px solid #daa3a3',
    boxShadow: '0 0.125rem 0.5rem #daa3a3, 0 0.0625rem 0.125rem #daa3a3',
    borderRadius: '8px',
    padding: '0.5rem',
    maxWidth: '20rem',
    whiteSpace: 'normal',

    '&::before': {
      left: '14.7em',
      width: 0,
      border: '0.75rem solid transparent',
      bottom: '-0.75rem',
      filter: 'drop-shadow(0 -0.0625rem 0.0625rem #daa3a3)',
      height: 0,
      content: "''",
      position: 'absolute',
      borderTop: 'none',
      borderBottomColor: '#fff',
      rotate: '180deg',
    },
  },

  boxStatistic: {
    padding: 15,
    background: '#fff',
    borderRadius: 5,
    minWidth: 175,
    '& .statisticTitle': {
      fontSize: 18,
    },
    '& .statisticValue': {
      color: '#d81717',
      fontSize: 45,
    },
  },
}));

export default useStyles;
