import { createTheme, makeStyles } from "@material-ui/core/styles"

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
  },

  content: {
    maxWidth: 1240,
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    margin: '48px 0',
    fontSize: 32,
    fontWeight: '700',
    color: '#d01717',
  },

  containerFilter: {
    width: '100%',
    height: 182,
    height: 88,
    borderBottom: '1px solid #D4D2D2',
    padding: 0,
    position: 'relative',
    marginBottom: 48,
    zIndex: 999,

    [responsive.breakpoints.down('sm')]: {
      marginBottom: 30,
      borderBottom: 'transparent',
      zIndex: 0,
      height: 150,
    }
  },

  boxFilter: {
    maxWidth: 230,
    width: '100%',
    marginBottom: 24,

    '& p': {
      fontSize: 14,
      fontWeight: '700',
      color: '#54080B',
      margin: '24px 0 16px 0',
    },


    [responsive.breakpoints.down('sm')]: {
      maxWidth: '100%',
      borderBottom: '1px solid #D4D2D2',
    }
  },

  filter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 88,
    borderBottom: '1px solid #D4D2D2',
    padding: 0,
    margin: '0 16px',

    '& .MuiExpansionPanelSummary-content': {
      justifyContent: 'space-between'
    },

    '& .MuiExpansionPanelSummary-root': {
      padding: 0
    },

    [responsive.breakpoints.down('sm')]: {
      flexDirection: 'column',
      border: 0,
    }
  },

  expanded: {

    '& :nth-child(1)': {
      fontSize: 18,
      fontWeight: '700',
      marginRight: '16.98px',
      color: '#8C0E0E',
      cursor: 'pointer',

    },

    '& :nth-child(3)': {
      display: 'none',
      fontSize: 25,
      border: 'none',
      color: '#8C0E0E',
      fontWeight: 'bold',
      background: 'transparent',
      padding: '5px 15px',
      cursor: 'pointer',

      '&:hover': {
        background: '#ee252614',
        borderRadius: '50%',
      },

      [responsive.breakpoints.down('sm')]: {
        display: 'block',
      }
    },

    [responsive.breakpoints.down('sm')]: {
      marginBottom: 32,
    }
  },

  expandedDesk: {
    display: 'block',

    '& :nth-child(2)': {
      fontSize: 25,
      border: 'none',
      color: '#8C0E0E',
      fontWeight: 'bold',
      background: 'transparent',
      padding: '5px 15px',
      cursor: 'pointer',

      '&:hover': {
        background: '#ee252614',
        borderRadius: '50%',
      },
    },

    [responsive.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  BoxExpandedMobile: {
    display: 'none',

    '& > button': {
      fontSize: 25,
      border: 'none',
      color: '#8C0E0E',
      fontWeight: 'bold',
      background: 'transparent',
      padding: '5px 15px',
      cursor: 'pointer',

      '&:hover': {
        background: '#ee252614',
        borderRadius: '50%',
      },
    },

    [responsive.breakpoints.down('sm')]: {
      display: 'block',

    }
  },

  result: {
    maxWidth: 264,
    width: '100%',
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& :nth-child(1)': {
      fontSize: 14,
      fontWeight: '400',
      color: '#5D5656',

    },

    [responsive.breakpoints.down('sm')]: {
      maxWidth: '100%',
    }
  },

  select: {
    maxWidth: 160,
    width: '100%',
    height: 'auto',
    border: '1px solid #D4D2D2',
    borderRadius: 8,

    '& .MuiFilledInput-root': {
      background: 'transparent'
    },

    '& .MuiFilledInput-underline:before': {
      border: 'none'
    },

    '& .MuiFilledInput-underline:after': {
      border: 'none',
    },


  },

  contentFilter: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    minHeight: 182,
    borderBottom: '1px solid #D4D2D2',
    borderTop: '1px solid #D4D2D2',
    width: '100%',
    justifyContent: 'space-between',
    padding: '0 16px',
    background: '#FFF',
    zIndex: 2,

    [responsive.breakpoints.down('sm')]: {
      // position: 'fixed',
      // top: 0,
      // left: 0,
      // flexDirection: 'column',
      // background: '#fff',
      // maxWidth: 365,
      // width: '100%',
      // zIndex: 999,
      // height: '100%',
      // overflowY: 'scroll',
      // boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.12)',
      display: 'none',
    },
  },

  containerFilterMobile: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 999,
    background: '#fff',
    width: 'calc(100% - 25px)',
    boxShadow: '0 0 16px rgba(0,0,0,0.12)',
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    padding: 16,
  },

  contentFilterMobile: {
    height: '100%',
    overflowX: 'hidden',
    paddingRight: 8,
  },

  slider: {
    width: '90%',
    paddingLeft: 10,

    [responsive.breakpoints.down('sm')]: {
      paddingLeft: 0,
      maxWidth: 190,
      width: '100%',
      marginBottom: 16,
    },

    '& p': {
      fontSize: 14,
      fontWeight: '700',
      color: '#54080B',
      margin: '24px 0 16px 0',

      [responsive.breakpoints.down('sm')]: {
        margin: '0 0 16px 0',
      },
    },

    '& .MuiSlider-root': {
      color: '#8C0E0E',
      marginLeft: 6,
    },

    '& .MuiSlider-thumb': {
      width: 14,
      height: 14,
      background: '#fff',
      border: '1px solid'
    },

    '& .MuiSlider-track': {
      height: 6,
    },

    '& .MuiSlider-rail': {
      height: 6,
    }
  },

  sliderValues: {
    display: 'flex',
    justifyContent: 'space-between',

    '& span': {
      fontSize: 12,
      fontWeight: '400',
      color: '#D4D2D2',
      marginTop: '-10px',
    }
  },

  button: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 194,
    width: '100%',
    alignItems: 'flex-end',

    [responsive.breakpoints.down('sm')]: {
      '& > :nth-child(2)': {
        display: 'none',
      }
    }

  },

  buttonType: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 51,

    '& button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 90,
      height: 33,
      textAlign: 'center',
      borderRadius: 8,
      outline: 'none',
      border: 'none',
      fontSize: 14,
      fontWeight: '400',
      letterSpacing: 2,
      background: '#fff',
      border: '1px solid #d81717',
      color: '#d81717',
      cursor: 'pointer',

      '&.active': {
        background: '#d81717',
        color: '#fff',

        '&:hover': {
          background: '#ee2526',
          border: '1px solid #d81717',
          color: '#fff',
        },
      },

      '&:hover': {
        background: '#d81717',
        color: '#fff',
      },

      [responsive.breakpoints.down('sm')]: {
        marginRight: 16,
      }
    },

    [responsive.breakpoints.down('sm')]: {
      marginBottom: 16,
      width: '50%',
      justifyContent: 'flex-start'
    }
  },

  buttonClear: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    '& :nth-child(1)': {
      background: 'none',
      color: '#d81717',
      border: 'none',
      outline: 'none',
      fontSize: 14,
      fontWeight: '400',
      paddingBottom: 5,
      position: 'relative',
      cursor: 'pointer',

      "&:before": {
        content: '""',
        width: '100%',
        borderBottom: '3px solid #D81717',
        position: 'absolute',
        bottom: 0,
        left: 0,
        transition: 'all .8s cubic-bezier(.555, .205, .295, .555)'
      },

      '&:hover:before': {
        animation: `$animationBottom .8s`,
      },

      [responsive.breakpoints.down('sm')]: {
        letterSpacing: 2,
      }
    },

    '& :nth-child(2)': {
      border: 'none',
      outline: 'none',
      fontSize: 14,
      fontWeight: '400',
      padding: '5px 10px',
      background: '#d81717',
      color: '#fff',
      borderRadius: 8,
      cursor: 'pointer',

      '&:hover': {
        background: '#ee2526',
      },

      [responsive.breakpoints.down('sm')]: {
        display: 'none',
      }
    },

  },

  buttonClearMobile: {
    marginBottom: 24,

    '& button': {
      background: 'none',
      color: '#d81717',
      border: 'none',
      outline: 'none',
      fontSize: 14,
      fontWeight: '400',
      paddingBottom: 5,
      position: 'relative',

      "&:before": {
        content: '""',
        width: '100%',
        borderBottom: '3px solid #D81717',
        position: 'absolute',
        bottom: 0,
        left: 0,
        transition: 'all .8s cubic-bezier(.555, .205, .295, .555)'
      },

      '&:hover:before': {
        animation: `$animationBottom .8s`,
      },
    }
  },

  applyFilterMobile: {
    display: 'flex',
    alignItems: 'center',

    '& button': {
      width: '100%',
      textAlign: 'center',
      background: '#b01010',
      borderRadius: 8,
      outline: 'none',
      border: 'none',
      padding: '12px 10px',
      color: '#fff',
      fontSize: 12,
      fontWeight: '700',
      letterSpacing: 2,

      '&:hover': {
        opacity: 0.7,
      }
    }
  },

  mobile: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    margin: '16px 16px 24px 0',
  },

  closeMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '10%',
    marginBottom: 24,

    '& .MuiIconButton-root': {
      padding: 0,
    },

    '& span': {
      fontSize: 18,
      fontWeight: '700',
      color: '#b01010'
    },

    '& .btn_close_drawer': {

      '& svg': {
        fontSize: '3rem',
        fill: '#8C0E0E',
      }
    },
  },

  aplyFilter: {
    position: 'fixed',
    bottom: 0,
    width: '50%',
    display: 'none',
    background: '#fff',
    padding: '0 0 20px 0',

    [responsive.breakpoints.down('sm')]: {
      display: 'block',

      '& button': {
        width: '100%',
        textAlign: 'center',
        background: '#b01010',
        borderRadius: 8,
        outline: 'none',
        border: 'none',
        padding: '12px 10px',
        color: '#fff',
        fontSize: 12,
        fontWeight: '700',
        letterSpacing: 2,
      }
    }
  },

  containerCourse: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
  },

  messageError: {
    fontSize: '5vw',
    margin: '100px 0',
    whiteSpace: 'nowrap',

    [responsive.breakpoints.down('sm')]: {
      fontSize: '8vw'
    }
  },

  pagination: {
    margin: '32px 0 48px 0',
  },

  expandedMobile: {
    display: 'none',

    [responsive.breakpoints.down('sm')]: {
      display: 'block',
    }
  },

  "@keyframes animationBottom": {
    '0%': {
      width: '100%'
    },
    '100%': {
      width: '0%'
    },
  },

  '$.MuiCollapse-root': {
    transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },

  collapseMobile: {

    '& .MuiCollapse-container': {
      width: 0,
      transitionProperty: 'width',
    },

    '& .MuiCollapse-entered': {
      width: '100%'
    },

    '& .MuiCollapse-hidden': {
      width: 0
    },
    '& .MuiCollapse-root': {
      transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
  },

  animationTop: {
    [responsive.breakpoints.up('md')]: {
      marginTop: 190,
      transition: 'margin-top 300ms ease-in-out',
    }
  },
  animationBottom: {
    [responsive.breakpoints.up('md')]: {
      transition: 'margin-top 300ms ease-in-out',
    }
  }

}))

export default useStyles
