const { makeStyles, createTheme } = require("@material-ui/core/styles");

const shadow = '0px 0px 6px 2px #cccccc85'
const shadowHover = '0px 0px 6px 2px #cccccc'

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({
  footer: {
    minHeight: 557,
    background: '#d4d2d2',

    [responsive.breakpoints.down('sm')]: {
      height: 'max-content',
      paddingBottom: 120,
    },

    '& .area_footer': {
      paddingTop: 135,

      '& > .MuiGrid-root': {
        [responsive.breakpoints.down('sm')]: {
          '&:nth-child(2)': {
            margin: '45px 0'
          },
        },
      }
    },

    '& .MuiContainer-root': {
      padding: 0
    },

    '& .box': {
      '& .box_virtual_store, .box_social': {
        width: 'max-content',

        [responsive.breakpoints.down('sm')]: {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // padding: '0 20px',

          '& *': {
            textAlign: 'center',
          },

          '& br': {
            display: 'none'
          },
        },
      },

      [responsive.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },

      [responsive.breakpoints.down('xs')]: {
        width: 320,
      },
    },

    '& .title_footer': {
      color: '#d81717',
      fontSize: 32,
      width: '100%',
      lineHeight: 'normal',
    },

    '& .text': {
      color: '#271e1e',
      fontSize: 22,
      lineHeight: 'normal'
    },

    '& .box_icons_social': {
      paddingTop: 15,

      '& .box_square_social': {
        boxShadow: shadow,
        width: 72,
        height: 72,
        borderRadius: 5,
        background: '#f4f4f4',
        transition: 'linear .2s',
        marginRight: 20,
        marginBottom: 20,

        '&:hover': {
          boxShadow: shadowHover,
          background: '#fff',
        }
      }
    },

    '& .link_virtual_store': {
      borderRadius: 5,
      background: '#f4f4f4',
      transition: 'linear .2s',
      display: 'flex',
      width: 'max-content',
      padding: '20px 29px',
      marginTop: 10,

      '& > *': {
        fontSize: 22,
        color: '#271e1e',
        fontWeight: 'bold',
      },

      '&:hover': {
        boxShadow: shadowHover,
        background: '#fff',
      },

      [responsive.breakpoints.down('xs')]: {
        width: '100%',
        height: 72,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > *': {
          fontSize: '4vw',
        }
      },
    }
  },

  copyright: {
    fontSize: 12,
    color: '#5d5656',
    textAlign: 'center',

    [responsive.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    [responsive.breakpoints.down('xs')]: {
      width: 320
    },

    '& .separador': {
      padding: '0 5px',

      [responsive.breakpoints.down('sm')]: {
        display: 'none',
      }
    }
  }
}))

export default useStyles
