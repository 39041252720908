import { makeStyles, createTheme } from "@material-ui/core/styles"

const shadow = '0px 2px 5px 0px rgb(39 30 30 / .2)'
const shadowHover = '0px 2px 15px 0px rgb(39 30 30 / .4)'

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({
  boxBigSquare: {
    width: 628,
    height: 490,
    boxShadow: shadow,
    borderRadius: 5,
    overflow: 'hidden',
    background: '#fff',
    position: 'relative',
    transition: 'linear .1s',

    '&:hover': {
      boxShadow: shadowHover,
    },

    [responsive.breakpoints.between('md', 'lg')]: {
      width: '49%'
    },

    [responsive.breakpoints.down('sm')]: {
      '&:nth-child(2)': {
        marginTop: 20,
      }
    },
  },

  boxNextCourse: {

    '& .side_left, .side_right': {
      width: '50%',
    },

    '& .side_left': {
      '& .photo_teacher': {
        maxWidth: 284,
        height: 284,
        overflow: 'hidden',

        '& img': {
          maxWidth: 'auto',
          height: '100%',
        },
      },
      '& .schedule': {
        marginTop: 65,
        height: 60,

        '& .MuiBox-root': {
          width: '50%',
        },

        '& .date_of_course, .hour_start': {
          fontWeight: 'bold',
          fontSize: 22,
          display: 'inline-block',
          width: '80%',
          textAlign: 'center',

          [responsive.breakpoints.only('md')]: {
            fontSize: 18,
          }
        },
        '& .hour_end': {
          fontWeight: 'bold',
          fontSize: 18,
          display: 'inline-block',
          width: '60%'
        },

        '& .label_hour_end, .label_hour_start, .day_week_course, .hour_end': {
          [responsive.breakpoints.only('md')]: {
            fontSize: 15,
          }
        },
      },
      '& .price': {
        fontSize: 32,
        paddingTop: 11,
        margin: '0 auto',
        width: '50%',
      }
    },

    '& .side_right': {
      padding: 12,
      '& .name_teacher': {
        fontSize: 22,
        marginTop: 33,
        height: 33,
        width: '80%',
        margin: '0 auto',

        [responsive.breakpoints.only('md')]: {
          fontSize: 18,
        },
      },

      '& .name_course': {
        fontWeight: 'bold',
        fontSize: 22,
        lineHeight: '23px',
        marginTop: 4,
        height: 73,
        width: '80%',
        margin: '0 auto',

        '& span': {
          margin: '0 auto',
        },

        [responsive.breakpoints.down('md')]: {
          fontSize: 18,
        },
      },

      '& .box_categories': {
        marginTop: 14,
        marginBottom: 13,
        justifyContent: 'center',
        height: 33,

        '& .category': {
          background: '#f5f5f5',
          padding: '0 10px',
          borderRadius: 25,
          margin: 5,
          whiteSpace: 'nowrap',
        },
      },

      '& .description_course': {
        fontSize: 24,
        height: 149,
        overflow: 'hidden',
      },

      '& .link_next_course': {
        display: 'block',
        width: '100%',
        maxWidth: 280,
        borderRadius: 5,
        textAlign: 'center',
        padding: '35px 0',

        '& span': {
          width: '100%',
          margin: '0 auto',
          fontSize: 14,
        },
      }
    }
  },

}))

export default useStyles
