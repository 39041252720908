import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import useStyles from './style'

const SkeletonNextCourse = () => {

  const style = useStyles()
  return (
    <Box className={`${style.boxBigSquare} ${style.boxNextCourse}`} display='flex' component='a' href='#'>
      <Box className='side_left'>
        <Box className='photo_teacher' display='flex' justifyContent='center'>
          <Skeleton width="90%" />
        </Box>

        <Box className='schedule' display='flex'>
          <Box align='center'>
            <Typography align='center' className='date_of_course'> <Skeleton width="100%" /></Typography>
            <Typography className='day_week_of_Course'> <Skeleton width="60%" /></Typography>
          </Box>

          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography align='center'>
              <span className='label_hour_start'><Skeleton width={90} /></span>
            </Typography>
            <Typography align='center'>
              <span className='label_hour_end'><Skeleton width={90} /></span>
            </Typography>
          </Box>
        </Box>

        <Typography className='price'> <Skeleton width="100%" /></Typography>
      </Box>
      <Box className='side_right'>
        <Typography className="name_teacher"> <Skeleton width="100%" /></Typography>

        <Typography className="name_course">
          <Skeleton width="90%" />
          <Skeleton width="80%" />
          <Skeleton width="70%" />
        </Typography>

        <Box display='flex' flexWrap='wrap' className="box_categories">
          <Typography className="category"> <Skeleton width={50} /></Typography>
        </Box>

        <Box className='description_course'>
          <Skeleton width="100%" />
          <Skeleton width="100%" />
          <Skeleton width="100%" />
          <Skeleton width="100%" />
        </Box>

        <Box display='flex' justifyContent='center'>
          <Skeleton className='link_next_course'>
            <Skeleton width="50%" />
            <Skeleton width="50%" />
          </Skeleton>
        </Box>
      </Box>
    </Box>
  )
}

export default SkeletonNextCourse;
