import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { DateTime } from 'luxon';

/*** Images ***/
import bannerNewCourses from '~/Assets/img/banner-cursos-outubro.png';
import bannerWhoSells from '~/Assets/img/banner-quem-vende.png';
import firstBannerMay from '~/Assets/img/banner-site-de-curso-1-maio23.jpg';
import secondBannerMay from '~/Assets/img/banner-site-de-curso-2-maio23.jpg';
import banner from '~/Assets/img/foto-banner-teste.jpg';
import professor from '~/Assets/img/professora.jpg';
import categoria from '~/Assets/img/exemplo-categoria.jpg';
import arrowMoreCourses from '~/Assets/img/arrow-more-courses.jpg';
import imageDepoiment from '~/Assets/img/image-depoiment.png';
import cursosLivres from '~/Assets/img/cursos-livres.png';
import custoBeneficio from '~/Assets/img/custo-beneficio.png';
import chefsRenomados from '~/Assets/img/chefs-renomados.png';

import NewHeader from '~/Components/NewHeader';
import { ContainerApp } from '~/Components/Custom';
import { Box, Hidden, Typography } from '@material-ui/core';
import useStyles from './style';

import SlideCategory from '~/Components/Slides/SlideCategory';
import SlideTeachers from '~/Components/Slides/SlideTeachers';
import SliderInfra from '~/Components/Slides/SlideInfra';
import { Link } from 'react-router-dom';
import Footer from '~/Components/Footer';
import CourseCard from '~/Components/CourseCard';
import api from '~/Services/api';
import { store } from '~/store';
import { signInExpired } from '~/store/modules/auth/actions';

import SkeletonNextCourse from '~/Utils/Skeleton/SkeletonNextCourse';
import SkeletonCourse from '~/Utils/Skeleton/SkeletonCourse';
import SkeletonCarouselCategory from '~/Utils/Skeleton/SkeletonCarouselCategory';
import SkeletonCarouselProffesor from '~/Utils/Skeleton/SkeletonCarouselProffesor';
import { Skeleton } from '@material-ui/lab';
import { ptBR } from 'date-fns/locale';
import { addDays, format } from 'date-fns';

// import BannerPrincipal from '~/Assets/img/banner_1.png'
import BannerSecondary from '~/Assets/img/banner_2.png';
// import BannerFebruary from '~/Assets/img/banner-fevereiro.jpg'
import BannerFebruary from '~/Assets/img/banner-volta-aulas.jpg';
import { useDispatch } from 'react-redux';

DateTime.local().setZone('America/Sao_Paulo');

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  speed: 1000,
  autoplaySpeed: 3000,
  autoplay: true,
  infinite: true,
  swipeToSlide: true,
  useTransform: false,
  arrows: false,
  accessibility: false,
};

function Home() {
  const profile = store.getState().user.profile;
  const dispatch = useDispatch();
  const [banner, setBanner] = useState([]);
  const [nextCourse, setNextCourses] = useState({});
  const [courses, setCourses] = useState([]);
  const [courseNotFound, setCourseNotFound] = useState(false);
  const [categoryNotFound, setCategoryNotFound] = useState(false);
  const [teacherNotFound, setTeacherNotFound] = useState(false);
  const [categories, setCategories] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [configNextCourse, setConfigNextCourse] = useState({
    label: 'Inscreva-se',
    bgButton: '#d91718',
    labelColor: '#fff',
  });
  const [showNextCourse, setShowNextCourse] = useState(false);
  const [isSubcribeCourse, setIsSubscribeCourse] = useState('');
  const priceFormated = nextCourse?.donation ? 'Grátis' : `${nextCourse.price}`;
  const dayCourseFormated =
    nextCourse.date_of_course &&
    format(addDays(new Date(nextCourse.date_of_course), 1), 'dd/MM/yyyy', {
      locale: ptBR,
    });

  const dayCourse = DateTime.fromISO(nextCourse?.date_of_course);
  const today = DateTime.local();
  const diff = dayCourse.diff(today, ['months', 'days']);

  const [depoiments, setDepoiments] = useState([]);

  const slideBanners = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function gifLabel() {
    let gif = true;
    const elementLabel = document.querySelector(
      '[data-giflabel="gif_label_card"]'
    );
    setInterval(() => {
      gif = !gif;
      if (elementLabel) {
        elementLabel.innerHTML = gif ? 'Últimas vagas' : 'Inscreva-se';
      }
    }, 2000);
  }

  const toCamelCase = string => {
    return string
      .split(' ')
      .map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  };

  async function getCategories() {
    const res = await api.get('view/category');
    setCategories(res.data);
  }

  async function getCourses() {
    const { data } = await api.get('view/home');

    if (data.length > 0) {
      if (window.innerWidth > 600) {
        setNextCourses(data.shift());
        setShowNextCourse(true);
      }
      if (data.length > 0) {
        setCourses(data);
      }
    } else {
      setCourseNotFound(true);
    }
  }

  async function getTeachers() {
    const res = await api.get('view/teacher');
    if (res.data.length > 0) {
      setTeachers(res.data);
    } else {
      setTeacherNotFound(true);
    }
  }

  const getSubscribeCourse = async () => {
    let sub;
    try {
      if (profile && nextCourse?.id) {
        sub = await api.get(
          `course_subscribe/${nextCourse.id}/student/${profile?.id}`
        );

        if (
          sub.data.message === 'registered' &&
          nextCourse.status_pratical !== 1 &&
          nextCourse.is_pratical === 1
        ) {
          setConfigNextCourse({
            label: 'Aguardando confirmação',
            bgButton: '#ffabab',
            labelColor: '#fff',
            buttonDisabled: true,
          });
          return null;
        }

        if (sub.data.message === 'registered') {
          setIsSubscribeCourse(sub.data.message);
          setConfigNextCourse({
            label: 'Você está inscrito',
            bgButton: '#419a58',
            labelColor: '#fff',
          });
        } else {
          setIsSubscribeCourse(sub.data.message);
        }
      }
      if (nextCourse.status === 'canceled') {
        setConfigNextCourse({
          label: 'Cancelado',
          bgButton: '#fff',
          labelColor: '#B01010',
          borderButton: `1px solid #B01010`,
        });
        return setTimeout(() => {
          setShowNextCourse(true);
        }, 2000);
      }
    } catch (error) {
      dispatch(signInExpired());
    }

    if (parseInt(nextCourse.quantity) === parseInt(nextCourse.totalQuantity)) {
      setConfigNextCourse({
        label: 'Esgotado',
        bgButton: '#fff',
        labelColor: '#B01010',
        borderButton: `1px solid #B01010`,
      });

      return setTimeout(() => {
        setShowNextCourse(true);
      }, 2000);
    }

    if (
      parseInt(nextCourse?.totalQuantity) - parseInt(nextCourse?.quantity) <=
        10 &&
      sub?.data?.message !== 'registered'
    ) {
      setConfigNextCourse({
        label: 'Últimas vagas',
        bgButton: '#d91718',
        labelColor: '#fff',
      });

      return setTimeout(() => {
        setShowNextCourse(true);
        gifLabel();
      }, 2000);
    }

    if (diff.days > nextCourse.days_for_publication) {
      setConfigNextCourse({
        label: 'Inscrições em breve',
        bgButton: '#ffabab',
        labelColor: '#fff',
      });
      return setTimeout(() => {
        setShowNextCourse(true);
      }, 2000);
    }
  };

  const handleSlug = name => {
    name = name.replace(/[' '&\/\\#,+()$~%.'":*?<>{}@]/g, '-').toLowerCase();
    return `${name}-${nextCourse.id}`;
  };

  const getDepoiments = async () => {
    const { data } = await api.get('depoiment?topfive=true');
    setDepoiments(data);
  };

  async function getBanner() {
    try {
      const res = await api.get('banners_active');
      setBanner(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getDepoiments();
    getCourses();
    getCategories();
    getTeachers();
    getBanner();
  }, []);

  useEffect(() => {
    getSubscribeCourse();
  }, [nextCourse]);

  const style = useStyles(configNextCourse);
  return (
    <>
      <NewHeader />
      <ContainerApp className={style.home}>
        <Box
          display="flex"
          flexWrap="wrap"
          className={style.containerBigSquare}
        >
          {/* <Hidden xsDown> */}
          <Box className={style.boxBigSquare}>
            <Slider
              {...slideBanners}
              className={`${style.styleSlides} slide_banner_home`}
            >
              {banner.map(banner => (
                <Box
                  key={banner.id}
                  className={style.boxBanner}
                  component="a"
                  href={banner.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={banner.banner_url}
                    alt={banner.description}
                    href={banner.link}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
          {/* </Hidden> */}

          {/* <Hidden smUp>
            <Box className={style.carouselHomeMobile}>
              <Box display='flex' className={`area_carousel`}>
                {banners.map(b => (
                  <Box key={b.id} className={style.boxBannerMobile} component='a' href={b.link}>
                    <img src={b.img} alt="" />
                    <Typography align='center' className="title_banner" >{b.name}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Hidden> */}

          <Hidden xsDown>
            {showNextCourse ? (
              <Box
                className={`${style.boxBigSquare} ${style.boxNextCourse}`}
                display="flex"
                component="a"
                href={`/curso/${handleSlug(nextCourse.name)}`}
              >
                <Box className="side_left">
                  {nextCourse.path_image && (
                    <Box
                      className="photo_teacher"
                      display="flex"
                      justifyContent="center"
                    >
                      <img src={nextCourse.path_image} alt="" />
                    </Box>
                  )}

                  <Box className="schedule" display="flex">
                    <Box>
                      <Typography align="center" className="date_of_course">
                        {DateTime.fromSQL(nextCourse.date_of_course).toFormat(
                          'dd/MM/yyyy'
                        )}
                      </Typography>
                      <Typography align="center" className="day_week_of_Course">
                        {DateTime.fromSQL(nextCourse.date_of_course)
                          .setLocale('pt')
                          .toFormat('EEEE')}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography align="center">
                        <span className="label_hour_start">Início:</span>
                        <span className="hour_start">
                          {DateTime.fromSQL(nextCourse.hour_start)
                            .setLocale('pt')
                            .toFormat('HH:mm')}
                        </span>
                      </Typography>
                      <Typography align="center">
                        <span className="label_hour_end">Término:</span>
                        <span className="hour_end">
                          {DateTime.fromSQL(nextCourse.hour_end)
                            .setLocale('pt')
                            .toFormat('HH:mm')}
                        </span>
                      </Typography>
                    </Box>
                  </Box>

                  <Typography className="price">{priceFormated}</Typography>
                </Box>
                <Box className="side_right">
                  <Typography className="name_teacher">
                    {toCamelCase(nextCourse.fullname)}
                  </Typography>

                  <Typography className="name_course">
                    {nextCourse.name}
                  </Typography>

                  <Typography className="tag_course">
                    {nextCourse?.donation ? 'Curso Teórica' : 'Curso Prático'}
                  </Typography>

                  <Box
                    display="flex"
                    flexWrap="wrap"
                    className="box_categories"
                  >
                    {nextCourse.categories_names?.split(',').map(category => (
                      <Typography key={category} className="category">
                        {toCamelCase(category)}
                      </Typography>
                    ))}
                  </Box>

                  <Box
                    className="description_course"
                    dangerouslySetInnerHTML={{ __html: nextCourse.description }}
                  />

                  <Box
                    display="flex"
                    justifyContent="center"
                    maxWidth={280}
                    width="100%"
                  >
                    <Box className="link_next_course">
                      <Typography
                        data-giflabel="gif_label_card"
                        data-subscribe={isSubcribeCourse}
                      >
                        {configNextCourse.label}
                      </Typography>
                      <Typography>saiba mais</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <SkeletonNextCourse />
            )}
          </Hidden>
        </Box>
      </ContainerApp>

      <Hidden smDown>
        <Box display="flex" flexWrap="wrap" className={style.containerCourses}>
          {courses.length > 0 ? (
            courses.map(c => <CourseCard course={c} key={c.id} />)
          ) : (
            <>
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
              <SkeletonCourse />
            </>
          )}
          <Box
            className={style.boxReadMoreCourses}
            display="flex"
            alignItems="center"
            justifyContent="center"
            component="a"
            href="/cursos"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box
                className="circle_arrow"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={arrowMoreCourses} alt="" />
              </Box>
              <Typography className="label_read_more_courses">
                Confira todos <br /> os cursos
              </Typography>
            </Box>
          </Box>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Box className={style.carouselHomeMobile}>
          <Box display="flex" className="area_carousel courses">
            {courses.length > 0 ? (
              courses.map(c => <CourseCard course={c} key={c.id} />)
            ) : (
              <>
                <SkeletonCourse />
                <SkeletonCourse />
                <SkeletonCourse />
                <SkeletonCourse />
                <SkeletonCourse />
                <SkeletonCourse />
              </>
            )}
            <Box
              className={style.boxReadMoreCourses}
              display="flex"
              alignItems="center"
              justifyContent="center"
              component="a"
              href="/cursos"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Box
                  className="circle_arrow"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={arrowMoreCourses} alt="" />
                </Box>
                <Typography className="label_read_more_courses">
                  Confira todos <br /> os cursos
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box style={{ padding: 10 }}>
          <Link to="/cursos" className={style.linkAllCourses}>
            Confira todos os cursos
          </Link>
        </Box>
      </Hidden>

      <ContainerApp className={style.home}>
        <Box className={`${style.boxMiniSlides} categories`}>
          <Typography align="center" className={style.titleHome}>
            Categorias
          </Typography>
          <Hidden smDown>
            {!categoryNotFound ? (
              <SlideCategory
                className={style.styleSlides}
                categories={categories}
              />
            ) : (
              <SkeletonCarouselCategory className={style.titleHome} />
            )}
          </Hidden>
          <Hidden mdUp>
            <Box className={style.carouselHomeMobile}>
              <Box
                display="flex"
                className={`${style.styleSlides} area_carousel`}
              >
                {!categoryNotFound
                  ? categories.map(c => (
                      <Box
                        key={c.id}
                        className="box_mini_slide"
                        component="a"
                        href={`/cursos?category=${c.name}`}
                      >
                        <Box className="image_mini_slide">
                          <img src={c.image_url || categoria} alt="" />
                        </Box>
                        <Box className="name_mini_slide">
                          <Typography>{c.name}</Typography>
                        </Box>
                      </Box>
                    ))
                  : [1, 2, 3, 4, 5].map(skeleton => (
                      <Box
                        key={skeleton}
                        className="box_mini_slide"
                        component="a"
                      >
                        <Box className="image_mini_slide">
                          <Skeleton width={350} />
                        </Box>
                        <Skeleton width={150} />
                      </Box>
                    ))}
              </Box>
            </Box>
          </Hidden>
        </Box>

        <Box className={`${style.boxMiniSlides} teachers`}>
          <Typography align="center" className={style.titleHome}>
            Professores
          </Typography>
          <Hidden smDown>
            {!teacherNotFound ? (
              <SlideTeachers
                className={style.styleSlides}
                teachers={teachers}
              />
            ) : (
              <SkeletonCarouselProffesor />
            )}
          </Hidden>
          <Hidden mdUp>
            <Box className={style.carouselHomeMobile}>
              <Box
                display="flex"
                className={`${style.styleSlides} area_carousel`}
              >
                {!teacherNotFound
                  ? teachers.map(t => (
                      <Box
                        key={t.id}
                        className="box_mini_slide"
                        component="a"
                        href={`/cursos?teacher=${t.name}`}
                      >
                        <Box className="image_mini_slide">
                          <img src={t.path_image || professor} alt="" />
                        </Box>
                        <Typography className="name_mini_slide">
                          {t.name}
                        </Typography>
                      </Box>
                    ))
                  : [1, 2, 3, 4, 5].map(skeleton => (
                      <Box
                        key={skeleton}
                        className="box_mini_slide"
                        component="a"
                      >
                        <Box className="image_mini_slide">
                          <Skeleton width={350} />
                        </Box>
                        <Skeleton width={150} />
                      </Box>
                    ))}
              </Box>
            </Box>
          </Hidden>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          className={style.boxPricePeriod}
        >
          <Box display="flex" flexWrap="wrap" className="box period">
            <Typography className={style.titleHome} align="center">
              Período
            </Typography>

            <Box display="flex">
              <Box className={style.boxSquare}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  component="a"
                  href="/cursos?period=MANHÃ"
                >
                  <Typography className="label_primary">Manhã</Typography>
                  <Typography className="label_secondary">
                    das 9:00 às 11:30
                  </Typography>
                </Box>
              </Box>

              <Box className={style.boxSquare}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  component="a"
                  href="/cursos?period=TARDE"
                >
                  <Typography className="label_primary">Tarde</Typography>
                  <Typography className="label_secondary">
                    das 14:00 às 16:30
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex">
              <Box className={style.boxSquare}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  component="a"
                  href="/cursos?period=INTEGRAL"
                >
                  <Typography className="label_primary">Integral</Typography>
                  <Typography className="label_secondary">
                    das 9:00 às 16:30
                  </Typography>
                </Box>
              </Box>

              <Box className={style.boxSquare}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  component="a"
                  href="/cursos?period=SÁBADO"
                >
                  <Typography className="label_primary">Sábado</Typography>
                  <Typography className="label_secondary">
                    das 9:00 às 12:00
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexWrap="wrap" className="box price">
            <Typography className={style.titleHome} align="center">
              Preços
            </Typography>

            <Box display="flex">
              <Box className={style.boxSquare}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  component="a"
                  href="/cursos?price=gratis"
                >
                  <Typography className="label_primary">Grátis</Typography>
                </Box>
              </Box>

              <Box className={style.boxSquare}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  component="a"
                  href="/cursos?price=0&&39"
                >
                  <Typography className="label_secondary">até</Typography>
                  <Typography className="label_primary">R$ 39,99</Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex">
              <Box className={style.boxSquare}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  component="a"
                  href="/cursos?price=40&&99"
                >
                  <Typography className="label_secondary">
                    de R$40,00 até
                  </Typography>
                  <Typography className="label_primary">R$ 99,99</Typography>
                </Box>
              </Box>

              <Box className={style.boxSquare}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  component="a"
                  href="/cursos?price=100"
                >
                  <Typography className="label_secondary">
                    a partir de
                  </Typography>
                  <Typography className="label_primary">R$ 100,00</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          className={style.boxDepoiments}
        >
          <Hidden mdUp>
            <Typography align="center" className={style.titleHome}>
              Depoimentos
            </Typography>
          </Hidden>

          <Box className="image_depoiment">
            <Hidden smDown>
              <Typography align="center" className={style.titleHome}>
                Depoimentos
              </Typography>
            </Hidden>
            <img src={imageDepoiment} alt="" />
          </Box>

          <Box className="box_content">
            <span className="arrow_depoiment"></span>
            <Slider {...settings}>
              {depoiments.map(d => (
                <Box key={d.fullname}>
                  <Typography className="name_student">{d.fullname}</Typography>
                  <Box display="flex" className="area_text">
                    <span className="aspas aspas_start">&#8220;</span>
                    <Typography className="text_depoiment">
                      <span>{d.about_course}</span>
                    </Typography>
                    <span className="aspas aspas_end">&#8222;</span>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>

        <Box
          display="flex"
          flexWrap="wrap"
          className={`${style.containerBigSquare} infra_local`}
        >
          <Box className="infra_local--box">
            <Typography align="center" className={style.titleHome}>
              Nossa Estrutura
            </Typography>
            <Box className={`${style.boxInfra}`}>
              <SliderInfra className={style.infraRoom} />
            </Box>
          </Box>

          <Box className="infra_local--box">
            <Typography align="center" className={style.titleHome}>
              Local do Curso
            </Typography>
            <Box className={`${style.boxLocal}`}>
              <Box className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.4944866739547!2d-46.572820684475964!3d-23.550677567084126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5e969718ba07%3A0x185cdeaff8f17631!2sR.%20Serra%20de%20Jur%C3%A9a%2C%20736%20-%20Tatuap%C3%A9%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003323-020!5e0!3m2!1spt-BR!2sbr!4v1608129431560!5m2!1spt-BR!2sbr"
                  width="628"
                  height="313"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                />
              </Box>

              <Box
                className="address"
                display="flex"
                justifyContent="space-around"
              >
                <Box>
                  <Typography className={style.titleHome}>Endereço</Typography>
                  <Typography className="text">
                    Rua Serra de Juréa, 736
                  </Typography>
                  <Typography className="text">
                    Tatuapé - São Paulo - SP
                  </Typography>
                </Box>

                <Box>
                  <Typography className={style.titleHome}>Telefone</Typography>
                  <Typography className="text">(11) 2388-3378</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" className={style.containerSpecification}>
          <Box
            className={`${style.boxSpecification}`}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography align="center" className={style.titleHome}>
              Aulas com preços acessíveis.
            </Typography>
            <Typography align="center" className="text">
              Os cursos custam em média R$30,00 por aula! Nos cursos Grátis
              pedimos a doação de alimentos que são doados para instituições
              carentes.
            </Typography>
            <Box
              className="image"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={custoBeneficio} alt="" />
            </Box>
          </Box>

          <Box
            className={`${style.boxSpecification}`}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography align="center" className={style.titleHome}>
              Professores e Chefs renomados.
            </Typography>
            <Typography align="center" className="text">
              Aprenda com Chefs, Professores e Culinaristas que possuem anos{' '}
              <br /> de experiência e muito <br />
              conhecimento no ramo!
            </Typography>
            <Box
              className="image"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={chefsRenomados} alt="" />
            </Box>
          </Box>

          <Box
            className={`${style.boxSpecification} course_free`}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography align="center" className={style.titleHome}>
              Cursos livres <br />
              todos os dias.
            </Typography>
            <Typography align="center" className="text">
              Os nossos cursos são para iniciantes ou profissionais. A maioria
              das aulas são teóricas para o professor demonstrar tudo em aula!
            </Typography>
            <Box
              className="image"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={cursosLivres} alt="" />
            </Box>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box className={style.boxVacancy} display="flex">
            <Box display="flex" flexDirection="column" className="box box-left">
              <Typography className={style.titleHome}>
                Garanta já sua vaga.
              </Typography>
              <Typography className="text">
                Diversos cursos para você se especializar na confeitaria e
                transformar sua cozinha em sua empresa.
              </Typography>
              <Typography className="text bold">
                FAÇA, VENDA e LUCRE!
              </Typography>
            </Box>

            <Box
              className="box box-right"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <a href="/cursos" className="link_all_courses">
                Confira todos <br /> os cursos
              </a>
            </Box>
          </Box>
        </Box>
      </ContainerApp>

      <Footer />
    </>
  );
}

export default Home;
