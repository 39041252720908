import { makeStyles } from "@material-ui/core/styles";

const globalStyles = makeStyles(theme => ({


  root: {
    "& *": {
      fontFamily: 'lato !important',
      fontWeight: 400,
    },
    '@media print': {
      '&.header': {
        display: 'none !important',
      },
      '& > $certificateFix': {
        display: 'block !important',
      },
    },
    '&.header': {
      display: 'flex',
      width: '100%',
      height: 50,
      position: 'fixed',
      background: '#fff',
      zIndex: 999,
      boxShadow: '0px 1px 6px -1px #ccc',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',

      },
      [theme.breakpoints.up(`md`)]: {
        justifyContent: 'space-between'
      }

    },
    "& button": {
      "&:disabled": {
        background: "#f5f5f5 !important",
        border: "1px solid #ccc !important",
        color: "#ccc !important",
        cursor: "not-allowed !important"
      }
    },
    '& div.invalid': {
      '& label': {
        color: 'red !important',
      },
      '& > div > fieldset': {
        borderColor: 'red !important',
      },
      '& p': {
        color: 'red !important',
      },
    },
    '& div.isValid': {
      '& label': {
        color: 'green !important',
      },
      '& > div > fieldset': {
        borderColor: 'green !important',
      },
      '& p': {
        color: 'green !important',
      },
    },
    '& .drawer': {
      [theme.breakpoints.up('md')]: {
        width: 500,
        padding: '60px 40px 0px 40px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '40px 20px 0 20px',
        height: '100vh',
        width: '100vw',
      },

    }
  },
  grayPrimary: {
    background: '#f5f5f5',
  },
  titleForm: {
    color: '#b01010',
    fontSize: '25px',
    padding: '1rem 0 .5rem',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },
  btnBackDrawer: {
    position: 'absolute !important',
    top: 10,
    left: 15,
    color: "#b01010 !important",
  },
  btnCloseDrawer: {
    position: 'absolute !important',
    top: 10,
    right: 15,
    color: "#b01010 !important",
  },
  centerFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  alert: {
    textAlign: 'center',
    padding: '0 0',
    color: "#fff",
    borderRadius: 5,
    margin: '10px 0',
    transition: 'linear .2s',
    '&.error': {
      background: 'var(--colorError)',
      display: 'block',
      padding: '10px 0',
    },
    '&.success': {
      background: 'var(--colorSuccess)',
      display: 'block',
      padding: '10px 0',
    },
    '&.warning': {
      background: 'var(--colorWarning)',
      display: 'block',
      padding: '10px 0',
    }
  },
  content: {
    '@media print': {
      '& button': {
        display: 'none',
      },
      '& > $certificateFix': {
        display: 'block !important',
      },
    },
    paddingTop: 50,
    paddingBottom: 70,
    minHeight: '100vh',
    "& *": {
      fontFamily: 'lato !important',
      fontWeight: 400,
    },
  },
  inputFile: {
    '& .labelNameImage': {
      display: 'inline-flex',
      paddingLeft: 10,
      width: '65%',
      overflow: 'hidden'
    }
  },
  circleImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    width: 100,
    height: 100,
    overflow: 'hidden',
    borderRadius: '50%',
    '& > img': {
      maxWidth: '100%',
      height: 'auto',
    }
  },
  inputTeste: {
    "& > label.Mui-focused": {
      color: '#000 !important'
    },
    "& > div.Mui-focused > fieldset": {
      borderColor: '#000 !important'
    },
  }

}))

export default globalStyles;


