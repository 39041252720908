import React from 'react';
import DashAdmin from '~/Components/DashAdmin';

export default function Dashboard() {
  return (
    <>
      <DashAdmin title={'Dashboard'} content={null} width={1200}/>
    </>
  );
}
