import React from 'react';
// import useStyles from './style';

import DashAdmin from '~/Components/DashAdmin';
import FormBannerEdit from '~/Components/Forms/FormBannerEdit';

export default function EditBanner({ match }) {
  return (
    <>
      <DashAdmin
        content={
          <FormBannerEdit
            handleDrawer={() => {}}
            id={match.params.id}
            form={'banner'}
          />
        }
        title={'Editar Banner'}
        width={'1200px'}
      />
    </>
  );
}
