import React from 'react';
import DashAdmin from '~/Components/DashAdmin';
import FormCourses from '~/Components/Forms/Courses/FormCourses';

// import { Container } from './styles';

export default function AddCourses() {
  return (
    <DashAdmin content={<FormCourses/>} title={'Cadastrar novo Curso'} width={'850px'}/>
  );
}