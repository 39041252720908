import React from 'react';
import DashAdmin from '~/Components/DashAdmin';
import TableTeacher from '~/Components/Tables/TableTeacher';

// import { Container } from './styles';

export default function ListTeacher() {
  return (
    <>
      <DashAdmin content={<TableTeacher />} title={'Lista de Professores'} width={'100%'} />
    </>
  );
}
