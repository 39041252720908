import { makeStyles, createTheme } from "@material-ui/core/styles"

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({

  active: {
    color: 'var(--colorSecondary) !important',
    fontWeight: '700 !important',
    borderBottom: '3px solid #960D0D',
    width: 'max-content'
  },

  containerDados: {
    maxWidth: 483,
    width: '100%',
    minHeight: 280,
    height: 'auto',
    border: '1px solid #daa3a3',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 8,
    marginRight: 48,

    '& h1': {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 'bold',
      marginTop: 32,
      marginBottom: 25,
      color: '#d01717',
    },

    [responsive.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginBottom: 32,
      marginRight: 20,
    },

    [responsive.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '0 16px 32px 16px',
    },
  },

  containerCourses: {
    maxWidth: 483,
    width: '100%',
    height: 248,
    border: '1px solid #daa3a3',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 10px 10px 10px',

    '& h1': {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 'bold',
      marginTop: 32,
      marginBottom: 25,
      color: '#d01717',
    },

    [responsive.breakpoints.down('md')]: {
      maxWidth: '100%',
      margin: '0 16px 32px 16px',
    },
  },

}));

export default useStyles
