import { createGlobalStyle } from "styled-components"
import { MainFonts } from "../fonts/fonts"

const StylesGlobals = createGlobalStyle`
	${MainFonts}

  :root{
    --colorError:'#ff0000a8';
    --colorSuccess: '#008000a1',
    --colorWarning: '#ff8100bf',
    --colorSecondary: '#d81717',
    --textPrimary: '#271e1e',
  }

	* {
    font-family: 'lato', sans-serif !important;
    text-decoration: none;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    list-style: none;
	}

  body {
    overflow-anchor: none;
    background: #f4f4f4;
  }

  .area_carousel::-webkit-scrollbar {
    width:2px !important;
    height: 2px !important;
    -webkit-overflow-scrolling: auto !important;
  }

  .area_carousel::-webkit-scrollbar-track {
    background:#bfbfbf !important;
  }
  .area_carousel::-webkit-scrollbar-thumb {
    background: #d81717 !important;
  }

  @media print {
    .header {
      display: none;
    }

    .footer{
      display: none;
    }
  }
`

export default StylesGlobals
