import React, { useEffect, useState } from 'react';
import api from '~/Services/api';
import useStyles from '../../style';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Select,
  Slide,
  Typography,
} from '@material-ui/core';
import {
  Add,
  CheckCircleOutline,
  Delete,
  Edit,
  Search,
} from '@material-ui/icons';
import {
  CustomIconButtom,
  CustomButton,
  TitleTables,
  InputForm,
} from '~/Components/Custom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function TableConfiguration() {
  const style = useStyles();
  const [modalDelete, setModalDelete] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState({});
  const [config, setConfig] = useState({
    page: 1,
    pages: 1,
    limit: 10,
  });
  const [banners, setBanners] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  function openDeleteModal(banner) {
    setSelectedBanner(banner);
    setModalDelete(true);
  }

  function closeDeleteModal() {
    setModalDelete(false);
  }

  async function getBanners() {
    try {
      const res = await api.get(
        `banners?page=${config.page}&limit=${config.limit}`
      );
      setConfig({
        ...config,
        pages: res.data.pages,
      });

      setBanners(res.data.banners);
    } catch (err) {
      console.error(err);
    }
  }

  function handleSearchChange(event) {
    setSearchTerm(event.target.value);
  }

  function handleSearch() {
    getBanners();
  }

  async function updateBannerStatus(bannerId, currentStatus) {
    try {
      const newStatus = currentStatus === 1 ? 0 : 1;
      await api.put(`banners/${bannerId}`, {
        status: newStatus,
      });

      setBanners(prevBanners =>
        prevBanners.map(banner =>
          banner.id === bannerId ? { ...banner, status: newStatus } : banner
        )
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteBanner() {
    try {
      console.log(`Deletando o banner com ID: ${selectedBanner.id}`);
      await api.delete(`banners/${selectedBanner.id}`);

      getBanners();

      closeDeleteModal();
    } catch (error) {
      console.error(error);
    }
  }

  function prevPage() {
    if (config.page > 1) {
      setConfig({ ...config, page: config.page - 1 });
      window.scrollTo(0, 0);
    }
  }

  function nextPage() {
    if (config.page < config.pages) {
      setConfig({ ...config, page: config.page + 1 });
      window.scrollTo(0, 0);
    }
  }

  function limit(e) {
    setConfig({ ...config, page: 1, limit: e.target.value });
  }

  useEffect(() => {
    getBanners();
  }, [config.page, config.limit]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        className={style.btnLimit}
      >
        <InputForm variant="outlined">
          <InputLabel htmlFor="input_limit">Limite por página</InputLabel>
          <Select
            id="input_limit"
            name="limit"
            value={config.limit}
            onChange={limit}
            labelWidth={130}
          >
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem>
          </Select>
        </InputForm>

        {/* <InputForm variant="outlined" className="input-search">
          <InputLabel htmlFor="input_search">Pesquisar por nome</InputLabel>
          <input
            type="text"
            id="input_search"
            name="search"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </InputForm> */}

        <CustomButton
          className="btn_add_table"
          bg={'#fabc4a'}
          startIcon={<Search />}
          onClick={handleSearch}
        >
          Pesquisar
        </CustomButton>
      </Box>
      <table cellPadding={0} cellSpacing={0} className={style.table}>
        <thead>
          <tr>
            <th className="photo">Banner</th>
            <th align="center">Nome</th>
            <th align="center">Descrição</th>
            <th align="center">Status</th>
            <th align="center">Ações</th>
            {/* <th className="options">Opções</th> */}
          </tr>
        </thead>
        <tbody>
          {banners.map(banner => (
            <tr key={banner.id}>
              {/* Banner */}
              <td className="photo banner">
                <img
                  src={banner.banner_url}
                  alt={`Banner ${banner.name}`}
                  title={`Banner ${banner.name}`}
                  width={100}
                  height={100}
                />
              </td>
              {/* Nomes */}
              <td align="center">{banner.name}</td>
              {/* Descricao */}
              <td align="center">{banner.description}</td>
              {/* Status */}
              <td align="center">
                <CustomIconButtom
                  icon_color={banner.status === 1 ? 'green' : '#ccc'}
                  onClick={() => updateBannerStatus(banner.id, banner.status)}
                >
                  <CheckCircleOutline />
                </CustomIconButtom>
              </td>
              {/* Ações */}
              <td className="options actions" align="center">
                <CustomIconButtom
                  icon_color="#fabc4a"
                  component="a"
                  href={`/admin/configuracoes/editar-banner/${banner.id}`}
                >
                  <Edit />
                </CustomIconButtom>
                <CustomIconButtom
                  icon_color="#ff7171"
                  onClick={() => openDeleteModal(banner)}
                >
                  <Delete />
                </CustomIconButtom>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={style.paginationTable}
      >
        <CustomButton
          bg="#f4393c"
          onClick={prevPage}
          disabled={config.page <= 1}
        >
          Anterior
        </CustomButton>
        <Typography>
          {config.page} / {config.pages}
        </Typography>
        <CustomButton
          bg="#f4393c"
          onClick={nextPage}
          disabled={config.page >= config.pages}
        >
          Próximo
        </CustomButton>
      </Box>
      <Dialog
        open={modalDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModalDelete(false)}
      >
        <DialogTitle>Deletar Banner</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja realmente excluir o banner {selectedBanner.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton bg="#ff7171" onClick={() => setModalDelete(false)}>
            Cancelar
          </CustomButton>
          <CustomButton bg="#6fdb6f" onClick={deleteBanner}>
            Sim
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TableConfiguration;
