import React, { useEffect, useRef } from 'react'

import { useField } from '@unform/core'
import { FormHelperText, Grid, TextField } from '@material-ui/core'

import useStyles from './style'

const Input = ({ name, label, ...rest }) => {
  const { fieldName, registerField, error } = useField(name)

  const inputRef = useRef(null)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])



  const style = useStyles()
  return (
    <div>
      <Grid item xs={12} className={style.form} >
        <label>{label}</label>
        <TextField
          inputRef={inputRef}
          {...rest}
        />
        <FormHelperText>{error && error}</FormHelperText>
      </Grid>

    </div>
  )
}

export default Input
