import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Slider from 'react-slick';

import salaCourseFoto1 from '~/Assets/img/sala-curso-1.jpg'
import salaCourseFoto2 from '~/Assets/img/sala-curso-2.jpg'
import salaCourseFoto3 from '~/Assets/img/sala-curso-3.jpg'
import salaCourseFoto4 from '~/Assets/img/sala-curso-4.jpg'

function SliderInfra({ className, ...props }) {

  const infraRoom = [
    { id: 1, name: 'foto1', path_image: salaCourseFoto1, alt: 'Foto 1 da sala de curso' },
    { id: 2, name: 'foto2', path_image: salaCourseFoto2, alt: 'Foto 2 da sala de curso' },
    { id: 3, name: 'foto3', path_image: salaCourseFoto3, alt: 'Foto 3 da sala de curso' },
    { id: 4, name: 'foto4', path_image: salaCourseFoto4, alt: 'Foto 4 da sala de curso' },
  ]

  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 1,
    speed: 500,
    swipeToSlide: true,
    useTransform: false,
  }

  return (
    <>
      <Slider {...settings} className={`${className}`}>
        {infraRoom.map(room => (
          <Box key={room.id} className='boxRoom' >
            <Box className='boxRoomImg'>
              <img src={room.path_image} alt={room.alt} />
            </Box>
          </Box>
        ))}
      </Slider>
    </>
  )
}

export default SliderInfra;
