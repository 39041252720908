const { makeStyles, createTheme } = require('@material-ui/core/styles');

const shadow = '0px 2px 5px 0px rgb(39 30 30 / .2)';
const shadowHover = '0px 2px 15px 0px rgb(39 30 30 / .4)';

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    width: 304,
    height: 440,
    margin: '10px 0',
    marginTop: 65,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 50,
    zIndex: 1,
  },

  boxCourse: {
    borderTop: `5px solid #D01717`,
    background: '#fff',
    transition: 'linear .05s',
    borderRadius: 5,
    height: 420,
    boxShadow: shadow,
    zIndex: 1,

    [responsive.breakpoints.up('md')]: {
      '&:not(.active):hover': {
        height: 440,
        boxShadow: shadowHover,

        '& $btnCardCourse': {
          height: 75,
          overflow: 'none',

          '& .label_second': {
            color: props => props.labelColor,
          },
        },
      },
    },

    [responsive.breakpoints.down('sm')]: {
      height: 440,
      boxShadow: shadowHover,

      '& $btnCardCourse': {
        height: 75,
        overflow: 'none',

        '& .label_second': {
          color: props => props.labelColor,
        },
      },
    },
  },

  tag_course: {
    background: '#f4f4f4',
    textAlign: 'center',
    display: 'flex',
    width: 200,
    margin: '0 auto',
    marginTop: 10,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    color: '#271e1e',
    padding: '2px 0',
  },

  photoTeacher: {
    width: 152,
    height: 152,
    borderRadius: '100%',
    border: '5px solid #f4f4f4',
    overflow: 'hidden',
    position: 'absolute',
    top: -76,
    background: '#f4f4f4',
    zIndex: 2,

    '& img': {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '50%',
      border: '3px solid #b01010',
    },
  },

  nameTeacher: {
    fontSize: 20,
    color: '#271e1e',
    padding: '0 10px',
    textAlign: 'center',
    marginTop: 91,
  },

  nameCourse: {
    fontSize: 18,
    color: '#271e1e',
    padding: '0 10px',
    textAlign: 'center',
    marginTop: 10,
    height: 40,
    lineHeight: '20px',
    overflow: 'hidden',
    fontWeight: 'bold',
  },

  boxCategories: {
    marginTop: 14,
    overflow: 'hidden',
    height: 28,
  },

  nameCategory: {
    color: '#d81717',
    background: '#f4f4f4',
    padding: '2px 18px',
    borderRadius: 10,
    marginRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  boxInfosCourse: {
    marginTop: 12,

    '& *': {
      color: '#271e1e',
    },

    '& .box': {
      width: '50%',
    },

    '& .date_course, .hour_start_course, .hour_end_course': {
      fontWeight: 'bold',
      marginLeft: 5,
    },

    '& .date_course, .hour_start_course': {
      fontSize: 22,
    },
  },

  priceCourse: {
    fontSize: 32,
    color: '#d81717',
    fontWeight: 'bold',
    marginTop: 11,
    textAlign: 'center',
  },

  btnCardCourse: {
    background: props => props.bgButton,
    color: '#fff',
    height: 55,
    overflow: 'hidden',
    transition: 'linear .05s',
    marginTop: 20,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    border: props => (props.border_button ? props.border_button : ''),

    '& .label_primary': {
      fontSize: 22,
      height: 55,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: props => props.labelColor,

      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        fontSize: 22,
        height: 55,
      },

      '& span:nth-child(2)': {
        display: 'none',
      },
    },

    '& .confirmationCourse': {
      height: 25,
      fontSize: 18,
    },

    '& .phone_teacher': {
      color: '#D01717',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 15,

      '&  button': {
        marginLeft: 5,
        color: '#419a58',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        background: 'transparent',
      },
    },

    '& .label_second': {
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      top: -15,
      transition: 'linear .2s',
      color: 'transparent',
    },
  },

  unsubscribeButton: {
    position: 'absolute',
    top: 20,
    right: 25,
    borderRadius: '50%',
    width: 40,
    height: 40,
    background: '#d01717',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
    transition: '2s',
    cursor: 'pointer',

    '&.active': {
      background: '#fff',

      '& .MuiSvgIcon-root': {
        color: '#020202',
      },
    },

    '&:hover': {
      opacity: 0.8,
    },

    '& .MuiSvgIcon-root': {
      color: '#fff',
      fontSize: '1.8rem',
    },
  },

  BoxInfoCourse: {
    position: 'absolute',
    width: '100%',
  },

  canceledCourse: {
    position: 'absolute',
    top: 0,
    height: 444,
    width: 0,
    background: '#d01717',
    borderRadius: 8,
    zIndex: 1,
    transition: 'width 0.4s ease-in-out ',
    // transitionDuration: '0.5s',

    '&.active': {
      width: '100%',
    },

    [responsive.breakpoints.down('sm')]: {
      height: 465,
    },
  },

  boxCanceled: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    marginTop: 90,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '80%',
    width: '100%',
    margin: '0 auto',
    height: 330,
    opacity: 0,
    display: 'none',

    [responsive.breakpoints.down('sm')]: {
      height: 350,
    },

    '&.active': {
      animation: 'fade_in_show 5s ease-in',
      opacity: 1,
      display: 'flex',
    },

    '& h3': {
      marginTop: 10,
      fontSize: 22,
      color: '#fff',
    },

    '& p': {
      textAlign: 'center',
      fontSize: 18,
      marginTop: 10,
      color: '#fff',
    },

    '& .MuiTextField-root': {
      width: '100%',

      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
    },

    '& textarea': {
      // height: '150px !important',
      marginTop: 20,
      width: '100%',
      borderRadius: 5,
      background: '#ff7474',
      padding: 10,
      color: '#271e1e',
      outline: 'none',
      border: 'none',

      '&::placeholder': {
        color: '#271e1e',
      },
    },

    '& button': {
      marginTop: 20,
      fontSize: 18,
      color: '#fff',
      cursor: 'pointer',

      '&:disabled': {
        color: '#f96969',
      },

      [responsive.breakpoints.down('sm')]: {
        marginTop: 40,
      },
    },
  },

  '@keyframes fade_in_show': {
    '0% ': {
      opacity: 0,
    },
    '20% ': {
      opacity: 0,
    },
    '40% ': {
      opacity: 0,
    },
    '60% ': {
      opacity: 0,
    },
    '80% ': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export default useStyles;
