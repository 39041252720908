import { css } from 'styled-components'
import NunitoRegular from './Nunito/Nunito-Regular.ttf'
import LatoRegular from './Lato/Lato-Regular.ttf'


export const MainFonts = css`
	@font-face{
		font-family: 'nunito';
    font-weight: 400;
    src: url('${NunitoRegular}');
	}

  @font-face{
		font-family: 'lato';
    font-weight: 400;
    src: url('${LatoRegular}');
	}
`;
