import React from 'react';

import iconFacebook from '~/Assets/img/icon-facebook.png'
import iconYoutube from '~/Assets/img/icon-youtube.png'
import iconInstagram from '~/Assets/img/icon-instagram.png'

import useStyles from './style'

import { Box, Container, Grid, Typography } from '@material-ui/core';

function Footer({ className }) {

  const style = useStyles()

  return (
    <Box className={`${style.footer} footer ${className}`}>
      <Container maxWidth="md">
        <Grid container className='area_footer'>

          <Grid item sm={12} md={6} container justifyContent='center'>
            <Box className='box' display='flex'>
              <Box className='box_social'>
                <Typography className={'title_footer'}>Rede Social</Typography>
                <Typography className={'text'}>
                  Além de cursos presenciais, temos muito <br />
                  conteúdo especializado, lives e diversas <br />
                  receitas em nossas redes sociais. Confira!
                </Typography>
                <Box display='flex' className='box_icons_social'>
                  <Box className='box_square_social' display='flex' justifyContent='center' alignItems='center' target='_blank' component='a' href='https://www.youtube.com/user/lojasantoantonio'>
                    <img src={iconYoutube} alt="" />
                  </Box>
                  <Box className='box_square_social' display='flex' justifyContent='center' alignItems='center' target='_blank' component='a' href='https://www.instagram.com/lojasantoantonio/'>
                    <img src={iconInstagram} alt="" />
                  </Box>
                  <Box className='box_square_social' display='flex' justifyContent='center' alignItems='center' target='_blank' component='a' href='https://www.facebook.com/lojasantoantonio.sp/'>
                    <img src={iconFacebook} alt="" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item sm={12} md={6} container justifyContent='center'>
            <Box className='box' display='flex' justifyContent='flex-end'>
              <Box className='box_virtual_store'>
                <Typography className={'title_footer'}>Loja Virtual</Typography>
                <Typography className={'text'}>
                  Acesse a nossa Loja Virtual e aproveite diversas <br />
                ofertas de chocolate, produtos para confeitaria,<br />
                formas, embalagens, itens de festa e muito mais.
              </Typography>

                <Box component='a' href='https://www.lojasantoantonio.com.br' className="link_virtual_store" target='_blank'>
                  <Typography>www.lojasantoantonio.com.br</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display='flex' justifyContent='center'>
              <Typography className={style.copyright} >
                <span>Razão Social: Comercial Luzia Meire de Gêneros Alimentícios LTDA</span>
                <span className='separador'>|</span>
                <span>CNPJ: 08.991.182/0001-11</span>
                <span className='separador'>|</span>
                <span>Loja Física: Rua Serra de Juréa, 736 </span>
                <span>Tatuapé - São Paulo - SP - BRASIL</span>
              </Typography>
            </Box>
          </Grid>

        </Grid>
      </Container>
    </Box>
  )
}

export default Footer;
