import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { store } from '~/store'
import api from '~/Services/api';
import { useDispatch } from 'react-redux';
// import { signInExpired } from '~/store/modules/auth/actions';

export default function RouteValidation({ component: Component, privateFor, ...rest }) {
  const { signed } = store.getState().auth;
  const { profile } = store.getState().user;
  const dispatch = useDispatch()

  const validToken = async () => {
    try {
      await api.get(`/users`)
    } catch (error) {
      // dispatch(signInExpired())
    }
  }

  if (!signed && privateFor) {
    return <Redirect to={{ pathname: '/', state: { from: rest.location } }} />;
  }

  if (signed && privateFor && profile.type_user !== privateFor) {
    return <Redirect to={{ pathname: '/', state: { from: rest.location } }} />;
  }

  if (signed && privateFor) {
    validToken()
  }

  return <Route {...rest} component={Component} />;
}

RouteValidation.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteValidation.defaultProps = {
  isPrivate: false,
};
