import { makeStyles, createTheme } from "@material-ui/core/styles"

const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 390,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    width: '100%',
    height: 70,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 18px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.24)',
    borderRadius: 8,
    marginBottom: 24,

    '&.courseCanceled': {
      opacity: 0.6
    },

    '& p': {
      '&:nth-child(1)': {
        fontSize: 14,
        fontWeight: '700',
        color: '#271E1E',
        maxWidth: 190,
        width: '100%',

        [responsive.breakpoints.down('sm')]: {
          maxWidth: '100%',
        }
      },

      '&:nth-child(2)': {
        fontSize: 14,
        color: '#271E1E',
        maxWidth: 200,
        width: '100%',
        textAlign: 'center',

        [responsive.breakpoints.down('sm')]: {
          maxWidth: '100%',
          textAlign: 'initial',
        }
      },

      '&:nth-child(3)': {
        fontSize: 14,
        fontWeight: '700',
        color: '#D01717',
        maxWidth: 210,
        width: '100%',
        textAlign: 'center',

        [responsive.breakpoints.down('sm')]: {
          maxWidth: '100%',
          textAlign: 'initial',
        }
      },

      '&:nth-child(4)': {
        fontSize: 20,
        color: '#271E1E',
        maxWidth: 200,
        width: '100%',
        textAlign: 'center',

        "&.ausent": {
          fontWeight: '700'
        },

        [responsive.breakpoints.down('sm')]: {
          maxWidth: '100%',
          textAlign: 'initial',
        }
      }
    },

    [responsive.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 409,
      width: '100%',
      height: 169,
    },

    '& .canceled': {
      fontSize: 14,
      maxWidth: 150,
      width: '100%',
    }
  },

  button: {
    padding: '0 40px',
    textTransform: 'initial'
  }
}))

export default useStyles
