import React, { useState, useEffect } from 'react';
import globalStyles from '~/Assets/css/globalStyles';
import { useTheme } from '@material-ui/core/styles';
import { Drawer, Button, IconButton, Typography, MobileStepper, Link, Hidden, Menu, MenuItem } from '@material-ui/core';
import FormUser from '../Forms/FormUser';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { Close, ArrowBack, ArrowForward, Dehaze, School, Business, Stars, ShoppingCart, ArrowDropDown, ExitToApp } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { FormLogin, ForgotPass } from '../Forms/Login';
import logo from '~/Assets/img/logo-positivo.jpg'
import useStyles from './style';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from '~/store/modules/auth/actions';
import NavigationProfile from '../NavProfile';
import TokenExpired from '../TokenExpired';
import FirstAccess from '../Modals/FirstAccess';

const Header = (props) => {
  const pattern = globalStyles()
  const classes = useStyles()
  const theme = useTheme();

  useEffect(() => {
    if (props.open || props.from) {
      handleDrawer(props.open.name)
    }
  }, [props.open])

  useEffect(() => {
    if (props.from) {
      handleDrawer('login')
    }
  }, [props.from])

  const dispatch = useDispatch()

  const profile = useSelector(state => state.user.profile)
  const token = useSelector(state => state.auth.token)
  const tokenExp = useSelector(state => state.auth.tokenExpired)

  const [drawer, setDrawer] = useState({
    register: false,
    login: false,
    menuMobile: false,
  })

  const [menuProfile, setMenuProfile] = useState(null)

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  const handleDrawer = (drawerName) => {
    setDrawer({ ...drawer, [drawerName]: !drawer[drawerName] })
  }

  const handleMenuProfile = event => {
    setMenuProfile(event.currentTarget)
  }

  const closeMenuProfile = () => {
    setMenuProfile(null)
  }

  return (
    <>
      <header className={`${pattern.root} header`}>
        {/* Area de marca e menu principal do site de cursos */}
        <div className={classes.areaBrandMenu}>
          <IconButton className={classes.btnMenuMobile} onClick={e => handleDrawer('menuMobile')}>
            <Dehaze />
          </IconButton>
          <div className={classes.brand}>
            <a href="/"><img src={logo} alt="Cursos Loja Santo Antonio" /></a>
          </div>

          <Hidden smDown>
            <div className={classes.menu}>
              <Link href="/" color="inherit">Programação Completa</Link>
              <Link href="/quem-somos" color="inherit">Quem Somos</Link>
              <Link href="/duvidas" color="inherit">Dúvidas</Link>
              <Link href="/" color="inherit">Depoimentos</Link>
              <Link href="/" color="inherit">Loja Virtual</Link>
            </div>
          </Hidden>
        </div>
        {/* Area dos botões para se logar e cadastrar no site de cursos */}
        {!profile ? (
          <div className={classes.areaBtns}>
            <span onClick={e => handleDrawer("register")} className={classes.btnRegister}> CADASTRE-SE </span>
            <span onClick={e => handleDrawer("login")} className={classes.btnSignIn}> ENTRAR </span>
          </div>
        ) : (
            <>
              <Hidden xsDown>
                <Button aria-controls="menuProfile" aria-haspopup="true" className={classes.btnMenuProfile} onClick={handleMenuProfile}>
                  Olá, {profile.fullname.split(" ")[0]} <ArrowDropDown />
                </Button>
                <Menu
                  id="menuProfile"
                  anchorEl={menuProfile}
                  keepMounted
                  open={Boolean(menuProfile)}
                  onClose={closeMenuProfile}
                  className={classes.menuProfileDesk}
                >
                  {profile.type_user === 'admin' ?
                    <MenuItem component='a' href="/admin">Dashboard</MenuItem>
                    :
                    <div>
                      <MenuItem component='a' href="/area-do-aluno">Meu Perfil</MenuItem>
                      <MenuItem component='a' href="/area-do-aluno/meus-cursos">Meus Cursos</MenuItem>
                    </div>
                  }
                  <MenuItem onClick={() => {
                    setMenuProfile(null)
                    dispatch(signOut())
                  }}>Sair</MenuItem>
                </Menu>
              </Hidden>
            </>
          )}
      </header>
      {/*  Drawer Register User */}
      <Drawer className={pattern.root} anchor={window.innerWidth > 960 ? 'right' : 'bottom'} open={drawer.register} onClose={e => handleDrawer("register")}>
        <div role="presentation">
          <div className={"drawer"}>
            <IconButton className={pattern.btnCloseDrawer} onClick={e => handleDrawer('register')}>
              <Close />
            </IconButton>
            <Typography className={`${pattern.titleForm} ${classes.titleRegister}`}>Cadastre-se para se tornar um aluno!</Typography>
            <FormUser handleDrawer={handleDrawer} />
          </div>
        </div>
      </Drawer>
      {/*  Drawer Login */}
      <Drawer className={pattern.root} anchor={'right'} open={drawer.login} onClose={e => handleDrawer("login")}>
        <div role="presentation">
          <div className={"drawer"}>
            {activeStep === 1 ? (
              <IconButton onClick={handleBack} className={pattern.btnBackDrawer}>
                <ArrowBack />
              </IconButton>
            ) : ""}
            <IconButton onClick={e => handleDrawer('login')} className={pattern.btnCloseDrawer}>
              <Close />
            </IconButton>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              style={{ paddingTop: 50 }}
              disabled
            >
              <div style={{ padding: 10 }}>
                <Typography className={pattern.titleForm}>Faça seu login</Typography>
                <FormLogin handleDrawer={handleDrawer} />
                <Typography onClick={handleNext} className={pattern.centerFlex} style={{ padding: 10, cursor: 'pointer', color: "#b01010" }}>
                  Esqueci minha senha
                  <ArrowForward fontSize="small" />
                </Typography>
              </div>

              <div style={{ padding: 10 }}>
                <Typography className={pattern.titleForm}>Confirme seu CPF</Typography>
                <ForgotPass />
              </div>

            </SwipeableViews>
            <MobileStepper steps={2} activeStep={activeStep} />
          </div>
        </div>
      </Drawer>
      {/* Drawer Menu Mobile */}
      <Drawer className={pattern.root} anchor={'left'} open={drawer.menuMobile} onClose={e => handleDrawer('menuMobile')}>
        <div role="presentation">
          <div className="drawer">
            <IconButton onClick={e => handleDrawer('menuMobile')} className={pattern.btnCloseDrawer}>
              <Close />
            </IconButton>
            <nav>
              <a href="/nossos-cursos" className={classes.itemMenuMobile}>
                <School /> <span>Nossos Cursos</span>
              </a>

              <a href="/quem-somos" className={classes.itemMenuMobile}>
                <Business /> <span>Quem somos</span>
              </a>

              <a href="/duvidas" className={classes.itemMenuMobile}>
                <QuestionAnswerIcon /> <span>Dúvidas</span>
              </a>

              <a href="/depoimentos" className={classes.itemMenuMobile}>
                <Stars /> <span>Depoimentos</span>
              </a>

              <a href="https://www.lojasantoantonio.com.br" className={classes.itemMenuMobile}>
                <ShoppingCart /> <span>Loja Física</span>
              </a>
              <a href="/" className={classes.itemMenuMobile} onClick={() => dispatch(signOut())}>
                <ExitToApp /> <span>Sair</span>
              </a>
            </nav>
          </div>
        </div>
      </Drawer>

      {profile ?
        profile.type_user === 'student' ?
          (
            <Hidden mdUp>
              <NavigationProfile page={props.page} />
            </Hidden>
          )
          : ""
        : ""}

      {tokenExp ? (
        <TokenExpired handleDrawer={handleDrawer} />
      ) : ""}


      {profile ?
        <FirstAccess profile={profile} token={token} />
        : ""}

    </>
  )
}

export default Header
