const { makeStyles } = require('@material-ui/core/styles');

const useStyles = makeStyles(theme => ({
  table: {
    borderRadius: 5,
    overflow: 'hidden',
    width: '100%',

    '& thead': {
      background: '#f4393c',

      '& tr': {
        '& th': {
          color: '#fff',
          padding: 15,
          fontWeight: 'normal',
          fontSize: 18,

          '&.status': {
            width: 100,
            [theme.breakpoints.down('xs')]: {
              width: 60,
              padding: 0,
            },
          },

          '&.options': {
            width: 150,

            [theme.breakpoints.down('xs')]: {
              width: 100,
              padding: '15px 0',
            },
          },

          '&.photo': {
            width: 100,
            [theme.breakpoints.down('xs')]: {
              width: 40,
              padding: '15px 5px',
            },
          },
        },
      },
    },

    '& tbody': {
      '& tr': {
        '&:nth-of-type(odd)': {
          background: '#f5f5f5',
        },
        '& td': {
          padding: 15,

          '&.options': {
            display: 'flex',
            justifyContent: 'center',
            width: 150,
            [theme.breakpoints.down('xs')]: {
              width: 100,
              padding: '15px 0',
            },
            '&.actions': {
              alignItems: 'center',
              height: 130,
              margin: '0 auto',
            },
          },

          '&.status': {
            width: 100,
            [theme.breakpoints.down('xs')]: {
              width: 60,
              padding: 0,
            },
          },

          '&.photo': {
            width: 100,
            [theme.breakpoints.down('xs')]: {
              width: 40,
              padding: '15px 5px',
            },
            '&.banner img': {
              width: 100,
              height: 100,
              borderRadius: '50%',
              objectFit: 'cover',
              [theme.breakpoints.down('xs')]: {
                width: 40,
                height: 40,
              },
            },
          },
        },
      },
    },
  },

  tableHeader: {
    '& .MuiTableRow-head': {
      background: '#d01717',

      '& .MuiTableCell-head': {
        color: '#fff',
        fontSize: 18,
        fontWeight: '400',
      },
    },
  },

  box_header_tables: {
    padding: '15px 0',

    '& .btn_add_table': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  },

  paginationTable: {
    padding: '20px 0',
  },

  btnLimit: {
    padding: '20px 0',
    '& .MuiFormControl-root': {
      width: 130,
    },
  },

  iconEdit: {
    color: 'orange',
  },
}));

export default useStyles;
