import { Box, Hidden } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CourseCard from '~/Components/CourseCard'
import Profile from '~/Components/Profile'
import api from '~/Services/api'
import { store } from '~/store'
import useStyles from './style'

const OngoingCourses = () => {

  const { profile } = store.getState().user

  const [courses, setCourses] = useState([])
  const [notFound, setNotFound] = useState(false)

  const getOngoingCourses = async () => {
    const res = await api.get(`/student/my_subscribes/${profile.id}`)
    if (res.data.course.length > 0) {
      setCourses(res.data.course)
    } else {
      setNotFound(true)
    }
  }

  useEffect(() => {
    getOngoingCourses()
  }, [])

  const style = useStyles()
  return (
    <Profile title="Cursos em andamento">
      <Hidden smDown>
        <Box className={style.container}>
          {courses.map(course => <CourseCard key={course.id} course={course} pageCourse="cursos em andamento" />)}
          {notFound &&
            <p className={style.message}>Curso não encontrado</p>
          }
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Box className={style.carroselMobile}>
          {!notFound ?
            <Box display='flex' className="area_carousel courses">
              {courses.map(c => (
                <CourseCard course={c} key={c.id} pageCourse="cursos em andamento" />
              ))}
            </Box> :
            <p className={style.message}>Nenhum curso realizado</p>
          }
        </Box>
      </Hidden>
    </Profile>
  )
}

export default OngoingCourses
