import React, { useEffect, useState } from 'react';
import DashAdmin from '~/Components/DashAdmin';
import FormCourses from '~/Components/Forms/Courses/FormCourses';
import api from '~/Services/api';

// import { Container } from './styles';

export default function EditCourses(props) {
  const { id } = props.match.params;

  const [data, setData] = useState();
  const [courseImage, setCourseImage] = useState([]);

  const getCourseImage = async () => {
    try {
      const res = await api.get(`course/${id}/image`);
      setCourseImage(res.data); // obj[] { image, alt, url }
      if (!res.data.error) {
        setCourseImage(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function requestCourse() {
    try {
      const res = await api.get(`course/${id}`);
      const arrCategory = [];
      if (
        res.data &&
        res.data.length > 0 &&
        res.data[0].categories_names !== null
      ) {
        res.data[0].categories_names.split(',').forEach(category => {
          arrCategory.push(category);
        });

        res.data[0].category = arrCategory;
        if (res.data[0].reason_canceled === null) {
          res.data[0].reason_canceled = '';
        }
        setData(res.data[0]);
      } else {
        res.data[0].category = arrCategory;
        setData(res.data[0]);
      }
    } catch (error) {
      console.error('Erro ao buscar o curso:', error);
    }
  }

  useEffect(() => {
    requestCourse();
    getCourseImage();
  }, []);

  return (
    <DashAdmin
      content={<FormCourses objData={data} images={courseImage}/>}
      title={'Editar curso'}
      width={'850px'}
    />
  );
}
