import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ArrowBack, ArrowForward, Edit } from '@material-ui/icons';
import { addDays, format } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CustomButton } from '~/Components/Custom';
import api from '~/Services/api';

moment.locale('pt-br')

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  status: {
    '&.public': {
      borderLeft: '5px solid #4caf50'
    },
    '&.not-publicated': {
      borderLeft: '5px solid #ff9800'
    },
    '&.canceled': {
      borderLeft: '5px solid #f44336',
      background:  '#e5e5e5',
      "& a":{
        display: 'none',
      }
    }
  },
  btnEdit: {
    color: '#ff9800'
  },

  fieldDate: {
    border: '1px solid #0000003b',
    borderRadius: 5,
    maxWidth: '100%',
    width: '100%',

    '&:hover': {
      border: '1px solid',
    },

    '& .MuiInputLabel-formControl': {
      top: -8,
      left: 10,
      background: '#fafafa',
      width: 'max-content',
      textAlign: 'center',
      color: '#000',
    },

    '& label + .MuiInput-formControl': {
      padding: '0 16px 6px',
    },

    '& .MuiInput-underline:before': {
      display: 'none',
    },

    '& .MuiInput-underline:after': {
      display: 'none',
    }
  },


  tableHeader: {

    '& .MuiTableRow-head': {
      background: '#d01717',

      '& .MuiTableCell-head': {
        color: '#fff',
        fontSize: 18,
        fontWeight: '400',
      }
    },
  },

  buttons: {
    display: 'flex',
    marginBottom: 32,

    '& button': {

      '&:first-child': {
        margin: '0 16px',
      }
    }
  },

  select: {

    '& .MuiFormLabel-root': {
      background: '#fafafa',
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: '#000',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000',
    }
  }
});

export default function TableCouses() {
  const classe = useStyles()
  const [courses, setCourses] = useState([])
  const [configTable, setConfigTable] = useState({
    page: 1,
    pages: 0,
    limit: 10,
  })
  const [filterCourse, setFilterCourse] = useState({
    dateInitial: format(new Date(), 'yyyy-MM-dd'),
    dateEnd: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
    status: ''
  })
  const [query, setQuery] = useState('')

  async function requestCourses() {
    const res = await api.get(`course?page=${configTable.page}&limit=${configTable.limit}&filter=${query}`);
    setConfigTable({ ...configTable, pages: res.data.pages })
    setCourses(res.data.course)
  }

  function handlePrevNext(type) {
    if (type === 'prev') {
      setConfigTable({ ...configTable, page: --configTable.page })
    }
    if (type === 'next') {
      setConfigTable({ ...configTable, page: configTable.page += 1 })
    }
    requestCourses()
  }

  function handleLimit(value) {
    setConfigTable({ ...configTable, limit: value })
  }

  function handleChange(e) {
    const { name, value } = e.target
    if (name === 'dateEnd' || name === 'dateInitial') {
      setFilterCourse({
        ...filterCourse,
        [name]: format(new Date(value), 'yyyy-MM-dd')
      })

    }
    setFilterCourse({
      ...filterCourse,
      [name]: value
    })
  }

  function handleFilterCourse() {
    let queryString = ''
    queryString += `c.date_of_course BETWEEN '${filterCourse.dateInitial}' AND '${filterCourse.dateEnd}' ${filterCourse.status ? `AND c.status='${filterCourse.status}'` : ''} `
    setQuery(queryString)
  }

  function clearFilter() {
    setQuery('')
    setFilterCourse({
      dateInitial: format(new Date(), 'yyyy-MM-dd'),
      dateEnd: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
      status: ''
    })
    requestCourses()
  }

  useEffect(() => {
    requestCourses();
  }, []);

  useEffect(() => {
    requestCourses()
  }, [query])

  useEffect(() => {
    requestCourses()
  }, [configTable.limit])

  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={3} style={{ padding: 15 }}>
          <FormControl variant="outlined" fullWidth className={classes.select}>
            <InputLabel id="limit">Limite por página</InputLabel>
            <Select
              labelId="limit"
              id="limit"
              value={configTable.limit}
              onChange={e => handleLimit(e.target.value)}
              label="Limite por página"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3} style={{ padding: 15 }}>
          <TextField
            id="date"
            label="Data inicial"
            type="date"
            name="dateInitial"
            value={filterCourse.dateInitial}
            onChange={handleChange}
            className={classes.fieldDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} style={{ padding: 15 }}>
          <TextField
            id="date"
            label="Data final"
            type="date"
            name="dateEnd"
            value={filterCourse.dateEnd}
            onChange={handleChange}
            className={classes.fieldDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} style={{ padding: 15 }}>
          <FormControl variant="outlined" fullWidth className={classes.select} >
            <InputLabel id="status" >Status do curso</InputLabel>
            <Select
              id="status"
              name="status"
              value={filterCourse.status}
              onChange={handleChange}
              label="Status do curso"
            >
              <MenuItem value=''>Status do curso</MenuItem>
              <MenuItem value='public'>Publicado</MenuItem>
              <MenuItem value='not-publicated'>Não publicado</MenuItem>
              <MenuItem value='canceled'>Cancelado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" style={{ padding: "0 16px 16px" }}>
        <Grid className={classes.buttons} >
          <CustomButton
            fullWidth
            bg="#ff9800"
            onClick={clearFilter}
          >
            Limpar filtros
          </CustomButton>
          <CustomButton
            fullWidth
            bg="#419a58"
            onClick={handleFilterCourse}
          >
            Filtrar cursos
          </CustomButton>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell htmlFor="name-course" align="left" colSpan={2}>
                Curso
              </TableCell>
              <TableCell>Data</TableCell>
              <TableCell align="left" >Horário</TableCell>
              <TableCell align="left">Presentes/Inscritos/Vagas</TableCell>
              <TableCell align="left" >Preço</TableCell>
              <TableCell align="left">Professor</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map(course => {
              return (
                <TableRow className={`${classes.status} ${course.status}`} key={course.id} >
                  <TableCell align="left" colSpan={2}>{course.name}</TableCell>
                  <TableCell align="left">{moment(course.date_of_course).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align="center" >
                    {`${course.hour_start.substr(0, 5)}h`}
                    <br />
                    às
                    <br />
                    {`${course.hour_end.substr(0, 5)}h`}
                  </TableCell>
                  <TableCell align="center">{`${parseInt(course.subscribePresent)}/${parseInt(course.quantity)} / ${parseInt(course.totalQuantity)}`}</TableCell>

                  <TableCell align="left" >
                    {parseInt(course.price.replace('R$', '')) > 0 ? `R$ ${course.price}` : 'Grátis'}
                  </TableCell>

                  <TableCell align="left" >
                    {course.fullname}
                  </TableCell>
                  <TableCell align="center">
                    {course.status === 'public' ? 'Publicado' : course.status === 'not-publicated' ? 'Não publicado' : 'Cancelado'}
                  </TableCell>
                  <TableCell align="left">
                    <IconButton className={classes.btnEdit} href={`/admin/listar-cursos/${course.id}`}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Box display='flex' justifyContent='space-between' alignItems='center' style={{ padding: '15px 5px' }}>

          <CustomButton onClick={e => handlePrevNext('prev')} className={classe.btnPrevNext} disabled={configTable.page === 1}> <ArrowBack /> Anterior</CustomButton>

          <div>
            <span>{configTable.page}</span>/<span>{configTable.pages}</span>
          </div>

          <CustomButton onClick={e => handlePrevNext('next')} className={classe.btnPrevNext} disabled={configTable.page === configTable.pages} >Próximo <ArrowForward /></CustomButton>

        </Box>
      </TableContainer>
    </>
  );
}
