import React, { useEffect, useRef, useState } from 'react'

import { useField } from '@unform/core'
import { FormHelperText, Grid, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core'

import useStyles from './style'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const Password = ({ name, label, ...rest }) => {
  const { fieldName, registerField, error } = useField(name)

  const inputRef = useRef(null)
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])


  const style = useStyles()
  return (
    <Grid item xs={12} className={style.form} >
      <label>{label}</label>
      <OutlinedInput
        inputRef={inputRef}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        {...rest}
      />
      <FormHelperText>{error && error}</FormHelperText>
    </Grid>
  )
}

export default Password
