import React, { useState, useEffect } from 'react';
import DashAdmin from '~/Components/DashAdmin';
import * as yup from 'yup';

import useStyles from './style';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Box,
  Button,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableFooter,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import MaskedInput from 'react-text-mask';

import api from '~/Services/api';
import globalStyles from '~/Assets/css/globalStyles';
import { Add } from '@material-ui/icons';
import { CustomButton } from '~/Components/Custom';
import { toast } from 'react-toastify';

function cpfMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[
        /[0-9]/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const ListStudentCourse = ({ course }) => {
  const classes = useStyles();
  const pattern = globalStyles();

  const [students, setStudents] = useState([]);
  const [subscribe, setSubscribe] = useState({
    cpf: '',
  });
  const [nameCourse, setNameCourse] = useState('');

  const [btnSubmit, setBtnSubmit] = useState(false);

  const [msg, setMsg] = useState({
    cpf: '',
  });

  const [status, setStatus] = useState({
    cpf: '',
  });

  const [quantities, setQuantities] = useState({
    card: '',
    money: '',
    price: '',
    students: '',
  });

  async function requestStudents() {
    const res = await api.get(`/course_subscribe/${course}`);
    const price = res.data[0]?.price.replace('R$', '').trim();
    setNameCourse(res?.data[0]?.nameCourse);
    setStudents(res?.data);

    let card = 0,
      money = 0,
      students = 0;
    res.data.map(student => {
      if (student.is_present) {
        students += 1;
        student.type_payment === 'cartão'
          ? price && (card += parseFloat(price))
          : price && (money += parseFloat(price));
      }
    });
    setQuantities({
      card,
      money,
      students,
      price,
    });
  }

  const handleValue = e => {
    setSubscribe({ cpf: e.target.value });
    valid('cpf', { cpf: e.target.value });
  };

  const schema = yup.object().shape({
    cpf: yup
      .string()
      .required('Campo Obrigatório!')
      .min(14, 'CPF incompleto !'),
  });

  const valid = (field, obj) => {
    schema
      .validateAt(field, obj)
      .then(() => {
        setMsg({ ...msg, [field]: '' });
        setStatus({ ...status, [field]: 'isValid' });
      })
      .catch(err => {
        setMsg({ ...msg, [field]: err.errors });
        setStatus({ ...status, [field]: 'invalid' });
      });

    schema
      .validate(obj)
      .then(() => {
        setBtnSubmit(true);
      })
      .catch(err => {
        setBtnSubmit(false);
      });
  };

  function removerCharEspecial(value) {
    const charEsp = /[^a-z0-9]/gi;
    return value.replace(charEsp, '');
  }

  async function handlePresent({ target }) {
    const { name, checked, id } = target;

    let obj = {
      id: id,
      is_present: 0,
    };
    if (checked) {
      obj.is_present = 1;

      const newStudents = students.map(async student => {
        if (student.id === parseInt(id)) {
          student.is_present = obj.is_present;
          const newQuantities = quantities;

          newQuantities.students += 1;
          student.type_payment === 'cartão'
            ? newQuantities.price &&
              (newQuantities.card += parseFloat(newQuantities.price))
            : newQuantities.price &&
              (newQuantities.money += parseFloat(newQuantities.price));

          setQuantities(newQuantities);

          await api.put(`subscribe/${id}`, {
            type_payment: student.type_payment,
            is_present: student.is_present,
          });
        }
        return student;
      });

      setStudents(await Promise.all(newStudents));
    }
    if (checked === false) {
      obj.is_present = 0;
      const newStudents = students.map(async student => {
        if (student.id === parseInt(id)) {
          student.is_present = obj.is_present;
          student.type_payment = '';
          const newQuantities = quantities;

          newQuantities.students -= 1;
          student.type_payment === 'cartão'
            ? newQuantities.price &&
              (newQuantities.card -= parseFloat(newQuantities.price))
            : newQuantities.price &&
              (newQuantities.money -= parseFloat(newQuantities.price));
          setQuantities(newQuantities);

          await api.put(`subscribe/${id}`, {
            type_payment: '',
            is_present: student.is_present,
          });
        }
        return student;
      });
      setStudents(await Promise.all(newStudents));
    }
  }

  async function handleAdd() {
    const obj = {
      cpf: removerCharEspecial(subscribe.cpf),
      course_id: course,
    };

    const alunos = students.slice();
    try {
      const res = await api.post('course_subscribe/', obj);
      if (!res.data.message) {
        alunos.push(res.data[0]);
        setStudents(alunos);
        setStatus({ cpf: '' });
        setMsg({ cpf: '' });
        setSubscribe({ cpf: '' });
      }

      if (res.data.message === 'Registration already exists') {
        toast.error('Usuario já cadastrado');
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error('Usuário não encontrado');
    }
  }

  async function handleFormPayment({ target }) {
    const { value, checked, id } = target;

    const obj = {
      type_payment: null,
    };

    if (checked) {
      obj.type_payment = value;
    }
    if (checked === false) {
      obj.type_payment = null;
    }

    const newStudents = students.map(student => {
      if (student.id === parseInt(id)) {
        student.type_payment = obj.type_payment;
      }
      return student;
    });

    setStudents(newStudents);
  }

  async function present() {
    const present = students.reduce((acc, student) => {
      if (
        student.is_present === '' ||
        student.is_present === null ||
        student.is_present === 0
      ) {
        acc = [
          ...acc,
          {
            subscribe_id: student.id,
            is_present: student.is_present,
            email: student.email,
          },
        ];
      }
      return acc;
    }, []);
    try {
      const res = await api.post('/present', present);
      if (res.data) {
        toast.success('Lista finalizada com sucesso!');

        setTimeout(() => {
          window.location = '/admin/lista-de-presenca';
        }, 2000);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  useEffect(() => {
    requestStudents();
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ paddingBottom: 20 }}
      >
        <Typography className={classes.titleCourse}>
          Curso: {nameCourse}
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell align="left">Presença</TableCell>
              <TableCell>Nome</TableCell>
              {quantities.price !== '0' && (
                <TableCell>Forma de pagamento</TableCell>
              )}
              <TableCell align="left">E-mail</TableCell>
              <TableCell align="left">Contato 1</TableCell>
              <TableCell align="left">Contato 2</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map(s => (
              <TableRow key={s.id}>
                <TableCell align="center">
                  <Checkbox
                    color="primary"
                    id={`${s.id}`}
                    name="present"
                    checked={(s.is_present && true) || false}
                    onChange={handlePresent}
                    disabled={
                      s.price !== '0' && (s.type_payment ? false : true)
                    }
                    className={classes.checkbox}
                  />
                </TableCell>
                <TableCell>{s.fullname}</TableCell>
                {s.price !== '0' && (
                  <TableCell className={classes.radioButton}>
                    <RadioGroup
                      value={s.type_payment}
                      onChange={handleFormPayment}
                    >
                      <FormControlLabel
                        value="cartão"
                        control={<Radio id={`${s.id}`} />}
                        label="Cartão Crédito/Débito"
                      />
                      <FormControlLabel
                        value="dinheiro"
                        control={<Radio id={`${s.id}`} />}
                        label="Dinheiro"
                      />
                    </RadioGroup>
                  </TableCell>
                )}
                <TableCell align="left">{s.email}</TableCell>
                <TableCell align="left">{s.telephoneOne}</TableCell>
                <TableCell align="left">{s.telephoneTwo}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow className={classes.total}>
              <TableCell>
                Aluno presentes: <b> {quantities.students}</b>
              </TableCell>
              {quantities.price !== '0' && (
                <>
                  <TableCell>
                    Total pago em cartão: <br /> <b>R$ {quantities.card}</b>
                  </TableCell>
                  <TableCell>
                    Total pago em dinheiro: <br /> <b>R$ {quantities.money}</b>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Box
        display={'flex'}
        justifyContent="space-between"
        style={{ paddingTop: 20 }}
        className={pattern.root}
      >
        <Box>
          <FormControl size="small" className={status.cpf}>
            <InputLabel htmlFor="cpf" variant={'outlined'}>
              CPF
            </InputLabel>
            <OutlinedInput
              value={subscribe.cpf}
              id="cpf"
              inputComponent={cpfMask}
              onChange={e => handleValue(e)}
              onBlur={e => handleValue(e)}
              placeholder="123.456.789-00"
              labelWidth={35}
            />
            <FormHelperText>{msg.cpf}</FormHelperText>
          </FormControl>

          <Button
            className={classes.btnAddStudent}
            onClick={handleAdd}
            disabled={!btnSubmit}
          >
            <Add />
          </Button>
        </Box>
        <Box>
          <CustomButton bg_hover="#64cb64" bg="#6ab36a" onClick={present}>
            Finalizar lista
          </CustomButton>
        </Box>
      </Box>
    </>
  );
};

export default function ListPresence(props) {
  return (
    <>
      <DashAdmin
        content={<ListStudentCourse course={props.match.params.id} />}
        title={'Lista de Presença'}
        width={'100%'}
      />
    </>
  );
}
