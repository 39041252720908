import { Box, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import SkeletonCourse from '../SkeletonCourse'
import useStyles from './style'

const SkeletonDetailsCouse = () => {

  const style = useStyles()
  return (
    <Grid className={style.content}>
      <Box className={style.BackgroundHeader}>

        <Grid className={style.headerCourse}>
          <Box display="flex" >

            <Box className={style.photo}>
              <Skeleton variant='circle' width={142} height={142} />
            </Box>

            <Box className={style.course}>
              <h1><Skeleton width="100%" /></h1>
              <p><Skeleton width="100%" /></p>
              <p><Skeleton width="100%" /> <b><Skeleton width="100%" /></b></p>
              <Box className={style.hour} display="flex">
                <span><Skeleton width="100%" height={50} /></span>
                <span><Skeleton width="100%" height={50} /></span>
                <span><Skeleton width="100%" height={50} /></span>
              </Box>

            </Box>
          </Box>

          <Box className={style.contentSubscribe}>
            <p><Skeleton width={150} /></p>
            <Box className={style.subscribe}>
              <p><Skeleton width={100} /></p>
              <button>
                <Skeleton width={100} />
              </button>
            </Box>
          </Box>

        </Grid>
      </Box>
      <Box className={style.contentDetails}>
        <h2> <Skeleton width="50%" /></h2>
        <p> <Skeleton width="70%" /></p>
        <p> <Skeleton width="60%" /></p>
        <p> <Skeleton width="50%" /></p>
        <h3> <Skeleton width="30%" /></h3>
        <h4> <Skeleton width="30%" /></h4>
        <button>
          <Skeleton width={150} />
        </button>
      </Box>
      <h2 className={style.similarCourses}>Cursos similiares</h2>

      <Box className={style.boxBigSquare}>
        <Box className={style.boxCard}>
          <SkeletonCourse />
        </Box>
      </Box>
    </Grid>


  )
}

export default SkeletonDetailsCouse
